import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import swal from "sweetalert2";
import Moment from "moment";
import FichaAfiliado from "./FichaCommission";

///// Componente para armar la pantlla
class FormNewAfiliado extends Component {
	constructor(props) {
		super(props);
		this.state = {


			/////offer lista
			txtNameAfiliado: "",
			txtIdAfiliado: "",
			incorporator: "",
			amountAfiliado: 0,
			colorAfiliado: "",
			daysAfiliado: 0,
			divisaAfiliado: "selecciona",
			subTitleAfiliado: "",
			titleAfiliado: "",
			txtBeneficiosAfiliado: "",
			txtHeaderAfiliado: "",
			emailAfiliado: "",
			porcentajeAfiliado: 0,

			titleAfiliadoSubOffert: "",
			amountAfiliadoSubOffert: 0,
			daysAfiliadoSubOffert: 0,

			afiliadoList: [],

		};
		this.manejadorCambios = this.manejadorCambios.bind(this);

	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}
//
	validaIdAfiliado() {

	}
	validaFormOferta() {
		if (!this.state.txtNameAfiliado) {
			swal.fire({title: "Ingresa texto para el nombre de afiliado", text: "Necesario para esta afiliado", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.divisaAfiliado === "selecciona") {
			swal.fire({title: "selecciona una divisa", text: "Necesario para este afiliado", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.emailAfiliado) {
			swal.fire({title: "Ingresa email de tu afiliado", text: "Necesario para este afiliado", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.txtHeaderAfiliado) {
			swal.fire({title: "Ingresa texto para el header de tu afiliado", text: "Necesario para este afiliado", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.colorAfiliado) {
			swal.fire({title: "Ingresa un color", text: "Necesario para este afiliado", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.titleAfiliado) {
			swal.fire({title: "Ingresa un titulo", text: "Necesario para este afiliado", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.amountAfiliado === 0) {
			swal.fire({title: "Ingresa un valor en dll para tu oferta", text: "Necesario para este afiliado", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.porcentajeAfiliado) {
			swal.fire({title: "Ingresa una cantidad de porcentaje", text: "Necesario para este afiliado", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.daysAfiliado) {
			swal.fire({title: "Ingresa una cantidad de dias", text: "Necesario para este afiliado", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.subTitleAfiliado) {
			swal.fire({title: "Ingresa un texto de subtitulo", text: "Necesario para este afiliado", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.txtIdAfiliado) {
			swal.fire({title: "Ingresa un ID valido para el usuario", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			firestore.collection("affiliate").where('txtLink', '==', this.state.txtIdAfiliado).get().then((documentSnapshot) => {
				if(documentSnapshot.size === 0){
					this.guardaDatos();
				} else {
					swal.fire({title: "El id de afiliado ya esta ocupado", text: "Ingresa otro", type: "error", confirmButtonText: "Entendido"});
				}
			});
		}
	}

	///guardamos os datos en la bd
	guardaDatos() {
			firestore.collection("affiliate").doc().set({
				name: this.state.txtNameAfiliado,
				txtLink: this.state.txtIdAfiliado,
				incorporator: this.state.incorporator,
				picAfiliado: null,
				divisa: this.state.divisaAfiliado,
				amount: parseInt(this.state.amountAfiliado),
				percentage: parseInt(this.state.porcentajeAfiliado),
				color: "#"+this.state.colorAfiliado,
				days: parseInt(this.state.daysAfiliado),
				subTitle: this.state.subTitleAfiliado,
				title: this.state.titleAfiliado,
				txtHeader: this.state.txtHeaderAfiliado,
				email: this.state.emailAfiliado,
				modified: Moment().toDate(),
				contIniciados: 0,
				contPagados: 0,
				contRegUsuarios: 0,
				contSubOffert: 0,
				titleSubOffer: this.state.titleAfiliadoSubOffert,
				amountSubOffer: parseInt(this.state.amountAfiliadoSubOffert),
				daysSubOffer: parseInt(this.state.daysAfiliadoSubOffert),
			});
		this.limpiarForm();
		}

	limpiarForm() {
		this.setState({
			txtNameAfiliado: "",
			txtIdAfiliado: "",
			incorporator: "",
			amountAfiliado: 0,
			colorAfiliado: "",
			daysAfiliado: 0,
			subTitleAfiliado: "",
			titleAfiliado: "",
			txtBeneficiosAfiliado: "",
			txtHeaderAfiliado: "",
			titleAfiliadoSubOffert: "",
			amountAfiliadoSubOffert: 0,
			porcentajeAfiliado: 0,
			daysAfiliadoSubOffert: 0,
			emailAfiliado: "",
		});
		swal.fire({title: "Felicidades", text: "Guardado con exito", icon: "success", confirmButtonText: "Entendido"});
	}

	componentDidMount() {
		///////llenamos la lista de los offers
		let listaData = [];
		firestore.collection("affiliate").onSnapshot(querySnapshot => {
			///este variable evita que se dupliquen las consultas
			listaData = []
			let features = querySnapshot.docs
			features.forEach((doc) => {
				listaData.push({
					offerID: doc.id,
					name: doc.data().name,
					contIniciados: doc.data().contIniciados,
					contPagados: doc.data().contPagados,
					contRegUsuarios: doc.data().contRegUsuarios,
					contSubOffert: doc.data().contSubOffert,
					email: doc.data().email,
					color: doc.data().color,
					amount: doc.data().amount,
					title: doc.data().title,
					days: doc.data().days,
					titleSubOffer: doc.data().titleSubOffer,
					amountSubOffer: doc.data().amountSubOffer,
					daysSubOffer: doc.data().daysSubOffer,
					picAfiliado: doc.data().picAfiliado,
					divisa: doc.data().divisa,
					txtLink: doc.data().txtLink,
					incorporator: doc.data().incorporator,
					phone: doc.data().phone
				});
			});
			//////ordenamos la lista por prioridad (priority)
			listaData.sort(function (a, b) {
				return a.days - b.days;
			});
			this.setState({afiliadoList: listaData});
		});
		///////llenamos la lista de los features
	}


	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header">
					<h2 className="panel-title">Comisiónistas</h2>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Agrega afiliados</h3>
						</div>

						<div className="d-flex">
							<div className="card-body">

								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<label>Formato</label>
												<h6>Selecciona un formato para tu pathbook</h6>

												<select className="form-control" name="incorporator" value={this.state.incorporator} onChange={this.manejadorCambios}>
													<option value="">Selecciona uno</option>
													<option value="Mario De los Santos">Mario De los Santos</option>
													<option value="Jorge Caballero">Jorge Caballero</option>
													<option value="Jonathan Macias">Jonathan Macias</option>
													<option value="Victor Laguna">Victor Laguna</option>
												</select>
											</div>
											<div className="form-group">
												<label>Ingresa nombre del afiliado</label>
												<input className="form-control" name="txtNameAfiliado" placeholder="Nombre afiliado" type="text" value={this.state.txtNameAfiliado} onChange={this.manejadorCambios}/>
												<span>Campo obligatorio</span>
											</div>
											<div className="form-group">
												<label>Crea un id personal</label>
												<input className="form-control" name="txtIdAfiliado" placeholder="Id afiliado" type="text" value={this.state.txtIdAfiliado} onChange={this.manejadorCambios}/>
												<span>Campo obligatorio</span>
											</div>
											<div className="row">
												<div className="col col-md-6">
													<div className="form-group">
														<label>Ingresa un email para enviar notificaciones</label>
														<input className="form-control" name="emailAfiliado" placeholder="Email" type="text" value={this.state.emailAfiliado} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">
														<label>Ingresa un texto para el encabezado </label>
														<input className="form-control" name="txtHeaderAfiliado" placeholder="Encabezado" type="text" value={this.state.txtHeaderAfiliado} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">
														<label>Ingresa el codigo del color</label>
														<input className="form-control" name="colorAfiliado" placeholder="Color en codigo" type="text" value={this.state.colorAfiliado} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio sin # </span>
													</div>
													<div className="form-group">
														<label>Ingresa el titulo</label>
														<input className="form-control" name="titleAfiliado" placeholder="Titulo de oferta" type="text" value={this.state.titleAfiliado} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">
														<label>Divisa de tu afiliado</label>
													<select className="form-control" name="divisaAfiliado" value={this.state.divisaAfiliado} onChange={this.manejadorCambios}>
														<option value="selecciona">Selecciona uno</option>
														<option value="USD">USD</option>
														<option value="MXN">MXN</option>
													</select>
													</div>
													<div className="form-group">
														<label>Valor  de tu oferta</label>
														<input className="form-control" name="amountAfiliado" placeholder="Valor en dll" type="number" value={this.state.amountAfiliado} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>

													<div className="form-group">
														<label>Porcentage para el afiliado</label>
														<input className="form-control" name="porcentajeAfiliado" placeholder="Valor en dll" type="number" value={this.state.porcentajeAfiliado} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>

													<div className="form-group">
														<label>Cuandos dias otorga tu oferta (valor numero ejemplo 6 = a 6 dias) </label>
														<input className="form-control" name="daysAfiliado" placeholder="Dias de oferta" type="number" value={this.state.daysAfiliado} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>

													<div className="form-group">
														<label>Ingresa el subtitulo</label>
														<input className="form-control" name="subTitleAfiliado" placeholder="Subtitulo de oferta" type="text" value={this.state.subTitleAfiliado} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>

													<br/>
													<h4>Sub oferta</h4>

													<div className="form-group">
														<label>Ingresa el titulo Para una sub oferta</label>
														<input className="form-control" name="titleAfiliadoSubOffert" placeholder="Titulo de oferta" type="text" value={this.state.titleAfiliadoSubOffert} onChange={this.manejadorCambios}/>
													</div>

													<div className="form-group">
														<label>Valor en dll de tu oferta</label>
														<input className="form-control" name="amountAfiliadoSubOffert" placeholder="Valor en dll" type="number" value={this.state.amountAfiliadoSubOffert} onChange={this.manejadorCambios}/>
													</div>

													<div className="form-group">
														<label>Cuandos dias otorga tu oferta (valor numero ejemplo 6 = a 6 dias) </label>
														<input className="form-control" name="daysAfiliadoSubOffert" placeholder="Dias de oferta" type="number" value={this.state.daysAfiliadoSubOffert} onChange={this.manejadorCambios}/>
													</div>

												</div>
											</div>

											<br/>
											<div className="col col-md-12">
												<button onClick={() => this.validaFormOferta()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Crear oferta</button>
											</div>

										</div>
									</div>
								</div>
								<br/>
							</div>
						</div>

					</div>
				</div>
				<div className="content">
					<h3 className="">Lista de comisionistas: {this.state.afiliadoList.length}</h3>
				</div>
				<div className="content">
					<div className=" card-primary">
						<div className="row">
							{this.state.afiliadoList.map(data => (
								<div className="col col-md-6" key={data.offerID}>
									<div>
										<FichaAfiliado data={data}/>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FormNewAfiliado;


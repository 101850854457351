import React, {Component} from "react";
import {Switch, Route, useRouteMatch} from "react-router-dom";
import {auth, firestore} from "../js/firebase";
import BarTop from "../components/BarTop";
import BarSide from "../components/BarSide";
import PathbooksStatistics from "./statistics/PathbooksStatistics";
import SaludoTop from "../modules/SaludoTop";
import Coupons from "../modules/GeneraCupones";
import AppFeatures from "./features/AppFeatures";
import AppCarrusel from "./carruseles/AppCarrusel";
import RepUsersReg from "./reports/RepUsersReg";

import ControlPathbooks from "./pathbooks/ControlPathbooks";
import FormNewPathbook from "./pathbooks/FormNewPathbook";
import FormPortadasPathbook from "./pathbooks/FormPortadasPathbook";
import FormImgPathbook from "./pathbooks/FormImgPathbook";
import FormJsonPathbook from "./pathbooks/FormJsonPathbook"

import GeneraAudioPathbooks from "./audioPathbooks/GeneraAudioPathbooks";
import FormAudioPathbook from "./audioPathbooks/FormAudioPathbook";

import RepRegaliasPathbook from "./reports/RepRegaliasPathbook";
import RepLecturasPathbook from "./reports/RepLecturasPathbook";


import RepCommission from "./reports/RepCommission";
import RepPathbooks from "./reports/RepPathbooks";

import FormNewAutor from "./autors/FormNewAutor";
import FormImgAutor from "./autors/FormImgAutor";
import FormNewOffers from "./offers/FormNewOffers";
import FormNewCommission from "./commission/FormNewCommission";

import AppSchools from "./schools/AppSchools";



class Panel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//////estado del usuario
			userData: {
				"id": null,
				"email": null,
				"name": null,
				"type": null,
				"userPic": null,
				"username": null,
				"authorId": null,
			},
			//////datos de estadisticas pathbooks
			listaPathbooks: [],
			listaAutores: [],
			listaGenero: [],
		};
	}

	////validamos el usuario que exista
	componentDidMount() {
		auth.onAuthStateChanged((user) => {
			if (user) {
				const uid = user.uid;
				////obtenemos datos de usuario
				firestore.doc("users/" + uid).get().then((documentSnapshot) => {
					let dataAdmin = documentSnapshot.data();
					let dataUserAdmin = {};
					let author = null;
					if(!dataAdmin){
						auth.signOut();
					}
					if (dataAdmin.type === "admin") {
						if (!dataAdmin.authorData) {
							author = null;
						} else {
							author = dataAdmin.authorData.id;
						}
						dataUserAdmin = {
							"id": uid,
							"email": dataAdmin.email,
							"name": dataAdmin.name,
							"type": "admin",
							"userPic": dataAdmin.userPic,
							"username": dataAdmin.username,
							"authorId": author,
						};
						this.setState({userData: dataUserAdmin});

					} else {
						auth.signOut();
					}
				});
			} else {
				this.props.history.push("/");
			}
		});
		let listaPathbooks = []
		firestore.collection("pathbooks").onSnapshot(querySnapshot => {
			listaPathbooks = []
			let consultaPathbooks = querySnapshot.docs
			consultaPathbooks.forEach((doc) => {
				listaPathbooks.push({doc});
			});
			this.setState({listaPathbooks: listaPathbooks});

		});
		let listaAutores = []
		firestore.collection("authors").onSnapshot(querySnapshot => {
			listaAutores = []
			let consultaAutores = querySnapshot.docs
			consultaAutores.forEach((doc) => {
				listaAutores.push({doc});
			});
			this.setState({listaAutores: listaAutores});

		});
		let listaGenero = []
		firestore.collection("genres").onSnapshot(querySnapshot => {
			listaGenero = []
			let consultaGenero = querySnapshot.docs
			consultaGenero.forEach((doc) => {
				listaGenero.push({doc});
			});
			this.setState({listaGenero: listaGenero});

		});
	}

	render() {
		return (
			<div>
				{this.state.userData.id ?
					<div>
						<BarTop/>
						<BarSide userData={this.state.userData}/>
						<PanelControlIni
							userData={this.state.userData}
							listaPathbooks={this.state.listaPathbooks}
							listaAutores={this.state.listaAutores}
							listaGenero={this.state.listaGenero}
						/>
					</div>
					:
					<div>
					</div>
				}
			</div>
		);
	}
}

export default Panel;

function PanelControlIni(dataGeneral) {
	let {path} = useRouteMatch();
	return (
		<div>
			<Switch>
				<Route exact path={path}>
					<SaludoTop userData={dataGeneral.userData}/>
					<PathbooksStatistics listaPathbooks={dataGeneral.listaPathbooks}/>
				</Route>
				<Route exact path={path + "/statistics"}>
					<PathbooksStatistics listaPathbooks={dataGeneral.listaPathbooks}/>
				</Route>
				<Route exact path={path+"/Contacto"}><PathbooksStatistics/></Route>
				<Route exact path={path + "/Ini"}><PathbooksStatistics/></Route>
				<Route exact path={path + "/Coupons"}><Coupons/></Route>
				<Route exact path={path + "/AppFeatures"}><AppFeatures dataGeneral={dataGeneral} /></Route>
				<Route exact path={path + "/AppCarrusel"}><AppCarrusel dataGeneral={dataGeneral} /></Route>
				<Route exact path={path + "/RepUsersReg"}><RepUsersReg/></Route>

				<Route exact path={path + "/ControlPathbooks"}><ControlPathbooks dataGeneral={dataGeneral.listaPathbooks}/></Route>
				<Route exact path={path + "/FormNewPathbook"}><FormNewPathbook dataGeneral={dataGeneral}/></Route>
				<Route exact path={path + "/FormPortadasPathbook"}><FormPortadasPathbook dataGeneral={dataGeneral}/></Route>
				<Route exact path={path + "/FormImgPathbook"}><FormImgPathbook dataGeneral={dataGeneral}/></Route>
				<Route exact path={path + "/FormJsonPathbook"}><FormJsonPathbook dataGeneral={dataGeneral}/></Route>

				<Route exact path={path + "/GeneraAudioPathbooks"}><GeneraAudioPathbooks dataGeneral={dataGeneral}/></Route>
				<Route exact path={path + "/FormAudioPathbook"}><FormAudioPathbook dataGeneral={dataGeneral}/></Route>


				<Route exact path={path + "/RepRegaliasPathbook"}><RepRegaliasPathbook listaPathbooks={dataGeneral.listaPathbooks} /></Route>
				<Route exact path={path + "/RepLecturasPathbook"}><RepLecturasPathbook listaPathbooks={dataGeneral.listaPathbooks} /></Route>

				<Route exact path={path + "/RepCommission"}><RepCommission listaPathbooks={dataGeneral.listaPathbooks} /></Route>
				<Route exact path={path + "/RepPathbooks"}><RepPathbooks listaPathbooks={dataGeneral.listaPathbooks} /></Route>

				<Route exact path={path + "/FormNewAutor"}><FormNewAutor dataGeneral={dataGeneral}/></Route>
				<Route exact path={path + "/FormImgAutor"}><FormImgAutor dataGeneral={dataGeneral}/></Route>
				<Route exact path={path + "/FormNewOffers"}><FormNewOffers/></Route>
				<Route exact path={path + "/FormNewCommission"}><FormNewCommission/></Route>
				<Route exact path={path + "/AppSchools"}><AppSchools  /></Route>





			</Switch>
		</div>
	);
}

import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import swal from "sweetalert2";
import axios from "axios";
import ReactMarkdown from "react-markdown";

class GeneraAudioPathbooks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editar: false,
			pathbooksAModificar: "",
			pathbooksId: "",
			pathbookSeleccionado: "",
			pathbooksLista: props.dataGeneral.listaPathbooks,
			selectPathbooks: [],
			cadenaJson1: "https://firebasestorage.googleapis.com/v0/b/pathbook.appspot.com/o/pathbooks%2F",
			cadenaJson2: "%2F",
			cadenaJson3: ".json?alt=media&token=f3f86f7f-bd13-4c0f-915a-be46ca962c9f",

			aregloContent: [],
			arregloDeciones: [],
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	validaPathbooksExiste() {
		if (!this.state.pathbookSeleccionado) {
			swal.fire({title: "Seleciona un pathbook existente", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let generoID = this.state.pathbookSeleccionado.split(" -   --> ");
			firestore.doc("pathbooks/" + generoID[1]).get().then( (documentSnapshot) => {
				let datapathbook = documentSnapshot;
				let cadenaJson = this.state.cadenaJson1+datapathbook.id+this.state.cadenaJson2+"pathbook"+this.state.cadenaJson3
				if(datapathbook.exists === true){

					let listaContents = [];
					let aregloTerminado = [];
					let aregloDecide = [];

					 axios.get(cadenaJson).then(async res  => {
						listaContents = res.data.chapters
						let keys = Object.keys(listaContents);
						await keys.forEach( function(key) {
							var value = listaContents[key];

							var titleChapter = listaContents[key].title["es"]
							var contentAll  = listaContents[key].content["es"];


							/*const splited = contentAll.split(" ")
							const filter = splited.filter(x => {
								return (x.includes("null"));
							});
							console.log(filter.toString());
							*/





							aregloTerminado.push({
								key: key,
								data: value,
								content: contentAll,
								title: titleChapter
							});
						})
						 this.setState({aregloContent: aregloTerminado})

						 let idKey = 0
						 let contador = 0
						 await keys.forEach( function(key) {
							 let value = listaContents[key];
							 value.options.forEach( function(content) {
							 	if(content.link === "ending"){
								    contador = contador + 1
							    } else if(content.link === "endingWriter"){
									 contador = contador + 1
								} else if(content.link === "endingShort"){
								    contador = contador + 1
							    } else if(content.title === "Siguiente"){
								    contador = contador + 1
							    }else if(content.title === "SIGUIENTE"){
								    contador = contador + 1
							    } else if(content.title === "SIGUIENTE PÁGINA"){
								    contador = contador + 1
							    } else{
								    contador = contador + 1
								    idKey = idKey + 1
								    aregloDecide.push({
									    link: content.link,
									    title: content.title,
									    key: "id"+idKey,
								    });
							    }

							 })
						 })

						 aregloDecide.sort(function (a, b) {
							 return a.link - b.link;
						 });

						this.setState({arregloDeciones: aregloDecide})

					});
				}
				else{
					this.limpiarForm();
					swal.fire({title: "Ese pathbook no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
			})
		}
	}





	limpiarForm() {
		this.setState({
			pathbooksAModificar: "",
			pathbookSeleccionado: "",
		});
		swal.fire({title: "Felicidades", text: "Guardado con exito", type: "error", confirmButtonText: "Entendido"});
	}

	async componentDidMount() {
		let selectPathbookList = [];
		await this.state.pathbooksLista.forEach((task) => {
				let idPathbook = task.doc.id;
				let nombrePathbook = task.doc.data().title["es"];
				selectPathbookList.push({id: idPathbook, nombre: nombrePathbook});
		});
		this.setState({selectPathbooks: selectPathbookList});
	}
	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header">
					<h2 className="panel-title">Pathbook</h2>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Informacion de Pathbook</h3>
						</div>

						<div className="d-flex">
							<div className="card-body">
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<div className="container">
													<label>Si quieres editar un pathbook Buscalo aqui:</label>
													<div className="row">
														<input className="form-control" list="selectPathbook" id="generoSelected" name="pathbookSeleccionado" type="select" placeholder="Elige un pathbook a modificar" value={this.state.pathbookSeleccionado} onChange={this.manejadorCambios}/>
														<datalist id="selectPathbook">
															{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
														</datalist>
													</div>

												</div>
												<br/>
												<div className="form-group">

													<button onClick={() => this.validaPathbooksExiste()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Seleccionar pathbook</button>
												</div>
											</div>
											<div>
												<br/>
												<br/>
												<br/>
												<br/>
												{this.state.aregloContent.map(data => (
													<div key={data.key}>
														<div>

															<span style={{color: "#000", backgroundColor: "#fedd24", fontSize: "18px"}} >Nombre del archivo: </span>
															<span style={{color: "#000", backgroundColor: "#86f649", fontSize: "18px"}} >{data.key}Content </span>
															<br/>
															<span style={{color: "#000", backgroundColor: "#fedd24", fontSize: "18px"}} >Titulo del capitulo: </span>
															<span style={{color: "#000", backgroundColor: "#86f649", fontSize: "18px"}} >{data.title}</span>
															<ReactMarkdown source={data.content.replaceAll("<br>", "")} />




														</div>
														<br/>
													</div>
												))}
											</div>
											<div>
												<br/>
												<br/>
												<br/>
												<br/>
												{this.state.arregloDeciones.map(data => (
													<div key={data.key}>
														<div> <span style={{color: "#000", backgroundColor: "#fedd24", fontSize: "18px"}} >Nombre del Archivo: </span> <span style={{color: "#000", backgroundColor: "#86f649"}} >{data.link}Option</span></div>
														<div>{data.title["es"]}</div>
														<br/>
													</div>
												))}
											</div>

										</div>
									</div>
								</div>

							</div>
						</div>

						<div className="d-flex">
							<div className="card-body">

								<div className="container">
									<div className="row">
										<div className="col-sm">



										</div>
									</div>
								</div>
								<br/>
							</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

export default GeneraAudioPathbooks;


import React, {Component} from "react";
import {firestore} from "../../js/firebase";

///// Componente para armar la pantlla
class RepUsersReg extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userID: "",
			userEmail: "",
			userName: "",
			usersList: "",
			valorBarraLoad: 0,
			valorBarraLoadEnd: 100000,
			consecutivo: 0,
			pathbookList: [],
		};
	}

	async buscaUsuarios() {
		const usuarios = await firestore.collection("users").get();
		await this.setState({
			valorBarraLoad: usuarios.size,
		});
		let consec = 0;
		let users = "";
		usuarios.docs.forEach((doc) => {
			consec = consec + 1;
			console.log(doc.data().email);
		});
		this.setState({usersList: users});
	}

	componentDidMount() {
		let listaUsuarioPagado = [];
		firestore.collection("receipts").onSnapshot(querySnapshot => {
			listaUsuarioPagado = [];
			let consultaGenero = querySnapshot.docs;
			consultaGenero.forEach((doc) => {
				// console.log(doc.data().user.id)
				firestore.doc("users/" + doc.data().user.id).get().then((documentSnapshot) => {
					// firestore.collection("users").where("id", "==", doc.data().user.id).onSnapshot(async querySnapshot => {
					if(documentSnapshot.data()) {
						if (documentSnapshot.data().email) {
							console.log(documentSnapshot.data().email)
							listaUsuarioPagado.push(documentSnapshot.data().email);
						}
					}

				});
			});
			this.setState({pathbookList: listaUsuarioPagado})
		});
	}

	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header d-flex">
					<h2 className="panel-title">Usuarios Regiistrados</h2>
				</div>
				<div className="content-header d-flex">
					<div>
						<button onClick={() => this.buscaUsuarios()} type="button" id="buttonCarrusel" className="btn btn-primary btn-lg btn-block">Buscar</button>
					</div>
					<progress value={this.state.consecutivo} max={this.state.valorBarraLoad}/>
				</div>

				<div className="content-header d-flex">
					<div className="container-fluid">

						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Lista de Usuarios registrados: {this.state.valorBarraLoad}</h3>
								<h3 className="card-title">analizado: {this.state.consecutivo}</h3>
							</div>
							<div>
								<table className="table">
									<thead className="thead-dark">
									<tr>
										<th scope="col" width="40">Clasificacion</th>



									</tr>
									</thead>
									<tbody>
									{this.state.pathbookList.map(data => (
										<tr key={data}>
											<td>{data}</td>

											{/*<td><a href={data.urlGenrado} target="_blank">Abrir</a></td>*/}
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RepUsersReg;

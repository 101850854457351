import React, {Component} from "react";
import {storage, firestore} from "../../js/firebase";
import swal from "sweetalert2";

class FormJsonPathbook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pathbooksLista: props.dataGeneral.listaPathbooks,
			selectPathbooks: [],
			/////pathbook lista

			urlIni: "https://firebasestorage.googleapis.com/v0/b/pathbook.appspot.com/o/pathbooks%2F",
			urlmid: "%2F",
			urlEnd: "?alt=media",
			imagenNull: "https://livingabook.com/LoadPathbooksAndMore/assets/img/portadaNull.jpg",

			jsonES: null,
			barraLoad01: 0,
			barraLoadEnd01: 1000,
			message01: "Seleciona un archivo .JSON para cargar",

			jsonEN: null,
			barraLoad02: 0,
			barraLoadEnd02: 1000,
			message02: "Seleciona un archivo .JSON para cargar",

			jsonFR: null,
			barraLoad03: 0,
			barraLoadEnd03: 1000,
			message03: "Seleciona un archivo .JSON para cargar",

			jsonDE: null,
			barraLoad04: 0,
			barraLoadEnd04: 1000,
			message04: "Seleciona un archivo .JSON para cargar",

			jsonIT: null,
			barraLoad05: 0,
			barraLoadEnd05: 1000,
			message05: "Seleciona un archivo .JSON para cargar",

			jsonPT: null,
			barraLoad06: 0,
			barraLoadEnd06: 1000,
			message06: "Seleciona un archivo .JSON para cargar",

		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}
	limpiarVars(){
		this.setState({

			jsonES: null,
			barraLoad01: 0,
			barraLoadEnd01: 1000,
			message01: "Seleciona un archivo .JSON para cargar",

			jsonEN: null,
			barraLoad02: 0,
			barraLoadEnd02: 1000,
			message02: "Seleciona un archivo .JSON para cargar",

			jsonFR: null,
			barraLoad03: 0,
			barraLoadEnd03: 1000,
			message03: "Seleciona un archivo .JSON para cargar",

			jsonDE: null,
			barraLoad04: 0,
			barraLoadEnd04: 1000,
			message04: "Seleciona un archivo .JSON para cargar",

			jsonIT: null,
			barraLoad05: 0,
			barraLoadEnd05: 1000,
			message05: "Seleciona un archivo .JSON para cargar",

			jsonPT: null,
			barraLoad06: 0,
			barraLoadEnd06: 1000,
			message06: "Seleciona un archivo .JSON para cargar",
		})
	}
	async validaPathbooksExiste() {
		this.limpiarVars()
		if (!this.state.pathbookSeleccionado) {
			swal.fire({title: "Seleciona un pathbook existente", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let pathbookId = this.state.pathbookSeleccionado.split(" -   --> ");
			await firestore.doc("pathbooks/" + pathbookId[1]).get().then((documentSnapshot) => {
				let datapathbook = documentSnapshot;
				if (datapathbook.exists === true) {
					this.setState({pathbooksInfo: datapathbook});

					///Json ES
					if (datapathbook.data().jsonContent) {
					if (datapathbook.data().jsonContent.jsonES) {
						this.setState({
							jsonES: datapathbook.data().jsonContent.jsonES,
						});
					}
					} else {
						this.setState({
							jsonES: null,
						});
					}

					///Json EN
					if (datapathbook.data().jsonContent) {
						if (datapathbook.data().jsonContent.jsonEN) {
							this.setState({
								jsonEN: datapathbook.data().jsonContent.jsonEN,
							});
						}
					} else {
						this.setState({
							jsonEN: null,
						});
					}

					///Json FR
					if (datapathbook.data().jsonContent) {
						if (datapathbook.data().jsonContent.jsonFR) {
							this.setState({
								jsonFR: datapathbook.data().jsonContent.jsonFR,
							});
						}
					} else {
						this.setState({
							jsonFR: null,
						});
					}

					///Json DE
					if (datapathbook.data().jsonContent) {
						if (datapathbook.data().jsonContent.jsonDE) {
							this.setState({
								jsonDE: datapathbook.data().jsonContent.jsonDE,
							});
						}
					} else {
						this.setState({
							jsonDE: null,
						});
					}

					///Json IT
					if (datapathbook.data().jsonContent) {
						if (datapathbook.data().jsonContent.jsonIT) {
							this.setState({
								jsonIT: datapathbook.data().jsonContent.jsonIT,
							});
						}
					} else {
						this.setState({
							jsonIT: null,
						});
					}

					///Json PT
					if (datapathbook.data().jsonContent) {
						if (datapathbook.data().jsonContent.jsonPT) {
							this.setState({
								jsonPT: datapathbook.data().jsonContent.jsonPT,
							});
						}
					} else {
						this.setState({
							jsonPT: null,
						});
					}

				} else {
					swal.fire({title: "Ese pathbook no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
			});

		}
	}


	cargaJsonES(event) {
			const file = event.target.files[0];
			const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id ).child("es.json");
			const task = storageRef.put(file);

			task.on("state_changed", (snapshot) => {
				this.setState({
					barraLoadEnd01: snapshot.totalBytes,
					barraLoad01: snapshot.bytesTransferred,
				});

			}, (error) => {
				this.setState({
					message01: "Error: " + error,
				});
			}, () => {
				this.setState({
					message01: "Json cargado correctamente",
					jsonES: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
				});
				this.guarda();
			});

	}

	cargaJsonEN(event) {
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id ).child("en.json");
		const task = storageRef.put(file);

		task.on("state_changed", (snapshot) => {
			this.setState({
				barraLoadEnd02: snapshot.totalBytes,
				barraLoad02: snapshot.bytesTransferred,
			});

		}, (error) => {
			this.setState({
				message02: "Error: " + error,
			});
		}, () => {
			this.setState({
				message02: "Json cargado correctamente",
				jsonEN: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
			});
			this.guarda();
		});

	}

	cargaJsonFR(event) {
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id ).child("fr.json");
		const task = storageRef.put(file);

		task.on("state_changed", (snapshot) => {
			this.setState({
				barraLoadEnd03: snapshot.totalBytes,
				barraLoad03: snapshot.bytesTransferred,
			});

		}, (error) => {
			this.setState({
				message03: "Error: " + error,
			});
		}, () => {
			this.setState({
				message03: "Json cargado correctamente",
				jsonFR: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
			});
			this.guarda();
		});

	}

	cargaJsonDE(event) {
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id ).child("de.json");
		const task = storageRef.put(file);

		task.on("state_changed", (snapshot) => {
			this.setState({
				barraLoadEnd04: snapshot.totalBytes,
				barraLoad04: snapshot.bytesTransferred,
			});

		}, (error) => {
			this.setState({
				message04: "Error: " + error,
			});
		}, () => {
			this.setState({
				message04: "Json cargado correctamente",
				jsonDE: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
			});
			this.guarda();
		});

	}

	cargaJsonIT(event) {
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id ).child("it.json");
		const task = storageRef.put(file);

		task.on("state_changed", (snapshot) => {
			this.setState({
				barraLoadEnd05: snapshot.totalBytes,
				barraLoad05: snapshot.bytesTransferred,
			});

		}, (error) => {
			this.setState({
				message05: "Error: " + error,
			});
		}, () => {
			this.setState({
				message05: "Json cargado correctamente",
				jsonIT: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
			});
			this.guarda();
		});

	}

	cargaJsonPT(event) {
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id ).child("pt.json");
		const task = storageRef.put(file);

		task.on("state_changed", (snapshot) => {
			this.setState({
				barraLoadEnd06: snapshot.totalBytes,
				barraLoad06: snapshot.bytesTransferred,
			});

		}, (error) => {
			this.setState({
				message06: "Error: " + error,
			});
		}, () => {
			this.setState({
				message06: "Json cargado correctamente",
				jsonPT: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
			});
			this.guarda();
		});

	}

	async guarda() {
		if (this.state.jsonES === this.state.imagenNull) {
			await this.setState({jsonES: null});
		}
		if (this.state.jsonEN === this.state.imagenNull) {
			await this.setState({jsonEN: null});
		}
		if (this.state.jsonFR === this.state.imagenNull) {
			await this.setState({jsonFR: null});
		}
		if (this.state.jsonDE === this.state.imagenNull) {
			await this.setState({jsonDE: null});
		}
		if (this.state.jsonIT === this.state.imagenNull) {
			await this.setState({jsonIT: null});
		}
		if (this.state.jsonPT === this.state.imagenNull) {
			await this.setState({jsonPT: null});
		}
		await firestore.collection("pathbooks").doc(this.state.pathbooksInfo.id).update({
			jsonContent: {
				jsonES: this.state.jsonES,
				jsonEN: this.state.jsonEN,
				jsonFR: this.state.jsonFR,
				jsonDE: this.state.jsonDE,
				jsonIT: this.state.jsonIT,
				jsonPT: this.state.jsonPT,
			},

		});
		this.validaPathbooksExiste();
	}

	async componentDidMount() {
		/////lista de pathbooks
		let selectPathbookList = [];
		await this.state.pathbooksLista.forEach((task) => {
			let idPathbook = task.doc.id;
			let nombrePathbook = task.doc.data().title["es"];
			selectPathbookList.push({id: idPathbook, nombre: nombrePathbook});
		});
		this.setState({selectPathbooks: selectPathbookList});
	}


	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header">
					<h2 className="panel-title">Json de Pathbook</h2>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Busca Pathbook</h3>
						</div>

						<div className="d-flex">
							<div className="card-body">
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<div className="container">
													<label>Si quieres editar json a un pathbook buscalo aqui:</label>
													<div className="row">
														<input className="form-control" list="selectPathbook" id="generoSelected" name="pathbookSeleccionado" type="select" placeholder="Elige un pathbook a modificar" value={this.state.pathbookSeleccionado} onChange={this.manejadorCambios}/>
														<datalist id="selectPathbook">
															{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
														</datalist>
													</div>
												</div>
												<br/>
												<div className="form-group">
													{/*validaBuscadorPathbook*/}
													<button onClick={() => this.validaPathbooksExiste()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Seleccionar pathbook</button>
												</div>

											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="d-flex">
							<div className="card-body">
								{
									!this.state.pathbooksInfo
										? (<div>
											<label>Selecciona un pathbook:</label>
										</div>)
										:
										<div>
											<label>Pathbook selecionado</label>
											<h3>{this.state.pathbooksInfo.data().title["es"]}</h3>
											<div className="container">
												<div className="row">

													<div className="col-sm card-header">
														<h6>Json ES</h6>
														<div className="row row-inputs">
															<progress value={this.state.barraLoad01} max={this.state.barraLoadEnd01}/>
														</div>
														<div className="row row-inputs">
															<br/>
															<div className="input-group mb-3">
																<div className="custom-file">
																	<input type="file" accept="application/json" className="custom-file-input" onChange={this.cargaJsonES.bind(this)}/>
																	<label className="custom-file-label" htmlFor="inputGroupFile01">Json ES (Español)</label>
																</div>
															</div>
															<div className="input-group mb-3">
																{this.state.message01}
															</div>
															<br/>
															<div className="col-sm">
																<a download="JsonES" href={this.state.jsonES} target="_blank" rel="noopener noreferrer"><h5>Descarga Json ES</h5></a>
																<br/>
															</div>
														</div>
													</div>

													<div className="col-sm card-header">
														<h6>Json EN</h6>
														<div className="row row-inputs">
															<progress value={this.state.barraLoad02} max={this.state.barraLoadEnd02}/>
														</div>
														<div className="row row-inputs">
															<br/>
															<div className="input-group mb-3">
																<div className="custom-file">
																	<input type="file" accept="application/json" className="custom-file-input" onChange={this.cargaJsonEN.bind(this)}/>
																	<label className="custom-file-label" htmlFor="inputGroupFile02">Json EN (Ingles)</label>
																</div>
															</div>
															<div className="input-group mb-3">
																{this.state.message02}
															</div>
															<br/>
															<div className="col-sm">
																<a download="JsonEN" href={this.state.jsonEN} target="_blank" rel="noopener noreferrer"><h5>Descarga Json EN</h5></a>
																<br/>
															</div>
														</div>
													</div>

													<div className="col-sm card-header">
														<h6>Json FR</h6>
														<div className="row row-inputs">
															<progress value={this.state.barraLoad03} max={this.state.barraLoadEnd03}/>
														</div>
														<div className="row row-inputs">
															<br/>
															<div className="input-group mb-3">
																<div className="custom-file">
																	<input type="file" accept="application/json" className="custom-file-input" onChange={this.cargaJsonFR.bind(this)}/>
																	<label className="custom-file-label" htmlFor="inputGroupFile03">Json FR (Frances)</label>
																</div>
															</div>
															<div className="input-group mb-3">
																{this.state.message03}
															</div>
															<br/>
															<div className="col-sm">
																<a download="JsonFR" href={this.state.jsonFR} target="_blank" rel="noopener noreferrer"><h5>Descarga Json FR</h5></a>
																<br/>
															</div>
														</div>
													</div>

												</div>

												<div className="row">

													<div className="col-sm card-header">
														<h6>Json DE</h6>
														<div className="row row-inputs">
															<progress value={this.state.barraLoad04} max={this.state.barraLoadEnd04}/>
														</div>
														<div className="row row-inputs">
															<br/>
															<div className="input-group mb-3">
																<div className="custom-file">
																	<input type="file" accept="application/json" className="custom-file-input" onChange={this.cargaJsonDE.bind(this)}/>
																	<label className="custom-file-label" htmlFor="inputGroupFile04">Json DE (Aleman)</label>
																</div>
															</div>
															<div className="input-group mb-3">
																{this.state.message04}
															</div>
															<br/>
															<div className="col-sm">
																<a download="JsonDE" href={this.state.jsonDE} target="_blank" rel="noopener noreferrer"><h5>Descarga Json DE</h5></a>
																<br/>
															</div>
														</div>
													</div>

													<div className="col-sm card-header">
														<h6>Json IT</h6>
														<div className="row row-inputs">
															<progress value={this.state.barraLoad05} max={this.state.barraLoadEnd05}/>
														</div>
														<div className="row row-inputs">
															<br/>
															<div className="input-group mb-3">
																<div className="custom-file">
																	<input type="file" accept="application/json" className="custom-file-input" onChange={this.cargaJsonIT.bind(this)}/>
																	<label className="custom-file-label" htmlFor="inputGroupFile05">Json IT (Italiano)</label>
																</div>
															</div>
															<div className="input-group mb-3">
																{this.state.message05}
															</div>
															<br/>
															<div className="col-sm">
																<a download="JsonIT" href={this.state.jsonIT} target="_blank" rel="noopener noreferrer"><h5>Descarga Json IT</h5></a>
																<br/>
															</div>
														</div>
													</div>

													<div className="col-sm card-header">
														<h6>Json PT</h6>
														<div className="row row-inputs">
															<progress value={this.state.barraLoad06} max={this.state.barraLoadEnd06}/>
														</div>
														<div className="row row-inputs">
															<br/>
															<div className="input-group mb-3">
																<div className="custom-file">
																	<input type="file" accept="application/json" className="custom-file-input" onChange={this.cargaJsonPT.bind(this)}/>
																	<label className="custom-file-label" htmlFor="inputGroupFile06">Json PT (Portugues)</label>
																</div>
															</div>
															<div className="input-group mb-3">
																{this.state.message06}
															</div>
															<br/>
															<div className="col-sm">
																<a download="JsonPT" href={this.state.jsonPT} target="_blank" rel="noopener noreferrer"><h5>Descarga Json PT</h5></a>
																<br/>
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);

	}
}

export default FormJsonPathbook;


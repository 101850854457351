import React, {Component} from "react";
import {firestore} from "../../js/firebase";

class ListaVisible extends Component {
	constructor(props) {
		super();
		this.state = {
			pathbookID: props.data.id,
			nombrePath: "",
			portadaEs: "",
			portadaEn: "",
			feature: "",
		};
	}

	componentDidMount() {

		///agregamos el nombre a la lista
		firestore.doc("pathbooks/" + this.state.pathbookID).get().then((documentSnapshot) => {
			let pathbookDb = documentSnapshot.data();
			let pathbookName = pathbookDb.title["es-MX"];
			let imagenPathEs = pathbookDb.cover["es-MX"];
			let imagenPathEn = pathbookDb.cover["en-US"];
			let featureImage = pathbookDb.featureImage;

			this.setState({nombrePath: pathbookName});
			this.setState({portadaEs: imagenPathEs});
			this.setState({portadaEn: imagenPathEn});
			this.setState({feature: featureImage});
		});
	}

	render() {
		console.log(this.state.feature);
		return (
			<div>
				<div className="row">
					<div className="col-sm">
						<label>{this.state.nombrePath}</label>
					</div>
					<div className="col-sm">
						<img src={this.state.portadaEs} height="130" alt="portadaEs"/>
					</div>
					<div className="col-sm">
						<img src={this.state.portadaEn} height="130" alt="portadaEn"/>
					</div>
					<div className="col-sm">
						<img src={this.state.feature} height="50" alt="Feature"/>
					</div>
				</div>
			</div>
		);
	}
}

export default ListaVisible;

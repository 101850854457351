import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import swal from "sweetalert2";

class FichaFeatures extends Component {
	constructor(props) {
		super();
		this.state = {
			priorityFeature: props.data.priority,
			imagePathbook: "",
			nombrePathbook: "",
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}
	///////borar un feature
	borrarFeature(idFeature) {
		firestore.collection(this.props.data.dbLocation).doc(idFeature).delete().then(function () {
			swal.fire({
				title: "Borrado con exito",
				text: "Buen trabajo",
				type: "error",
				confirmButtonText: "OK",
			});
		}).catch(function (error) {
			swal.fire({
				title: "Error al borrar",
				text: "error",
				type: "error",
				confirmButtonText: "Entendido",
			});
		});
	}
	///////editar feature
	editarPrioridadFeature(idFeature, Prioridad) {
		if (Prioridad > 0) {
			firestore.collection(this.props.data.dbLocation).doc(idFeature).update({
				priority: parseInt(Prioridad),
			});
		} else {
			swal.fire({
				title: "Numero no valido",
				text: "Ingresa un numero mayor a cero ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		}
	}

	componentDidMount() {
		// ingresamos informacion de una pathbook
		firestore.doc("pathbooks/" + this.props.data.pathbookID).get().then((documentSnapshot) => {
			const dataPathbook = documentSnapshot.data();
			this.setState({imagePathbook: dataPathbook.featureImage});
			this.setState({nombrePathbook: dataPathbook.title["es-MX"]});
		});

	}

	render() {
		return (
			<div>
				<div className="col-md-12">
					<div className="card  widget-user">
						<div className="description-block">
							<span>Feature:</span>
							<h5 className="description-header">{this.props.data.activo}</h5>
						</div>
						<img src={this.state.imagePathbook} alt="Avatar" width="100%"/>
						<div className="description-block">
							<span>Feature ingles</span>
							<h5 className="description-header">{this.props.data.nombreEn}</h5>
							<span>Feature español</span>
							<h5 className="description-header">{this.props.data.nombreEs}</h5>
							<span>Nombre del pathbook</span>
							<h5 className="description-header">{this.state.nombrePathbook}</h5>
						</div>
						<div className="description-block">
							<span>PRIORIDAD</span>
							<h5 className="description-header">{this.props.data.priority}</h5>
						</div>
						<div className="col-sm-12">
							<div className="row">
								<div className="col-sm-12">
									<div className="description-block">
										<h5 className="description-header">EDITAR PRIORIDAD O BORRAR</h5>
									</div>
								</div>
								<div className="col-sm-6 border-right">
									<div className="description-block">
										<input className="form-control" name="priorityFeature" placeholder="Prioridad (numero)" type="number" value={this.state.priorityFeature} onChange={this.manejadorCambios}/>
										<button onClick={() => this.editarPrioridadFeature(this.props.data.id, this.state.priorityFeature)} type="button" className="btn btn-block bg-gradient-warning">CAMBIAR</button>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="description-block">
										<h4>BORRAR</h4>
									</div>
									<div className="description-block">
										<button onClick={() => this.borrarFeature(this.props.data.id)} type="button" className="btn btn-block bg-gradient-danger">BORRAR</button>
									</div>
								</div>
								<div className="col-sm-6 border-right"/>
								<div className="col-sm-6"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FichaFeatures;

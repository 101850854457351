import React, {Component} from "react";
import FichaPathbookControl from "./FichaPathbookControl";
import {firestore} from "../../js/firebase";

///// Componente para armar la pantlla
class ControlPathbooks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pathbooksLista: props.dataGeneral,
			pathbooksNoActivados: [],
			pathbooksActivados: [],
		};
	}

	listaPAthboos(){
		let pathbookNoActivados = [];
		let pathbookActivados = [];
		firestore.collection("pathbooks").onSnapshot(querySnapshot => {
			pathbookNoActivados = [];
			pathbookActivados = [];
			let features = querySnapshot.docs
			features.forEach((doc) => {
					if (doc.data().active === false){
						pathbookNoActivados.push({pathbook: doc});
					} else if (doc.data().visible === false){
						pathbookNoActivados.push({pathbook: doc});
					} else if (!doc.data().visible){
						pathbookNoActivados.push({pathbook: doc});
					} else {
					pathbookActivados.push({pathbook: doc});
				}

			});
			this.setState({
				pathbooksNoActivados: pathbookNoActivados,
				pathbooksActivados: pathbookActivados,
			});
		});
	}

	async componentDidMount() {
		this.listaPAthboos();
	}

	render() {
		return (
			<div className="content-wrapper">
				<div className="content-header">
					<h2 className="panel-title">Pathbook Control</h2>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Pathbooks no activados o no visibles</h3>
						</div>
						<div className="d-flex">
							<div className="card-body">
								<div className="container">
									<h2>Aqui puedes activar los pathbooks:</h2>
								</div>
								<br/>
								<div className="container">
									{this.state.pathbooksNoActivados.map(task => <div key={task.pathbook.id}>
										<FichaPathbookControl data={task}/>
									</div>)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Pathbooks activados y visibles</h3>
						</div>
						<div className="d-flex">
							<div className="card-body">
								<div className="container">
									<h2>Aqui puedes desactivar los pathbooks:</h2>
								</div>
								<br/>
								<div className="container">
									{this.state.pathbooksActivados.map(task => <div key={task.pathbook.id}>
										<FichaPathbookControl data={task}/>
									</div>)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ControlPathbooks;


///////creamos un metodo y conectamos nuestro firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

var config = {
	////////// demo //////////
	// apiKey: "AIzaSyBe8UsQ-eCipUZ6R0ebf3H7Lag1Dl4sygo",
	// authDomain: "viksoftwareant.firebaseapp.com",
	// databaseURL: "https://viksoftwareant.firebaseio.com",
	// projectId: "viksoftwareant",
	// storageBucket: "viksoftwareant.appspot.com",
	// messagingSenderId: "32819040892",
	// appId: "1:32819040892:web:89d52548f4020544"

	////////// Original //////////
	apiKey: "AIzaSyC-c7GeBSW3ucGU_ujH8uEqNtujLiF6aAk",
	authDomain: "pathbook.firebaseapp.com",
	databaseURL: "https://pathbook.firebaseio.com",
	projectId: "pathbook",
	storageBucket: "pathbook.appspot.com",
	messagingSenderId: "407045997235",
	appId: "1:407045997235:web:4a1a26f11ae0826d",
};
firebase.initializeApp(config);

const auth = firebase.auth();
const firestore = firebase.firestore();
const fieldValue = firebase.firestore.FieldValue;
const storage = firebase.storage();
const functions = firebase.functions();

export {auth, firestore, storage, fieldValue, functions};


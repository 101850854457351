import React, {Component} from "react";
import {firestore, storage} from "../../js/firebase";
import swal from "sweetalert2";
import Moment from "moment";
import ListaVisible from "./ListaVisible";
import FichaCarrusel from "./FichaCarrusel";
import carrManual from "../../assets/img/carruselManual.png";
import carrManualGrande from "../../assets/img/carruselManualBig.png";
import carrManualMini from "../../assets/img/carruselManualMini.png";

///// Componente para armar la pantlla
class AppCarrusel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectCarucel: [],
			pathbooksLista: props.dataGeneral.listaPathbooks,
			autoresLista: props.dataGeneral.listaAutores,
			generoLista: props.dataGeneral.listaGenero,

			classificationCarrusel: "Null",
			enviarCarruselA: "",
			tituloEnCarrusel: "",
			tituloEsCarrusel: "",
			tituloDeCarrusel: "",
			tituloFrCarrusel: "",
			tituloItCarrusel: "",
			tituloPtCarrusel: "",

			cantidadCarusel: 0,
			prioridadCarrusel: 0,

			autorCarrusel: "",
			authorFeatureVerificado: "",
			generoCarrusel: "",
			generoFeatureVerificado: "",
			pathbookCarrusel: "",
			pathbookFeatureVerificado: "",
			tipoCarruselTamano: "",
			contadorListaPantbooks: 0,

			carrucelTipo: "Null",

			selectAutores: [],
			selectGenero: [],
			selectPathbooks: [],
			selectPathbooksNum: 0,
			listaPathbooks: [],
			carruselListPathbooks: [],
			carruselListKids: [],
			carruselListStudents: [],
			destacadoPathbook: "",


			/////cargar imagenes
			pathbooksImagen: "",
			carruselImagenes: [],
			minimoImagenes: 5,
			maximoImagenes: 10,
			contadorImagenes: 0,
			imagenC01: "",
			valorBarraLoad: 0,
			valorBarraLoadEnd: 0,
			urlIni: "https://firebasestorage.googleapis.com/v0/b/pathbook.appspot.com/o/carruseles%2F",
			urlEnd: "?alt=media&token=155d59b3-a629-440f-ab95-7fad07e6aa9f",
		};
		//console.log(this.state.pathbooks)
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	/////cargamos imagenes para el tipo pathbook
	handleOnChange(event) {
		if (this.state.contadorImagenes < this.state.maximoImagenes) {
			const file = event.target.files[0];
			const namePict = "pictur" + (this.state.contadorImagenes + 1) + Moment().format();
			const storageRef = storage.ref(`carruseles/${namePict}`);
			const task = storageRef.put(file);

			task.on("state_changed", (snapshot) => {
				this.setState({
					valorBarraLoad: snapshot.bytesTransferred,
					valorBarraLoadEnd: snapshot.totalBytes,
				});
			}, (error) => {
				this.setState({
					message: "Error: " + error,
				});
			}, () => {
				this.state.carruselImagenes.push(this.state.urlIni + task.snapshot.metadata.name + this.state.urlEnd);
				this.setState({
					contadorImagenes: this.state.contadorImagenes + 1,
					message: "cargado correctamente",
					imagenC01: this.state.urlIni + task.snapshot.metadata.name + this.state.urlEnd,
				});
			});

		} else {
			swal.fire({title: "Maximo de imagenes alcanzado", text: "Agrega tu carrusel ", type: "error", confirmButtonText: "Entendido"});
		}
	}


	////manejamos el cambios en el formulario
	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({[name]: event.target.value});
	}

	////manejamos el imput change para multiples chekbooks
	manejadorImputs1() {
		this.setState({tipoCarruselTamano: "manual"});
	}

	manejadorImputs2() {
		this.setState({tipoCarruselTamano: "hugeManual"});
	}

	manejadorImputs3() {
		this.setState({tipoCarruselTamano: "miniFeatures"});
	}

	manejadorImputs4() {
		this.setState({enviarCarruselA: "home"});
	}

	manejadorImputs5() {
		this.setState({enviarCarruselA: "home-kids"});
	}

	manejadorImputs6() {
		this.setState({enviarCarruselA: "home-students"});
	}

	validaFormCarrusel(Tipo) {
		let regex = new RegExp("^[0-9]+$");

		if (!this.state.enviarCarruselA) {
			swal.fire({title: "Seleciona a donde mandaras tu carrusel Kids o pathbooks", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.classificationCarrusel === "Null") {
			swal.fire({title: "Ingresa una clasificación", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloEnCarrusel) {
			swal.fire({title: "Ingresa un titulo en Ingles para tu carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloEsCarrusel) {
			swal.fire({title: "Ingresa un titulo en Español para tu carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloDeCarrusel) {
			swal.fire({title: "Ingresa un titulo en Aleman para tu carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloFrCarrusel) {
			swal.fire({title: "Ingresa un titulo en Frances para tu carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloItCarrusel) {
			swal.fire({title: "Ingresa un titulo en Italiano para tu carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloPtCarrusel) {
			swal.fire({title: "Ingresa un titulo en Portugues para tu carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.cantidadCarusel) {
			swal.fire({title: "Ingresa la cantidad de pathbooks maxima de tu carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (!regex.test(this.state.cantidadCarusel)) {
			swal.fire({title: "El valor de la cantidad no es un numero entero", text: "agrega un numero entero para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.prioridadCarrusel) {
			swal.fire({title: "Ingresa la prioridad para tu carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (!regex.test(this.state.prioridadCarrusel)) {
			swal.fire({title: "El valor de la prioridad no es un numero entero", text: "agrega un numero entero para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.carrucelTipo === "Null") {
			swal.fire({title: "Tipo no esta disponible", text: "selecciona uno ", type: "error", confirmButtonText: "Entendido"});
		} else if (Tipo === "Autor") {
			this.autorValidaSave();
		} else if (Tipo === "Genero") {
			this.generoValidaSave();
		} else if (Tipo === "Manual") {
			this.manualValidaSave();
		} else if (Tipo === "Pathbook") {
			this.pathbookValida();
		} else if (Tipo === "Destacado") {
			this.destacadoValida();
		}
	}


	destacadoValida() {
		if (!this.state.pathbookCarrusel) {
			swal.fire({title: "Falta escojer un Pathbook", text: "ingresa un pathbook para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else {
			let pathbookID = this.state.pathbookCarrusel.split(" -   --> ");
			//////evaluamos que el pathbook exista
			firestore.doc("pathbooks/" + pathbookID[1]).get().then((documentSnapshot) => {
				let dataPathbook = documentSnapshot;
				if (dataPathbook.data().active) {
					this.setState({destacadoPathbook: firestore.doc("pathbooks/" + pathbookID[1])});
				} else {
					swal.fire({title: "Pathbook no activado", text: "ingresa uno activado para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
			}).then(() => {
				this.destacadoSave();
			}).catch(error => {
				swal.fire({title: "Ese Pathbook no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
			});

		}
	}

	destacadoSave() {
		////////guardamos los datos//////
		firestore.collection(this.state.enviarCarruselA).doc().set({
			classification: this.state.classificationCarrusel,
			title: {
				"en-US": this.state.tituloEnCarrusel,
				"es-MX": this.state.tituloEsCarrusel,
				"en": this.state.tituloEnCarrusel,
				"es": this.state.tituloEsCarrusel,
				"de": this.state.tituloDeCarrusel,
				"fr": this.state.tituloFrCarrusel,
				"it": this.state.tituloItCarrusel,
				"pt": this.state.tituloPtCarrusel,
			},
			limit: parseInt(this.state.cantidadCarusel),
			priority: parseInt(this.state.prioridadCarrusel),
			type: "award",
			added: {
				timestamp: Moment().toDate(),
				user: firestore.doc("users/CMk4wSMR47XFHKZ1zY2yerH9Wp42"),
			},
			pathbook: this.state.destacadoPathbook,
			modified: null,
		});
		////////guardamos los datos//////
		this.limpiarForm();
	}

	//////validamos lista 1 pathbook
	pathbookValida() {
		if (this.state.contadorImagenes >= this.state.minimoImagenes) {
			if (!this.state.pathbookCarrusel) {
				swal.fire({title: "Falta escojer un Pathbook", text: "ingresa un pathbook para continuar ", type: "error", confirmButtonText: "Entendido"});
			} else {
				let pathbookID = this.state.pathbookCarrusel.split(" -   --> ");
				//////evaluamos que el pathbook exista
				firestore.doc("pathbooks/" + pathbookID[1]).get().then((documentSnapshot) => {
					let dataPathbook = documentSnapshot;
					if (dataPathbook.data().active) {
						this.setState({pathbooksImagen: firestore.doc("pathbooks/" + pathbookID[1])});
					}
				}).then(() => {
					this.pathbookSave();
				}).catch(error => {
					swal.fire({title: "Ese Pathbook no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				});

			}
		} else {
			swal.fire({title: "Faltan imagenes", text: "El minimo es de 5 ", type: "error", confirmButtonText: "Entendido"});
		}
	}

	pathbookSave() {
		firestore.collection(this.state.enviarCarruselA).doc().set({
			classification: this.state.classificationCarrusel,
			title: {
				"en-US": this.state.tituloEnCarrusel,
				"es-MX": this.state.tituloEsCarrusel,
				"en": this.state.tituloEnCarrusel,
				"es": this.state.tituloEsCarrusel,
				"de": this.state.tituloDeCarrusel,
				"fr": this.state.tituloFrCarrusel,
				"it": this.state.tituloItCarrusel,
				"pt": this.state.tituloPtCarrusel,
			},
			limit: parseInt(this.state.cantidadCarusel),
			priority: parseInt(this.state.prioridadCarrusel),
			type: "pathbook",
			added: {
				timestamp: Moment().toDate(),
				user: firestore.doc("users/CMk4wSMR47XFHKZ1zY2yerH9Wp42"),
			},
			images: this.state.carruselImagenes,
			pathbook: this.state.pathbooksImagen,
			modified: null,
		});
		////////guardamos los datos//////
		this.limpiarForm();
	}


	/////agregamos pathbooks a la lista
	agregarAListaPathbooks() {
		if (!this.state.cantidadCarusel) {
			swal.fire({title: "Ingresa la cantidad de pathbooks maxima de tu carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.cantidadCarusel <= 0) {
			swal.fire({title: "Ingresa la cantidad de Maximo de pathbooks Mayor a 0", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.listaPathbooks === parseInt(this.state.cantidadCarusel)) {
			console.log("ok");
		} else if (parseInt(this.state.cantidadCarusel) === this.state.contadorListaPantbooks) {
			swal.fire({title: "Limite maximo de pathboks alcanzado agrega un limite mayor", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else {
			let pathbookID = this.state.pathbookCarrusel.split(" -   --> ");
			let contadorpathbooks = this.state.selectPathbooksNum;
			//////evaluamos que el pathbook exista
			firestore.doc("pathbooks/" + pathbookID[1]).get().then((documentSnapshot) => {
				let dataPathbook = documentSnapshot;
				if (dataPathbook.data().active) {
					this.state.listaPathbooks.push(firestore.doc("pathbooks/" + pathbookID[1]));
					this.setState({contadorListaPantbooks: this.state.contadorListaPantbooks + 1});
					contadorpathbooks = contadorpathbooks + 1;
					this.setState({selectPathbooksNum: contadorpathbooks});
				}
			}).then(() => {
				this.setState({pathbookCarrusel: ""});
			}).catch(error => {
				swal.fire({title: "Ese Pathbook no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
			});
		}
	}

	limpiarAListaPathbooks() {
		this.setState({listaPathbooks: []});
	}

	manualValidaSave() {

		if (!this.state.tipoCarruselTamano) {
			swal.fire({title: "Selecciona un tamaño de carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.listaPathbooks.length === 0) {
			swal.fire({title: "No encuentro pathbooks en la lista ingrea alguno", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else {
			////////guardamos los datos//////
			firestore.collection(this.state.enviarCarruselA).doc().set({
				classification: this.state.classificationCarrusel,
				title: {
					"en-US": this.state.tituloEnCarrusel,
					"es-MX": this.state.tituloEsCarrusel,
					"en": this.state.tituloEnCarrusel,
					"es": this.state.tituloEsCarrusel,
					"de": this.state.tituloDeCarrusel,
					"fr": this.state.tituloFrCarrusel,
					"it": this.state.tituloItCarrusel,
					"pt": this.state.tituloPtCarrusel,
				},
				limit: parseInt(this.state.cantidadCarusel),
				priority: parseInt(this.state.prioridadCarrusel),
				type: this.state.tipoCarruselTamano,
				added: {
					timestamp: Moment().toDate(),
					user: firestore.doc("users/CMk4wSMR47XFHKZ1zY2yerH9Wp42"),
				},
				pathbooks: this.state.listaPathbooks,
				modified: null,
			});
			////////guardamos los datos//////

			this.limpiarForm();
		}
	}

	generoValidaSave() {
		if (this.state.generoCarrusel) {
			let generoID = this.state.generoCarrusel.split(" -   --> ");
			//////evaluamos que el pathbook exista
			firestore.doc("genres/" + generoID[1]).get().then((documentSnapshot) => {
				this.setState({generoFeatureVerificado: generoID[1]});
			}).then(() => {
				////////guardamos los datos//////
				firestore.collection(this.state.enviarCarruselA).doc().set({
					classification: this.state.classificationCarrusel,
					title: {
						"en-US": this.state.tituloEnCarrusel,
						"es-MX": this.state.tituloEsCarrusel,
						"en": this.state.tituloEnCarrusel,
						"es": this.state.tituloEsCarrusel,
						"de": this.state.tituloDeCarrusel,
						"fr": this.state.tituloFrCarrusel,
						"it": this.state.tituloItCarrusel,
						"pt": this.state.tituloPtCarrusel,
					},
					limit: parseInt(this.state.cantidadCarusel),
					priority: parseInt(this.state.prioridadCarrusel),
					type: "genre",
					added: {
						timestamp: Moment().toDate(),
						user: firestore.doc("users/CMk4wSMR47XFHKZ1zY2yerH9Wp42"),
					},
					genre: firestore.doc("genres/" + this.state.generoFeatureVerificado),
					modified: null,
				});
				////////guardamos los datos//////
				this.limpiarForm();
			}).catch(error => {
				swal.fire({title: "Ese genero no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
			});
		}
	}

	autorValidaSave() {
		if (this.state.autorCarrusel) {
			let autorID = this.state.autorCarrusel.split(" -   --> ");
			//////evaluamos que el pathbook exista
			firestore.doc("authors/" + autorID[1]).get().then((documentSnapshot) => {
				let dataAuthor = documentSnapshot;
				if (dataAuthor.data().active) {
					this.setState({authorFeatureVerificado: autorID[1]});
				}
			}).then(() => {
				////////guardamos los datos//////
				firestore.collection(this.state.enviarCarruselA).doc().set({
					classification: this.state.classificationCarrusel,
					title: {
						"en-US": this.state.tituloEnCarrusel,
						"es-MX": this.state.tituloEsCarrusel,
						"en": this.state.tituloEnCarrusel,
						"es": this.state.tituloEsCarrusel,
						"de": this.state.tituloDeCarrusel,
						"fr": this.state.tituloFrCarrusel,
						"it": this.state.tituloItCarrusel,
						"pt": this.state.tituloPtCarrusel,
					},
					limit: parseInt(this.state.cantidadCarusel),
					priority: parseInt(this.state.prioridadCarrusel),
					type: "author",
					added: {
						timestamp: Moment().toDate(),
						user: firestore.doc("users/CMk4wSMR47XFHKZ1zY2yerH9Wp42"),
					},
					author: firestore.doc("authors/" + this.state.authorFeatureVerificado),
					modified: null,
				});
				////////guardamos los datos//////
				this.limpiarForm();

			}).catch(error => {
				swal.fire({title: "Ese autor no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
			});
		}
	}

	limpiarForm() {
		this.setState({
			tituloEnCarrusel: "",
			tituloEsCarrusel: "",
			tituloDeCarrusel: "",
			tituloFrCarrusel: "",
			tituloItCarrusel: "",
			tituloPtCarrusel: "",
			classificationCarrusel: "Null",
			cantidadCarusel: 0,
			prioridadCarrusel: 0,
			autorCarrusel: "",
			generoCarrusel: "",
			authorFeatureVerificado: "",
			pathbookCarrusel: "",
			carrucelTipo: "",
			tipoCarruselTamano: "",
			listaPathbooks: [],
			pathbooksImagen: "",
			carruselImagenes: [],
			minimoImagenes: 5,
			maximoImagenes: 10,
			contadorImagenes: 0,
			imagenC01: "",
			valorBarraLoad: 0,
			valorBarraLoadEnd: 0,
			destacadoPathbook: "",
			selectPathbooksNum: 0,
		});

		swal.fire({
			title: "Guardado con exito",
			text: "Buen trabajo",
			type: "error",
			confirmButtonText: "OK",
		});
	}

	async componentDidMount() {
		///////llenamos la lista de los pathbooks
		/////lista de pathbooks
		let selectPathbookList = [];
		await this.state.pathbooksLista.forEach((task) => {
			if (task.doc.data().active === true) {
				let idPathbook = task.doc.id;
				let nombrePathbook = task.doc.data().title["es"];
				let nombrePathbookEn = task.doc.data().title["en"];
				selectPathbookList.push({id: idPathbook, nombre: nombrePathbook, nombreEn: nombrePathbookEn});
			}
		});
		this.setState({selectPathbooks: selectPathbookList});


		///////llenamos la lista de los Autores
		let selectAutoresList = [];
		await this.state.autoresLista.forEach((task) => {
			if (task.doc.data().active === true) {
				let idAutor = task.doc.id;
				let nombreAutor = task.doc.data().name;
				selectAutoresList.push({id: idAutor, nombre: nombreAutor});
			}
		});
		this.setState({selectAutores: selectAutoresList});

		///////llenamos la lista de los Genero
		let selectGeneroList = [];
		await this.state.generoLista.forEach((task) => {
			if (task.doc.data().active === true) {
				let idGenero = task.doc.id;
				let nombreGenero = task.doc.data()["es-MX"];
				selectGeneroList.push({id: idGenero, nombre: nombreGenero});
			}
		});
		this.setState({selectGenero: selectGeneroList});

		///////llenamos la lista de los home pathbooks
		let listaDataP = [];
		firestore.collection("home").onSnapshot(querySnapshot => {
			///esta variable evita que se dupliquen las consultas
			listaDataP = [];
			let features = querySnapshot.docs;
			features.forEach((doc) => {
				let litaPathbooks = null
				if(doc.data().pathbooks){
					litaPathbooks = doc.data().pathbooks
				}
				listaDataP.push({
					idCarr: doc.id,
					dbLocation: "home",
					priority: doc.data().priority,
					nombreEs: doc.data().title["es"],
					nombreEn: doc.data().title["en"],
					typeCarr: doc.data().type,
					limit: doc.data().limit,
					pathbooks: litaPathbooks
				});
			});
			//////ordenamos la lista por prioridad (priority)
			listaDataP.sort(function (a, b) {
				return a.priority - b.priority;
			});
			this.setState({carruselListPathbooks: listaDataP});
		});
		///////llenamos la lista de los home pathbooks

		///////llenamos la lista de los home kids
		let listaDataK = [];
		firestore.collection("home-kids").onSnapshot(querySnapshot => {
			///esta variable evita que se dupliquen las consultas
			listaDataK = [];
			let features = querySnapshot.docs;
			features.forEach((doc) => {
				let litaPathbooks = null
				if(doc.data().pathbooks){
					litaPathbooks = doc.data().pathbooks
				}
				listaDataK.push({
					idCarr: doc.id,
					dbLocation: "home-kids",
					priority: doc.data().priority,
					nombreEs: doc.data().title["es"],
					nombreEn: doc.data().title["en"],
					typeCarr: doc.data().type,
					limit: doc.data().limit,
					pathbooks: litaPathbooks
				});
			});
			//////ordenamos la lista por prioridad (priority)
			listaDataK.sort(function (a, b) {
				return a.priority - b.priority;
			});
			this.setState({carruselListKids: listaDataK});
		});
		///////llenamos la lista de los home kids
		///////llenamos la lista de los home kids
		let listaDataE = [];
		firestore.collection("home-students").onSnapshot(querySnapshot => {
			///esta variable evita que se dupliquen las consultas
			listaDataE = [];
			let features = querySnapshot.docs;
			features.forEach((doc) => {
				let litaPathbooks = null
				if(doc.data().pathbooks){
					litaPathbooks = doc.data().pathbooks
				}
				listaDataE.push({
					idCarr: doc.id,
					dbLocation: "home-students",
					priority: doc.data().priority,
					nombreEs: doc.data().title["es"],
					nombreEn: doc.data().title["en"],
					typeCarr: doc.data().type,
					limit: doc.data().limit,
					pathbooks: litaPathbooks
				});
			});
			//////ordenamos la lista por prioridad (priority)
			listaDataE.sort(function (a, b) {
				return a.priority - b.priority;
			});
			this.setState({carruselListStudents: listaDataE});
		});
		///////llenamos la lista de los home students
	}

	render() {
		return (<div className="content-wrapper">
			<div className="content-header d-flex">
				<h2 className="panel-title">Carrusel</h2>
			</div>
			<div className="content-header">
				<div className="container-fluid">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Carrusel</h3>
						</div>
						<div>
							<div className="card-body">
								<label>Carrusel para:</label>
								<h6>Selecciona a cual de los dos menus se mandara tu carrusel</h6>
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-check">
												<input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs4()}/>
												<label className="form-check-label">Pathbooks</label>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-check">
												<input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs5()}/>
												<label className="form-check-label">Kids</label>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-check">
												<input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs6()}/>
												<label className="form-check-label">Estudiantes</label>
											</div>
										</div>
									</div>
								</div>
								<br/>
								<div className="form-group">
									<label>Seleeciona una clasificación</label>
									<select className="form-control" name="classificationCarrusel" value={this.state.classificationCarrusel} onChange={this.manejadorCambios}>
										<option value="Null">Seleciona uno</option>
										<option value="A">A</option>
										<option value="AA">AA</option>
										<option value="B">B</option>
										<option value="B15">B15</option>
										<option value="C">C</option>
									</select>
								</div>
								<div className="form-group">
									<label>Titulo de carrusel</label>
									<input className="form-control" name="tituloEnCarrusel" placeholder="Titulo Ingles" type="text" value={this.state.tituloEnCarrusel} onChange={this.manejadorCambios}/>
									<input className="form-control" name="tituloEsCarrusel" placeholder="Titulo Español" type="text" value={this.state.tituloEsCarrusel} onChange={this.manejadorCambios}/>
									<input className="form-control" name="tituloDeCarrusel" placeholder="Titulo Aleman" type="text" value={this.state.tituloDeCarrusel} onChange={this.manejadorCambios}/>
									<input className="form-control" name="tituloFrCarrusel" placeholder="Titulo Frances" type="text" value={this.state.tituloFrCarrusel} onChange={this.manejadorCambios}/>
									<input className="form-control" name="tituloItCarrusel" placeholder="Titulo Italiano" type="text" value={this.state.tituloItCarrusel} onChange={this.manejadorCambios}/>
									<input className="form-control" name="tituloPtCarrusel" placeholder="Titulo Portuges" type="text" value={this.state.tituloPtCarrusel} onChange={this.manejadorCambios}/>
									<span>Ingresar un titulo en español e ingles de manera obligatoria</span>
								</div>
								<div className="form-group">
									<label>Maximo de Pathbooks</label>
									<input className="form-control" name="cantidadCarusel" placeholder="Cantidad (numero)" type="number" value={this.state.cantidadCarusel} onChange={this.manejadorCambios}/>
									<span>Ingresar un numero que indique la cantidad maxima de pathbooks en este carrusel</span>
								</div>
								<div className="form-group">
									<label>Prioridad Numero</label>0 <input className="form-control" name="prioridadCarrusel" placeholder="Prioridad (numero)" type="number" value={this.state.prioridadCarrusel} onChange={this.manejadorCambios}/>
									<span>Ingresar un numero que indi0e que tanta prioridad tendra el feature en el carrucel</span>
								</div>
								<div className="form-group">
									<label>Seleeciona un Tipo</label>
									<select className="form-control" name="carrucelTipo" value={this.state.carrucelTipo} onChange={this.manejadorCambios}>
										<option value="Null">Seleciona uno</option>
										<option value="Autor">Autor</option>
										<option value="Genero">Genero</option>
										<option value="Manual">Manual</option>
										<option value="Pathbook">Pathbook</option>
										<option value="Destacado">Destacado</option>
									</select>
								</div>
								{
									this.state.carrucelTipo === "Null"
										? (<div className="form-group">
											<label>Para continuar seleciona un tipo</label>
											<br/>
											<span>Gracias</span>
										</div>)
										: null
								}
								{
									this.state.carrucelTipo === "Autor"
										? (<div>
											<h6>Autor</h6>
											<input className="form-control" list="selectAutor" id="autorSelected" name="autorCarrusel" type="select" placeholder="Elige un Autor" value={this.state.autorCarrusel} onChange={this.manejadorCambios}/>
											<datalist id="selectAutor">
												{this.state.selectAutores.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
											</datalist>
											<div></div>
											<div className="card-footer">
												<button onClick={() => this.validaFormCarrusel("Autor")} type="button" id="buttonCarrusel" className="btn btn-primary btn-lg btn-block">Agregar Carrusel</button>
											</div>
										</div>)
										: null
								}
								{
									this.state.carrucelTipo === "Genero"
										? (<div>
											<h6>Genero</h6>
											<input className="form-control" list="selectGenero" id="generoSelected" name="generoCarrusel" type="select" placeholder="Elige un Genero" value={this.state.generoCarrusel} onChange={this.manejadorCambios}/>
											<datalist id="selectGenero">
												{this.state.selectGenero.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
											</datalist>
											<div className="card-footer">
												<button onClick={() => this.validaFormCarrusel("Genero")} type="button" id="buttonCarrusel" className="btn btn-primary btn-lg btn-block">Agregar Carrusel</button>
											</div>
										</div>)
										: null
								}
								{
									this.state.carrucelTipo === "Manual"
										? (<div>
											<div>
												<label>Selecciona un tamaño</label>
												<h6>Que tamaño de portada deseas que tenga tu carrusel</h6>
												<div>
													<div className="container">
														<div className="row">
															<div className="col-sm">
																<img src={carrManual} height="130" alt="manual"/>
																<div className="form-check">
																	<input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs1()}/>
																	<label className="form-check-label">Normal</label>
																</div>
															</div>
															<div className="col-sm">
																<img src={carrManualGrande} height="130" alt="manual"/>
																<div className="form-check">
																	<input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs2()}/>
																	<label className="form-check-label">Grande</label>
																</div>
															</div>
															<div className="col-sm">
																<img src={carrManualMini} height="130" alt="manual"/>
																<div className="form-check">
																	<input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs3()}/>
																	<label className="form-check-label">Mini (Feature)</label>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<br/>
											<div>
												<label>Crea una lista</label>
												<h6>Ingresa los pathbooks para crear la lista que usaremos</h6>
												<h6>Llevas {this.state.selectPathbooksNum} Pathbooks</h6>
												<input className="form-control" list="selectPathbook" id="patbookSelected" name="pathbookCarrusel" type="select" placeholder="Elige un Pathbook" value={this.state.pathbookCarrusel} onChange={this.manejadorCambios}/>
												<datalist id="selectPathbook">
													{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
													{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombreEn + " -   --> " + task.id}>{task.nombreEn}</option>)}
												</datalist>
												<div className="card-footer">
													<button onClick={() => this.agregarAListaPathbooks()} type="button" id="buttonCarrusel" className="btn btn-block btn-primary btn-lg btn-success">Agrega a la lista</button>
													<button onClick={() => this.limpiarAListaPathbooks()} type="button" id="buttonCarrusel" className="btn btn-block btn-primary btn-lg btn-danger btn-sm">Limpiar a la lista</button>
												</div>
												<div>
													<h6>Lista de pathbooks</h6>
													<ul className="list-group">
														<div className="row">
															<div className="col-sm">
																<label>Nombre</label>
															</div>
															<div className="col-sm">
																<label>Portada Es</label>
															</div>
															<div className="col-sm">
																<label>Portada En</label>
															</div>
															<div className="col-sm">
																<label>Feature</label>
															</div>
														</div>
														{this.state.listaPathbooks.map(data => (
															<li className="list-group-item" key={data.id}>
																<ListaVisible data={data}/>
															</li>
														))}
													</ul>
												</div>
											</div>
											<div className="card-footer">
												<button onClick={() => this.validaFormCarrusel("Manual")} type="button" id="buttonCarrusel" className="btn btn-primary btn-lg btn-block">Agregar Carrusel</button>
											</div>
										</div>)
										: null
								}
								{
									this.state.carrucelTipo === "Pathbook"
										? (<div>
											<label>Escoje un Pathbook</label>
											<h6>Esta es la referencia del pathbook que funcionara en este carrusel</h6>
											<input className="form-control" list="selectPathbook" id="patbookSelected" name="pathbookCarrusel" type="select" placeholder="Elige un Pathbook" value={this.state.pathbookCarrusel} onChange={this.manejadorCambios}/>
											<datalist id="selectPathbook">
												{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
												{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombreEn + " -   --> " + task.id}>{task.nombreEn}</option>)}
											</datalist>
											<label>Carga una a una las imagenes del carrusel</label>
											<h6>Ingresa minimo 5 y maximo 10 imagenes</h6>
											<h6>Tienes {this.state.contadorImagenes} imagenes</h6>
											<div>
												<progress value={this.state.valorBarraLoad} max={this.state.valorBarraLoadEnd}/>
												<br/>
												<input className="form-control" type="file" onChange={this.handleOnChange.bind(this)}/>
												<br/>
												{this.state.message}
												<br/>
												<div className="container">
													<div className="row">
														{this.state.carruselImagenes.map(taskImage =>
															<div key={taskImage} className="col-sm">
																<img src={taskImage} height="130" alt="manual"/>
																<br/>
																{/*<a href={taskImage} target="_blank">Abrir</a>*/}
															</div>)
														}
													</div>
												</div>


												{/*<img src={this.state.imagenC01} height="130" alt="manual"/>*/}
											</div>

											<div className="card-footer">
												<button onClick={() => this.validaFormCarrusel("Pathbook")} type="button" id="buttonCarrusel" className="btn btn-primary btn-lg btn-block">Agregar Carrusel</button>
											</div>

										</div>)
										: null
								}
								{
									this.state.carrucelTipo === "Destacado"
										? (<div>
											<label>Escoje un Pathbook</label>
											<h6>Esta es la referencia del pathbook que funcionara en este carrusel</h6>
											<input className="form-control" list="selectPathbook" id="patbookSelected" name="pathbookCarrusel" type="select" placeholder="Elige un Pathbook" value={this.state.pathbookCarrusel} onChange={this.manejadorCambios}/>
											<datalist id="selectPathbook">
												{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
												{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombreEn + " -   --> " + task.id}>{task.nombreEn}</option>)}
											</datalist>
											<br/>


											<div className="card-footer">
												<button onClick={() => this.validaFormCarrusel("Destacado")} type="button" id="buttonCarrusel" className="btn btn-primary btn-lg btn-block">Agregar Carrusel</button>
											</div>

										</div>)
										: null
								}
								{/* <button onClick={() => this.guardarFeature()} type="button" id="buttonCarrusel" className="btn btn-primary btn-lg btn-block">Nuevo Feature</button> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="content-header d-flex">
				<div className="container-fluid">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Carruseles Pathbooks</h3>
						</div>

						<div>
							{this.state.carruselListPathbooks.map(data => (
								<div className="container" key={data.idCarr}>
									{/*<ListaVisible data={data}/>*/}
									<FichaCarrusel data={data} pathbooksList={this.state.selectPathbooks}/>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Carruseles Kids</h3>
						</div>
						<div>
							{this.state.carruselListKids.map(data => (
								<li className="list-group-item" key={data.idCarr}>
									{/*<ListaVisible data={data}/>*/}
									<FichaCarrusel data={data}  pathbooksList={this.state.selectPathbooks}/>
								</li>
							))}
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Carruseles Estudiantes</h3>
						</div>
						<div>
							{this.state.carruselListStudents.map(data => (
								<li className="list-group-item" key={data.idCarr}>
									{/*<ListaVisible data={data}/>*/}
									<FichaCarrusel data={data}  pathbooksList={this.state.selectPathbooks}/>
								</li>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>);
	}
}

export default AppCarrusel;

import React, {Component} from "react";

class PathbooksStatistics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pathbooksLista: props.listaPathbooks,
			patbooksTotales: 0,
			patbooksActivos: 0,
			patbooksInactivos: 0,
			pathbookGratis: 0,
			pathbookClassificationA: 0,
			pathbookClassificationAA: 0,
			pathbookClassificationB: 0,
			pathbookClassificationB15: 0,
			pathbookClassificationC: 0,
		};
	}

	componentDidMount() {

		let patbooksT = 0;
		let patbooksA = 0;
		let patbooksI = 0;
		let pathbookG = 0;
		let classificationA = 0;
		let classificationAA = 0;
		let classificationB = 0;
		let classificationB15 = 0;
		let classificationC = 0;

		this.state.pathbooksLista.forEach((task) => {
			patbooksT = patbooksT + 1;
			if (task.doc.data().active === true) {
				patbooksA = patbooksA + 1;
			}
			if (task.doc.data().active === false) {
				patbooksI = patbooksI + 1;
			}
			if (task.doc.data().free === true) {
				pathbookG = pathbookG + 1;
			}
			if (task.doc.data().classification === "A") {
				classificationA = classificationA + 1;
			}
			if (task.doc.data().classification === "AA") {
				classificationAA = classificationAA + 1;
			}
			if (task.doc.data().classification === "B") {
				classificationB = classificationB + 1;
			}
			if (task.doc.data().classification === "B15") {
				classificationB15 = classificationB15 + 1;
			}
			if (task.doc.data().classification === "C") {
				classificationC = classificationC + 1;
			}
		});
		this.setState({
			patbooksTotales: patbooksT,
			patbooksActivos: patbooksA,
			patbooksInactivos: patbooksI,
			pathbookGratis: pathbookG,
			pathbookClassificationA: classificationA,
			pathbookClassificationAA: classificationAA,
			pathbookClassificationB: classificationB,
			pathbookClassificationB15: classificationB15,
			pathbookClassificationC: classificationC,
		});
	}


	render() {
		return (
			<div className="content-wrapper">
				<div className="content-header">
					<h3 className="mt-4 mb-4">Estadisticas Generales</h3>
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 col-sm-6 col-md-3">
								<div className="info-box mb-3">
									<span className="info-box-icon bg-success elevation-1"><i className="fas fa-project-diagram"/></span>
									<div className="info-box-content">
										<span className="info-box-text">Pathbooks:</span>
										<span className="info-box-number">{this.state.patbooksTotales}</span>
									</div>
								</div>
							</div>

							<div className="col-12 col-sm-6 col-md-3">
								<div className="info-box mb-3">
									<span className="info-box-icon bg-success elevation-1"><i className="fas fa-thumbs-up"/></span>
									<div className="info-box-content">
										<span className="info-box-text">Activos:</span>
										<span className="info-box-number">{this.state.patbooksActivos}</span>
									</div>
								</div>
							</div>

							<div className="col-12 col-sm-6 col-md-3">
								<div className="info-box mb-3">
									<span className="info-box-icon bg-danger elevation-1"><i className="fas fa-cog"/></span>
									<div className="info-box-content">
										<span className="info-box-text">Inactivos:</span>
										<span className="info-box-number">{this.state.patbooksInactivos}</span>
									</div>
								</div>
							</div>

							<div className="col-12 col-sm-6 col-md-3">
								<div className="info-box mb-3">
									<span className="info-box-icon bg-warning elevation-1"><i className="fas fa-users"/></span>
									<div className="info-box-content">
										<span className="info-box-text">Gratis:</span>
										<span className="info-box-number">{this.state.pathbookGratis}</span>
									</div>
								</div>
							</div>
						</div>
						<h3 className="mt-4 mb-4">Estadisticas Clasificación</h3>
						<div className="container-fluid">
							<div className="row">
								<div className="col-12 col-sm-6 col-md-2">
									<div className="info-box mb-3">
										<span className="info-box-icon bg-success elevation-1">A</span>
										<div className="info-box-content">
											<span className="info-box-text">Cantidad:</span>
											<h3 className="info-box-text"> {this.state.pathbookClassificationA}</h3>
										</div>
									</div>
								</div>

								<div className="col-12 col-sm-6 col-md-2">
									<div className="info-box mb-3">
										<span className="info-box-icon bg-success elevation-1">AA</span>
										<div className="info-box-content">
											<span className="info-box-text">Cantidad:</span>
											<h3 className="info-box-text"> {this.state.pathbookClassificationAA}</h3>
										</div>
									</div>
								</div>

								<div className="col-12 col-sm-6 col-md-2">
									<div className="info-box mb-3">
										<span className="info-box-icon bg-success elevation-1">B</span>
										<div className="info-box-content">
											<span className="info-box-text">Cantidad:</span>
											<h3 className="info-box-text"> {this.state.pathbookClassificationB}</h3>
										</div>
									</div>
								</div>

								<div className="col-12 col-sm-6 col-md-2">
									<div className="info-box mb-3">
										<span className="info-box-icon bg-success elevation-1">B15</span>
										<div className="info-box-content">
											<span className="info-box-text">Cantidad:</span>
											<h3 className="info-box-text"> {this.state.pathbookClassificationB15}</h3>
										</div>
									</div>
								</div>

								<div className="col-12 col-sm-6 col-md-2">
									<div className="info-box mb-3">
										<span className="info-box-icon bg-success elevation-1">C</span>
										<div className="info-box-content">
											<span className="info-box-text">Cantidad:</span>
											<h3 className="info-box-text"> {this.state.pathbookClassificationC}</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PathbooksStatistics;

import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import swal from "sweetalert2";
import Moment from "moment";

///// Componente para armar la pantlla
class FormNewPathbook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editar: false,
			pathbooksAModificar: "",
			pathbooksLista: props.dataGeneral.listaPathbooks,
			autoresLista: props.dataGeneral.listaAutores,
			generoLista: props.dataGeneral.listaGenero,

			/////pathbook lista
			languagesPathbook: null,
			languagesPathbookEN: false,
			languagesPathbookES: false,
			languagesPathbookDE: false,
			languagesPathbookFR: false,
			languagesPathbookIT: false,
			languagesPathbookPT: false,
			languagesIAPathbook: null,
			languagesIAPathbookEN: false,
			languagesIAPathbookES: false,
			languagesIAPathbookDE: false,
			languagesIAPathbookFR: false,
			languagesIAPathbookIT: false,
			languagesIAPathbookPT: false,
			tituloEnPathbook: "",
			tituloEsPathbook: "",
			tituloDePathbook: "",
			tituloFrPathbook: "",
			tituloItPathbook: "",
			tituloPtPathbook: "",
			subTituloEnPathbook: "",
			subTituloEsPathbook: "",
			clasificacion: "selecciona",
			autorCarrusel: "",
			autorCarruselChk: "",
			generoCarrusel01: "",
			generoCarrusel02: "",
			generoCarrusel03: "",
			tiempoLectPathbook: 0,
			sumarioInCarrusel: "",
			sumarioEsCarrusel: "",
			sumarioDeCarrusel: "",
			sumarioFrCarrusel: "",
			sumarioPtCarrusel: "",
			sumarioItCarrusel: "",
			buscadorEnPathbookSave: [],
			buscadorEsPathbookSave: [],
			buscadorDePathbookSave: [],
			buscadorFrPathbookSave: [],
			buscadorPtPathbookSave: [],
			buscadorItPathbookSave: [],
			buscadorEnPathbook: [],
			buscadorEsPathbook: [],
			buscadorDePathbook: [],
			buscadorFrPathbook: [],
			buscadorPtPathbook: [],
			buscadorItPathbook: [],
			tipoPathbook: "",
			gratisPathbook: false,

			potenciaPathbook: 0,
			generosPathbookslSave: [],
			bookTrailerEnPathbook: "",
			bookTrailerEsPathbook: "",

			pathbookSeleccionado: "",
			pathbookSelecDatos: [],
			///areglos para selecionar
			selectPathbooks: [],
			selectAutores: [],
			selectGenero: [],

			audioPathbook: false,
			languagesAudioPathbook: null,
			languagesAudioPathbookEN: false,
			languagesAudioPathbookES: false,
			languagesAudioPathbookDE: false,
			languagesAudioPathbookFR: false,
			languagesAudioPathbookIT: false,
			languagesAudioPathbookPT: false,
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
		this.manejadorImputs = this.manejadorImputs.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	////manejamos el imput change para multiples chekbooks
	manejadorImputs(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name =  target.name;
		this.setState({
			[name]: value,
		});
	}

	validaPathbooksExiste() {
		if (!this.state.pathbookSeleccionado) {
			swal.fire({title: "Seleciona un pathbook existente", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let generoID = this.state.pathbookSeleccionado.split(" -   --> ");
			firestore.doc("pathbooks/" + generoID[1]).get().then((documentSnapshot) => {
				let datapathbook = documentSnapshot;
				if(datapathbook.exists === true){
					this.setState({pathbooksAModificar: generoID[1]});
					this.llenaDatosSelecionados(datapathbook.data());
				}
				else{
					this.limpiarForm();
					swal.fire({title: "Ese pathbook no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
			})
		}
	}

	async llenaDatosSelecionados(datos) {

		let thisAdd = this

		thisAdd.setState({
			languagesPathbookEN: false,
			languagesPathbookES: false,
			languagesPathbookDE: false,
			languagesPathbookFR: false,
			languagesPathbookIT: false,
			languagesPathbookPT: false,
			languagesAudioPathbookEN: false,
			languagesAudioPathbookES: false,
			languagesAudioPathbookDE: false,
			languagesAudioPathbookFR: false,
			languagesAudioPathbookIT: false,
			languagesAudioPathbookPT: false,
			languagesIAPathbookEN: false,
			languagesIAPathbookES: false,
			languagesIAPathbookDE: false,
			languagesIAPathbookFR: false,
			languagesIAPathbookIT: false,
			languagesIAPathbookPT: false,
		})

		await this.setState({
			editar: true
		});

		let lenguajes = [];
		lenguajes = datos.languages;
		lenguajes.forEach(function (doc) {
			if(doc === "en-US"){
				thisAdd.setState({languagesPathbookEN: true})
			}
			if(doc === "es-MX"){
				thisAdd.setState({languagesPathbookES: true})
			}
			if(doc === "en"){
				thisAdd.setState({languagesPathbookEN: true})
			}
			if(doc === "es"){
				thisAdd.setState({languagesPathbookES: true})
			}
			if(doc === "de"){
				thisAdd.setState({languagesPathbookDE: true})
			}
			if(doc === "fr"){
				thisAdd.setState({languagesPathbookFR: true})
			}
			if(doc === "it"){
				thisAdd.setState({languagesPathbookIT: true})
			}
			if(doc === "pt"){
				thisAdd.setState({languagesPathbookPT: true})
			}
		});


		let lenguajesIA = [];
		lenguajesIA = datos.translations;
		if(datos.translations){
			lenguajesIA.forEach(function (doc) {
				if(doc === "en-US"){
					thisAdd.setState({languagesIAPathbookEN: true})
				}
				if(doc === "es-MX"){
					thisAdd.setState({languagesIAPathbookES: true})
				}
				if(doc === "en"){
					thisAdd.setState({languagesIAPathbookEN: true})
				}
				if(doc === "es"){
					thisAdd.setState({languagesIAPathbookES: true})
				}
				if(doc === "de"){
					thisAdd.setState({languagesIAPathbookDE: true})
				}
				if(doc === "fr"){
					thisAdd.setState({languagesIAPathbookFR: true})
				}
				if(doc === "it"){
					thisAdd.setState({languagesIAPathbookIT: true})
				}
				if(doc === "pt"){
					thisAdd.setState({languagesIAPathbookPT: true})
				}
			});
		}

		let audioLanguages = [];


		if(datos.audioLanguages){
			audioLanguages = datos.audioLanguages;
			if(audioLanguages){
				audioLanguages.forEach(function (doc) {
					if(doc === "en-US"){
						thisAdd.setState({languagesAudioPathbookEN: true})
					}
					if(doc === "es-MX"){
						thisAdd.setState({languagesAudioPathbookES: true})
					}
					if(doc === "en"){
						thisAdd.setState({languagesAudioPathbookEN: true})
					}
					if(doc === "es"){
						thisAdd.setState({languagesAudioPathbookES: true})
					}
					if(doc === "de"){
						thisAdd.setState({languagesAudioPathbookDE: true})
					}
					if(doc === "fr"){
						thisAdd.setState({languagesAudioPathbookFR: true})
					}
					if(doc === "it"){
						thisAdd.setState({languagesAudioPathbookIT: true})
					}
					if(doc === "pt"){
						thisAdd.setState({languagesAudioPathbookPT: true})
					}
				});
			}
		}


		if(datos.audiobook){
			console.log("ds")
			this.setState({audioPathbook: datos.audiobook})
		}


		///autor
		let cadenaautor = "";
		await this.state.autoresLista.forEach((task) => {
			if (task.doc.id === datos.author.id) {
				cadenaautor = task.doc.data().name + " -   --> " + datos.author.id
			}
		});

		///genero
		let cadenaGenero1 = "";
		let cadenaGenero2 = "";
		let cadenaGenero3 = "";
		await this.state.generoLista.forEach((task) => {
			if (task.doc.id === datos.genres[0].id) {
				cadenaGenero1 = task.doc.data()["es-MX"]  + " -   --> " + task.doc.id
			}
			if (datos.genres[1]){
				if (task.doc.id === datos.genres[1].id) {
					cadenaGenero2 = task.doc.data()["es-MX"]  + " -   --> " + task.doc.id
				}
			}
			if (datos.genres[2]){
				if (task.doc.id === datos.genres[2].id) {
					cadenaGenero3 = task.doc.data()["es-MX"]  + " -   --> " + task.doc.id
				}
			}

		});

		///buscador
		let buscadorEN = "";
		if(datos.tags["en"]) {
			if (datos.tags["en"].length === 1) {
				await datos.tags["en"].forEach((task) => {
					buscadorEN = task
				});
			} else {
				await datos.tags["en"].forEach((task) => {
					if (buscadorEN === "") {
						buscadorEN = task
					} else {
						buscadorEN = buscadorEN + ", " + task
					}
				});
			}
		}
		let buscadorES = "";
		if(datos.tags["es"]) {
			if (datos.tags["es"].length === 1) {
				await datos.tags["es"].forEach((task) => {
					buscadorES = task
				});
			} else {
				await datos.tags["es"].forEach((task) => {
					if (buscadorES === "") {
						buscadorES = task
					} else {
						buscadorES = buscadorES + ", " + task
					}
				});
			}
		}
		let buscadorDE = "";
		if(datos.tags["de"]) {
			if (datos.tags["de"].length === 1) {
				await datos.tags["de"].forEach((task) => {
					buscadorDE = task
				});
			} else {
				await datos.tags["de"].forEach((task) => {
					if (buscadorDE === "") {
						buscadorDE = task
					} else {
						buscadorDE = buscadorDE + ", " + task
					}
				});
			}
		}
		let buscadorFR = "";
		if(datos.tags["fr"]) {
			if (datos.tags["fr"].length === 1) {
				await datos.tags["fr"].forEach((task) => {
					buscadorFR = task
				});
			} else {
				await datos.tags["fr"].forEach((task) => {
					if (buscadorFR === "") {
						buscadorFR = task
					} else {
						buscadorFR = buscadorFR + ", " + task
					}
				});
			}
		}
		let buscadorPT = "";
		if(datos.tags["pt"]) {
			if (datos.tags["pt"].length === 1) {
				await datos.tags["pt"].forEach((task) => {
					buscadorPT = task
				});
			} else {
				await datos.tags["pt"].forEach((task) => {
					if (buscadorPT === "") {
						buscadorPT = task
					} else {
						buscadorPT = buscadorPT + ", " + task
					}
				});
			}
		}
		let buscadorIT = "";
		if(datos.tags["pt"]) {
			if (datos.tags["it"].length === 1) {
				await datos.tags["it"].forEach((task) => {
					buscadorIT = task
				});
			} else {
				await datos.tags["it"].forEach((task) => {
					if (buscadorIT === "") {
						buscadorIT = task
					} else {
						buscadorIT = buscadorIT + ", " + task
					}
				});
			}
		}
		let subtituloEN = ""
		let subtituloES = ""

		if (datos.subtitle){
			subtituloEN = datos.subtitle["en"]
			subtituloES = datos.subtitle["es"]
		}
		let tituloEN = ""
		let tituloES = ""
		if (datos.title["en"]){
			tituloEN = datos.title["en"]
		}
		if (datos.title["es"]){
			tituloES = datos.title["es"]
		}
		let readingTime = ""
		if (datos.readingTime){
			readingTime = datos.readingTime
		}

		let tipoPathbook = ""
		if (datos.type){
			tipoPathbook = datos.type
		}
		let gratisPathbook = ""
		if (datos.free){
			gratisPathbook = datos.free
		}
		let potenciaPathbook = ""
		if (datos.grade){
			potenciaPathbook = datos.grade
		}

		this.setState({
			tituloEnPathbook: tituloEN,
			tituloEsPathbook: tituloES,
			subTituloEnPathbook: subtituloEN,
			subTituloEsPathbook: subtituloES,
			autorCarrusel: cadenaautor,
			generoCarrusel01: cadenaGenero1,
			generoCarrusel02: cadenaGenero2,
			generoCarrusel03: cadenaGenero3,
			tiempoLectPathbook: readingTime,
			sumarioInCarrusel: datos.summary["en"],
			sumarioEsCarrusel: datos.summary["es"],
			sumarioDeCarrusel: datos.summary["de"],
			sumarioFrCarrusel: datos.summary["fr"],
			sumarioPtCarrusel: datos.summary["it"],
			sumarioItCarrusel: datos.summary["pt"],
			buscadorEnPathbook: buscadorEN,
			buscadorEsPathbook: buscadorES,
			buscadorDePathbook: buscadorDE,
			buscadorFrPathbook: buscadorFR,
			buscadorPtPathbook: buscadorPT,
			buscadorItPathbook: buscadorIT,
			tipoPathbook: tipoPathbook,
			gratisPathbook: gratisPathbook,
			potenciaPathbook: potenciaPathbook,
		});

		if(datos.classification){
			this.setState({clasificacion: datos.classification})
		}else {
			this.setState({clasificacion: "selecciona"})
		}

		if(datos.video){
			if(datos.video["en-US"]){
				this.setState({bookTrailerEnPathbook: datos.video["en-US"]})
			}else if(datos.video["en"]){
				this.setState({bookTrailerEnPathbook: datos.video["en"]})
			}

			if(datos.video["es-MX"]){
				this.setState({bookTrailerEsPathbook: datos.video["es-MX"]})
			}else if(datos.video["es"]){
				this.setState({bookTrailerEsPathbook: datos.video["es"]})
			}
		}


		if(datos.title["de"]){
			this.setState({tituloDePathbook: datos.title["de"]})
		}
		if(datos.title["fr"]){
			this.setState({tituloFrPathbook: datos.title["fr"]})
		}
		if(datos.title["it"]){
			this.setState({tituloItPathbook: datos.title["it"]})
		}
		if(datos.title["pt"]){
			this.setState({tituloPtPathbook: datos.title["pt"]})
		}

	}

	validaBuscadorPathbook() {
		if (this.state.buscadorEnPathbook.length === 0) {
			swal.fire({title: "Agrega información en el buscador ", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.buscadorEsPathbook.length === 0) {
			swal.fire({title: "Agrega información en el buscador ", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.buscadorDePathbook.length === 0) {
			swal.fire({title: "Agrega información en el buscador ", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.buscadorFrPathbook.length === 0) {
			swal.fire({title: "Agrega información en el buscador ", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.buscadorPtPathbook.length === 0) {
			swal.fire({title: "Agrega información en el buscador ", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.buscadorItPathbook.length === 0) {
			swal.fire({title: "Agrega información en el buscador ", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let buscadorEN = this.state.buscadorEnPathbook.split(", ");
			let buscadorES = this.state.buscadorEsPathbook.split(", ");
			let buscadorDE = this.state.buscadorDePathbook.split(", ");
			let buscadorFR = this.state.buscadorFrPathbook.split(", ");
			let buscadorPT = this.state.buscadorPtPathbook.split(", ");
			let buscadorIT = this.state.buscadorItPathbook.split(", ");
			this.setState({
				buscadorEnPathbookSave: buscadorEN,
				buscadorEsPathbookSave: buscadorES,
				buscadorDePathbookSave: buscadorDE,
				buscadorFrPathbookSave: buscadorFR,
				buscadorPtPathbookSave: buscadorPT,
				buscadorItPathbookSave: buscadorIT,
			});
			this.validaClasificacion();
		}
	}

	validaClasificacion(){
		if (this.clasificacion==="selecciona"){
			swal.fire({title: "Agrega información en la clasificación", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			this.validaGenerosIguales();
		}
	};


	validaGenerosIguales() {
		if (this.state.generoCarrusel01 === this.state.generoCarrusel02) {
			swal.fire({title: "Los géneros no pueden ser iguales", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.generoCarrusel02 === this.state.generoCarrusel03) {
			swal.fire({title: "Los géneros no pueden ser iguales", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.generoCarrusel01 === this.state.generoCarrusel03) {
			swal.fire({title: "Los géneros no pueden ser iguales", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			this.validaGeneros1();
		}
	}

	validaGeneros1() {
		let genero1 = "";
		if (!this.state.generoCarrusel01) {
			swal.fire({title: "Agrega primer genero", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let generoID = this.state.generoCarrusel01.split(" -   --> ");
			//////evaluamos que el genero exista
			firestore.doc("genres/" + generoID[1]).get().then((documentSnapshot) => {
				if (documentSnapshot.data().active) {
					genero1 = generoID[1];
					this.validaGeneros2(genero1);
				} else {
					this.setState({generoCarrusel01: ""});
					swal.fire({title: "Ese genero no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
			})
		}
	}

	validaGeneros2(genero1) {
		let genero2 = "";
		if (!this.state.generoCarrusel02) {
			swal.fire({title: "Agrega un segundo genero", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let generoID = this.state.generoCarrusel02.split(" -   --> ");
			//////evaluamos que el genero exista
			firestore.doc("genres/" + generoID[1]).get().then((documentSnapshot) => {
				if (documentSnapshot.data().active) {
					genero2 = generoID[1];
					this.validaGeneros3(genero1, genero2);
				}else{
					this.setState({generoCarrusel02: ""});
					swal.fire({title: "El segundo genero no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
			})
		}
	}

	async validaGeneros3(genero1, genero2) {
		var genero1Clave = genero1;
		var genero2Clave = genero2;
		if (!this.state.generoCarrusel03) {
			await this.setState({generosPathbookslSave: [firestore.doc("genres/" + genero1Clave), firestore.doc("genres/" + genero2Clave)]});
			this.validaAuthor();
		} else {
			let generoID = this.state.generoCarrusel03.split(" -   --> ");
			//////evaluamos que el genero exista
			await firestore.doc("genres/" + generoID[1]).get().then((documentSnapshot) => {
				let datagenero = documentSnapshot;
				if (datagenero.data().active) {
					this.setState({generoCarrusel03Chk: "genres/" + generoID[1]});
				}
			}).then(() => {
				this.setState({generosPathbookslSave: [firestore.doc("genres/" + genero1Clave), firestore.doc("genres/" + genero2Clave), firestore.doc("genres/" + generoID[1])]});
				this.validaAuthor();
			}).catch(error => {
				swal.fire({title: "El tercer genero no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
			});
		}
	}

	 validaAuthor() {
		if (!this.state.autorCarrusel) {
			swal.fire({title: "Agrega un Autor", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let authorID = this.state.autorCarrusel.split(" -   --> ");
			//////evaluamos que el autor exista
			 firestore.doc("authors/" + authorID[1]).get().then((documentSnapshot) => {
				let dataAuthor = documentSnapshot;
				 if(dataAuthor.exists === true){
					 this.setState({autorCarruselChk: "authors/" + dataAuthor.id});
					 this.validaLenguajes();
				 } else {
					 swal.fire({title: "Ese autor no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				 }
			})
		}
	}
	validaLenguajes() {
		let aregloDir = [];

		if(this.state.languagesPathbookEN === true){
			aregloDir.push("en-US");
		}
		if(this.state.languagesPathbookES === true){
			aregloDir.push("es-MX");
		}
		if(this.state.languagesPathbookEN === true){
			aregloDir.push("en");
		}
		if(this.state.languagesPathbookES === true){
			aregloDir.push("es");
		}
		if(this.state.languagesPathbookDE === true){
			aregloDir.push("de");
		}
		if(this.state.languagesPathbookFR === true){
			aregloDir.push("fr");
		}
		if(this.state.languagesPathbookIT === true){
			aregloDir.push("it");
		}
		if(this.state.languagesPathbookPT === true){
			aregloDir.push("pt");
		}

		if (aregloDir === []) {
			swal.fire({title: "Agrega un idioma minimo", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {

			let aregloIADir = [];

			if(this.state.languagesIAPathbookEN === true){
				aregloIADir.push("en-US");
			}
			if(this.state.languagesIAPathbookES === true){
				aregloIADir.push("es-MX");
			}
			if(this.state.languagesIAPathbookEN === true){
				aregloIADir.push("en");
			}
			if(this.state.languagesIAPathbookES === true){
				aregloIADir.push("es");
			}
			if(this.state.languagesIAPathbookDE === true){
				aregloIADir.push("de");
			}
			if(this.state.languagesIAPathbookFR === true){
				aregloIADir.push("fr");
			}
			if(this.state.languagesIAPathbookIT === true){
				aregloIADir.push("it");
			}
			if(this.state.languagesIAPathbookPT === true){
				aregloIADir.push("pt");
			}

			let aregloAudioDir = [];

			if(this.state.languagesAudioPathbookEN === true){
				aregloAudioDir.push("en-US");
			}
			if(this.state.languagesAudioPathbookES === true){
				aregloAudioDir.push("es-MX");
			}
			if(this.state.languagesAudioPathbookEN === true){
				aregloAudioDir.push("en");
			}
			if(this.state.languagesAudioPathbookES === true){
				aregloAudioDir.push("es");
			}
			if(this.state.languagesAudioPathbookDE === true){
				aregloAudioDir.push("de");
			}
			if(this.state.languagesAudioPathbookFR === true){
				aregloAudioDir.push("fr");
			}
			if(this.state.languagesAudioPathbookIT === true){
				aregloAudioDir.push("it");
			}
			if(this.state.languagesAudioPathbookPT === true){
				aregloAudioDir.push("pt");
			}

			this.setState({languagesIAPathbook: aregloIADir});
			this.setState({languagesAudioPathbook: aregloAudioDir});
			this.setState({languagesPathbook: aregloDir});
			this.validaFormPathbook();
		}
	}

	validaFormPathbook() {
		let lenguageCadena = this.state.languagesPathbook;
		if (!lenguageCadena) {
			swal.fire({title: "Seecciona un idioma Minimo", text: "es minimo un lenguage para el pathbook", type: "error", confirmButtonText: "Entendido"});
		} else if (lenguageCadena.length === 0) {
			swal.fire({title: "Seecciona un idioma Minimo", text: "es minimo un lenguage para el pathbook", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloEnPathbook) {
			swal.fire({title: "Agrega un titulo en Ingles", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloEsPathbook) {
			swal.fire({title: "Agrega un titulo en Español", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloDePathbook) {
			swal.fire({title: "Agrega un titulo en Aleman", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloFrPathbook) {
			swal.fire({title: "Agrega un titulo en Frances", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloItPathbook) {
			swal.fire({title: "Agrega un titulo en Italiano", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.tituloPtPathbook) {
			swal.fire({title: "Agrega un titulo en Portuges", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.tipoPathbook === "selecciona") {
			swal.fire({title: "Selecciona un tipo de pathbook", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		}   else if (!this.state.tipoPathbook) {
			swal.fire({title: "Selecciona un tipo de pathbook", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.potenciaPathbook === "0") {
			swal.fire({title: "Potencia el valor de tu pathbook", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.potenciaPathbook) {
			swal.fire({title: "Potencia el valor de tu pathbook", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.tiempoLectPathbook < 1) {
			swal.fire({title: "El tiempo de lectura no puede ser menor a 1 minuto", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			this.guardaDatos();
		}
	}
	guardaDatos() {
		if (this.state.editar === false){
			////obtenemos los datos del formulario
			firestore.collection("pathbooks").doc().set({
				publisher: "Pathbooks",
				active: false,
				visible: false,
				languages: this.state.languagesPathbook,
				translations: this.state.languagesIAPathbook,
				classification: this.state.clasificacion,
				title: {
					"en": this.state.tituloEnPathbook,
					"es": this.state.tituloEsPathbook,
					"en-US": this.state.tituloEnPathbook,
					"es-MX": this.state.tituloEsPathbook,
			    	"de": this.state.tituloDePathbook,
		    		"fr": this.state.tituloFrPathbook,
	 			    "it": this.state.tituloItPathbook,
				    "pt": this.state.tituloPtPathbook
				},
				subtitle: {
					"en": this.state.subTituloEnPathbook,
					"es": this.state.subTituloEsPathbook,
					"en-US": this.state.subTituloEnPathbook,
					"es-MX": this.state.subTituloEsPathbook,
				},
				author: firestore.doc(this.state.autorCarruselChk),
				genres: this.state.generosPathbookslSave,
				summary: {
					"en": this.state.sumarioInCarrusel,
					"es": this.state.sumarioEsCarrusel,
					"en-US": this.state.sumarioInCarrusel,
					"es-MX": this.state.sumarioEsCarrusel,
					"de": this.state.sumarioDeCarrusel,
		    		"fr": this.state.sumarioFrCarrusel,
		 			"it": this.state.sumarioItCarrusel,
				    "pt": this.state.sumarioPtCarrusel,
				},
				tags: {
					"en": this.state.buscadorEnPathbookSave,
					"es": this.state.buscadorEsPathbookSave,
					"en-US": this.state.buscadorEnPathbookSave,
					"es-MX": this.state.buscadorEsPathbookSave,
					"de": this.state.buscadorDePathbookSave,
		    		"fr": this.state.buscadorFrPathbookSave,
		 			"it": this.state.buscadorItPathbookSave,
				    "pt": this.state.buscadorPtPathbookSave,
				},
				modified: null,
				readingTime: parseInt(this.state.tiempoLectPathbook),
				added: {
					timestamp: Moment().toDate(),
					user: firestore.doc("users/CMk4wSMR47XFHKZ1zY2yerH9Wp42"),
				},
				type: this.state.tipoPathbook,
				grade: parseInt(this.state.potenciaPathbook),
				free: this.state.gratisPathbook,
				////audio
				audiobook: this.state.audioPathbook,
				audioLanguages: this.state.languagesAudioPathbook,
				cover: {
					"en": null,
					"es": null,
					"en-US": null,
					"es-MX": null,
				},
				////Imagenes vacias
				featureImage: null,
				audiobookCover: {
					"en": null,
					"es": null,
					"en-US": null,
					"es-MX": null,
				},
				deleted: null,
				video: {
					"en": this.state.bookTrailerEnPathbook,
					"es": this.state.bookTrailerEsPathbook,
					"en-US": this.state.bookTrailerEnPathbook,
					"es-MX": this.state.bookTrailerEsPathbook,
				},
			});
		} else {
			let pathbookId = this.state.pathbooksAModificar
			firestore.collection("pathbooks").doc(pathbookId).update({
				publisher: "Pathbooks",
				languages: this.state.languagesPathbook,
				translations: this.state.languagesIAPathbook,
				classification: this.state.clasificacion,
				title: {
					"en": this.state.tituloEnPathbook,
					"es": this.state.tituloEsPathbook,
					"en-US": this.state.tituloEnPathbook,
					"es-MX": this.state.tituloEsPathbook,
					"de": this.state.tituloDePathbook,
		    		"fr": this.state.tituloFrPathbook,
	 			    "it": this.state.tituloItPathbook,
				    "pt": this.state.tituloPtPathbook,
				},
				subtitle: {
					"en": this.state.subTituloEnPathbook,
					"es": this.state.subTituloEsPathbook,
					"en-US": this.state.subTituloEnPathbook,
					"es-MX": this.state.subTituloEsPathbook,
				},
				author: firestore.doc(this.state.autorCarruselChk),
				genres: this.state.generosPathbookslSave,
				summary: {
					"en": this.state.sumarioInCarrusel,
					"es": this.state.sumarioEsCarrusel,
					"en-US": this.state.sumarioInCarrusel,
					"es-MX": this.state.sumarioEsCarrusel,
			    	"de": this.state.sumarioDeCarrusel,
		    		"fr": this.state.sumarioFrCarrusel,
		 			"it": this.state.sumarioItCarrusel,
				    "pt": this.state.sumarioPtCarrusel,
				},
				tags: {
					"en": this.state.buscadorEnPathbookSave,
					"es": this.state.buscadorEsPathbookSave,
					"en-US": this.state.buscadorEnPathbookSave,
					"es-MX": this.state.buscadorEsPathbookSave,
			    	"de": this.state.buscadorDePathbookSave,
		    		"fr": this.state.buscadorFrPathbookSave,
		 			"it": this.state.buscadorItPathbookSave,
				    "pt": this.state.buscadorPtPathbookSave,
				},
				readingTime: parseInt(this.state.tiempoLectPathbook),

				type: this.state.tipoPathbook,
				grade: parseInt(this.state.potenciaPathbook),
				free: this.state.gratisPathbook,

				audiobook: this.state.audioPathbook,
				audioLanguages: this.state.languagesAudioPathbook,

				video: {
					"en": this.state.bookTrailerEnPathbook,
					"es": this.state.bookTrailerEsPathbook,
					"en-US": this.state.bookTrailerEnPathbook,
					"es-MX": this.state.bookTrailerEsPathbook,
				},
			});
		}
		this.limpiarForm();
	}

	limpiarForm() {
		this.setState({
			editar: false,
			languagesPathbook: [],
			languagesPathbookEN: false,
			languagesPathbookES: false,
			languagesPathbookDE: false,
			languagesPathbookFR: false,
			languagesPathbookIT: false,
			languagesPathbookPT: false,
			languagesIAPathbook: [],
			clasificacion: "selecciona",
			languagesIAPathbookEN: false,
			languagesIAPathbookES: false,
			languagesIAPathbookDE: false,
			languagesIAPathbookFR: false,
			languagesIAPathbookIT: false,
			languagesIAPathbookPT: false,
			tituloEnPathbook: "",
			tituloEsPathbook: "",
			tituloDePathbook: "",
			tituloFrPathbook: "",
			tituloItPathbook: "",
			tituloPtPathbook: "",
			subTituloEnPathbook: "",
			subTituloEsPathbook: "",
			autorCarrusel: "",
			autorCarruselChk: "",
			generoCarrusel01: "",
			generoCarrusel02: "",
			generoCarrusel03: "",
			tiempoLectPathbook: 0,
			sumarioInCarrusel: "",
			sumarioEsCarrusel: "",
			sumarioDeCarrusel: "",
			sumarioFrCarrusel: "",
			sumarioItCarrusel: "",
			sumarioPtCarrusel: "",
			buscadorEnPathbookSave: [],
			buscadorEsPathbookSave: [],
			buscadorDePathbookSave: [],
			buscadorFrPathbookSave: [],
			buscadorItPathbookSave: [],
			buscadorPtPathbookSave: [],
			buscadorEnPathbook: [],
			buscadorEsPathbook: [],
			buscadorDePathbook: [],
			buscadorFrPathbook: [],
			buscadorItPathbook: [],
			buscadorPtPathbook: [],
			tipoPathbook: "",
			gratisPathbook: false,

			potenciaPathbook: 0,
			generosPathbookslSave: [],
			bookTrailerEnPathbook: "",
			bookTrailerEsPathbook: "",
			pathbookSeleccionado: "",
			pathbooksAModificar: "",

			audioPathbook: false,
			languagesAudioPathbook: null,
			languagesAudioPathbookEN: false,
			languagesAudioPathbookES: false,
			languagesAudioPathbookDE: false,
			languagesAudioPathbookFR: false,
			languagesAudioPathbookIT: false,
			languagesAudioPathbookPT: false,
		});
		swal.fire({title: "Felicidades", text: "Guardado con exito", type: "error", confirmButtonText: "Entendido"});
	}

	async componentDidMount() {
		/////lista de pathbooks
		let selectPathbookList = [];
		await this.state.pathbooksLista.forEach((task) => {
				let idPathbook = task.doc.id;
				let nombrePathbook = task.doc.data().title["es"];
				selectPathbookList.push({id: idPathbook, nombre: nombrePathbook});
			// firestore.collection("pathbooks").doc(idPathbook).update({
			// 	audiobookCover: null
			// });
		});
		this.setState({selectPathbooks: selectPathbookList});

		///////llenamos la lista de los Autores
		let selectAutoresList = [];
		await this.state.autoresLista.forEach((task) => {
			if (task.doc.data().active === true) {
				let idAutor = task.doc.id;
				let nombreAutor = task.doc.data().name;
				selectAutoresList.push({id: idAutor, nombre: nombreAutor});
			}
		});
		this.setState({selectAutores: selectAutoresList});

		///////llenamos la lista de los Genero
		let selectGeneroList = [];
		await this.state.generoLista.forEach((task) => {
			if (task.doc.data().active === true) {
				let idGenero = task.doc.id;
				let nombreGenero = task.doc.data()["es-MX"];
				selectGeneroList.push({id: idGenero, nombre: nombreGenero});
			}
		});
		this.setState({selectGenero: selectGeneroList});
	}


	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header">
					<h2 className="panel-title">Pathbook</h2>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Informacion de Pathbook</h3>
						</div>

						<div className="d-flex">
							<div className="card-body">
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<div className="container">
													<label>Si quieres editar un pathbook Buscalo aqui:</label>
													<div className="row">
														<input className="form-control" list="selectPathbook" id="generoSelected" name="pathbookSeleccionado" type="select" placeholder="Elige un pathbook a modificar" value={this.state.pathbookSeleccionado} onChange={this.manejadorCambios}/>
														<datalist id="selectPathbook">
															{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
														</datalist>
													</div>

												</div>
												<br/>
												<div className="form-group">
													{/*validaBuscadorPathbook*/}
													<button onClick={() => this.validaPathbooksExiste()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Seleccionar pathbook</button>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>

						<div className="d-flex">
							<div className="card-body">

								<div className="container">
									<div className="row">
										<div className="col-sm">

											<div className="form-group">
												<div className="container">
													<label>Idioma para tu pathbook:</label>
													<h6>Selecciona minimo un idioma que tendra tu pathbook</h6>
													<div className="row">
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesPathbookEN" type="checkbox" checked={this.state.languagesPathbookEN} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Ingles</label>
															</div>
														</div>
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesPathbookES" type="checkbox" checked={this.state.languagesPathbookES} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Español</label>
															</div>
														</div>
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesPathbookDE" type="checkbox" checked={this.state.languagesPathbookDE} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Aleman</label>
															</div>
														</div>
													</div>

													<div className="row">
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesPathbookFR" type="checkbox" checked={this.state.languagesPathbookFR} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Frances</label>
															</div>
														</div>
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesPathbookIT" type="checkbox" checked={this.state.languagesPathbookIT} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Italiano</label>
															</div>
														</div>
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesPathbookPT" type="checkbox" checked={this.state.languagesPathbookPT} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Portugues</label>
															</div>
														</div>
													</div>
													<br/>
													<label>Idioma traducido con IA:</label>
													<h6>Selecciona minimo un idioma que tendra tu pathbook</h6>
													<div className="row">
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesIAPathbookEN" type="checkbox" checked={this.state.languagesIAPathbookEN} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Ingles</label>
															</div>
														</div>
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesIAPathbookES" type="checkbox" checked={this.state.languagesIAPathbookES} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Español</label>
															</div>
														</div>
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesIAPathbookDE" type="checkbox" checked={this.state.languagesIAPathbookDE} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Aleman</label>
															</div>
														</div>
													</div>

													<div className="row">
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesIAPathbookFR" type="checkbox" checked={this.state.languagesIAPathbookFR} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Frances</label>
															</div>
														</div>
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesIAPathbookIT" type="checkbox" checked={this.state.languagesIAPathbookIT} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Italiano</label>
															</div>
														</div>
														<div className="col-sm">
															<div className="form-check">
																<input name="languagesIAPathbookPT" type="checkbox" checked={this.state.languagesIAPathbookPT} onChange={this.manejadorImputs}/>
																<label className="form-check-label"> - Portugues</label>
															</div>
														</div>
													</div>
												</div>
											</div>


											<div className="form-group">
												<label>Titulo de pathbook</label>
												<input className="form-control" name="tituloEnPathbook" placeholder="Titulo Ingles" type="text" value={this.state.tituloEnPathbook} onChange={this.manejadorCambios}/>
												<input className="form-control" name="tituloEsPathbook" placeholder="Titulo Español" type="text" value={this.state.tituloEsPathbook} onChange={this.manejadorCambios}/>
												<input className="form-control" name="tituloDePathbook" placeholder="Titulo Aleman" type="text" value={this.state.tituloDePathbook} onChange={this.manejadorCambios}/>
												<input className="form-control" name="tituloFrPathbook" placeholder="Titulo Frances" type="text" value={this.state.tituloFrPathbook} onChange={this.manejadorCambios}/>
												<input className="form-control" name="tituloItPathbook" placeholder="Titulo Portuges" type="text" value={this.state.tituloItPathbook} onChange={this.manejadorCambios}/>
												<input className="form-control" name="tituloPtPathbook" placeholder="Titulo Italiano" type="text" value={this.state.tituloPtPathbook} onChange={this.manejadorCambios}/>
												<span>Campo obligatorio</span>
											</div>
											<div className="form-group">
												<label>Subtitulo de pathbook</label>
												<input className="form-control" name="subTituloEnPathbook" placeholder="Subtitulo Ingles" type="text" value={this.state.subTituloEnPathbook} onChange={this.manejadorCambios}/>
												<input className="form-control" name="subTituloEsPathbook" placeholder="Subtitulo Español" type="text" value={this.state.subTituloEsPathbook} onChange={this.manejadorCambios}/>
												<span>Campo obligatorio</span>
											</div>

											<div className="form-group">
												<label>Clasificación de pathbook</label>
												<h6>Selecciona una clasificación</h6>

												<select className="form-control" name="clasificacion" value={this.state.clasificacion} onChange={this.manejadorCambios}>
													<option value="selecciona">Selecciona una</option>
													<option value="A">A</option>
													<option value="AA">AA</option>
													<option value="B">B</option>
													<option value="B15">B15</option>
													<option value="C">C</option>
												</select>
											</div>

											<div className="form-group">
												<label>Escritor - Autor</label>
												<h6>Seleeciona un escritor o autor</h6>
												<input className="form-control" list="selectAutor" id="autorSelected" name="autorCarrusel" type="select" placeholder="Elige un Autor" value={this.state.autorCarrusel} onChange={this.manejadorCambios}/>
												<datalist id="selectAutor">
													{this.state.selectAutores.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
												</datalist>
												<span>Campo obligatorio</span>
											</div>
											<div className="form-group">
												<label>Generos</label>
												<h6>Seleeciona el genero principal</h6>
												<input className="form-control" list="selectGenero1" id="generoSelected" name="generoCarrusel01" type="select" placeholder="Elige un Genero" value={this.state.generoCarrusel01} onChange={this.manejadorCambios}/>
												<datalist id="selectGenero1">
													{this.state.selectGenero.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
												</datalist>
											</div>
											<div className="form-group">
												<h6>Seleeciona el genero secundario</h6>
												<input className="form-control" list="selectGenero2" id="generoSelected" name="generoCarrusel02" type="select" placeholder="Elige un Genero" value={this.state.generoCarrusel02} onChange={this.manejadorCambios}/>
												<datalist id="selectGenero2">
													{this.state.selectGenero.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
												</datalist>
												<span>Campo obligatorio</span>
											</div>
											<div className="form-group">
												<h6>Seleeciona el genero adicional</h6>
												<input className="form-control" list="selectGenero3" id="generoSelected" name="generoCarrusel03" type="select" placeholder="Elige un Genero" value={this.state.generoCarrusel03} onChange={this.manejadorCambios}/>
												<datalist id="selectGenero3">
													{this.state.selectGenero.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
												</datalist>
											</div>
											<div className="form-group">
												<label>Tiempo de lectura en minutos</label>
												<input className="form-control" name="tiempoLectPathbook" placeholder="Tiempo de lectura (numero)" type="number" value={this.state.tiempoLectPathbook} onChange={this.manejadorCambios}/>
												<span>Obligatorio</span>
											</div>
											<div className="form-group">
												<label>Ingresa los links para los book trailers</label>
												<input className="form-control" name="bookTrailerEnPathbook" placeholder="Ingles" type="text" value={this.state.bookTrailerEnPathbook} onChange={this.manejadorCambios}/>
												<input className="form-control" name="bookTrailerEsPathbook" placeholder="Español" type="text" value={this.state.bookTrailerEsPathbook} onChange={this.manejadorCambios}/>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-group">
												<label>Sumario - Biografia - Sinopsis</label>
												<textarea className="form-control" name="sumarioInCarrusel" placeholder="Ingles" value={this.state.sumarioInCarrusel} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="sumarioEsCarrusel" placeholder="Español" value={this.state.sumarioEsCarrusel} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="sumarioDeCarrusel" placeholder="Aleman" value={this.state.sumarioDeCarrusel} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="sumarioFrCarrusel" placeholder="Frances" value={this.state.sumarioFrCarrusel} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="sumarioPtCarrusel" placeholder="Portugues" value={this.state.sumarioPtCarrusel} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="sumarioItCarrusel" placeholder="Italiano" value={this.state.sumarioItCarrusel} onChange={this.manejadorCambios}/>
												<span>Campo obligatorio</span>
											</div>
											<div className="form-group">
												<label>Buscador - separar con ( , ) y espacio</label>
												<textarea className="form-control" name="buscadorEnPathbook" placeholder="Ingles" value={this.state.buscadorEnPathbook} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="buscadorEsPathbook" placeholder="Español" value={this.state.buscadorEsPathbook} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="buscadorDePathbook" placeholder="Aleman" value={this.state.buscadorDePathbook} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="buscadorFrPathbook" placeholder="Frances" value={this.state.buscadorFrPathbook} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="buscadorPtPathbook" placeholder="Portugues" value={this.state.buscadorPtPathbook} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="buscadorItPathbook" placeholder="Italiano" value={this.state.buscadorItPathbook} onChange={this.manejadorCambios}/>
												<span>Campo obligatorio</span>
											</div>

											<div className="form-group">
												<label>Formato</label>
												<h6>Selecciona un formato para tu pathbook</h6>

												<select className="form-control" name="tipoPathbook" value={this.state.tipoPathbook} onChange={this.manejadorCambios}>
													<option value="selecciona">Selecciona uno</option>
													<option value="Pathbook">Pathbook</option>
													<option value="Ebook">Ebook</option>
													<option value="Micro">Micro</option>
													<option value="Series">Series</option>
													<option value="PathbookAudio">Pathbook, Audio</option>
													<option value="EbookAudio">Ebook, Audio</option>
													<option value="MicroAudio">Micro, Audio</option>
													<option value="SeriesAudio">Series, Audio</option>
												</select>
											</div>
											<div className="form-group">
												<label>Tu pathbook es Gratis?:</label>
												<div className="form-check">
													<input name="gratisPathbook" type="checkbox" checked={this.state.gratisPathbook} onChange={this.manejadorImputs}/>
													<label className="form-check-label"> - Gratis</label>
												</div>
												<h6>Tu pathbook quedara gratuito</h6>
											</div>

											<div className="form-group">
												<label>Potencia pathbook</label>
												<h6>Potencia el valor de tu pathbook siendo 1 el menos valioso</h6>

												<select className="form-control" name="potenciaPathbook" value={this.state.potenciaPathbook} onChange={this.manejadorCambios}>
													<option value="0">Selecciona uno</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
												</select>
											</div>
											<div className="form-group">
												<label>Audio pathbook</label>
												<h6>Potencia el valor de tu pathbook siendo 1 el menos valioso</h6>

												<label>Tu pathbook tiene audio?:</label>
												<div className="form-check">
													<input name="audioPathbook" type="checkbox" checked={this.state.audioPathbook} onChange={this.manejadorImputs}/>
													<label className="form-check-label"> - Con audio</label>
												</div>
												<h6>Deja en blanco si no cuentas con audio</h6>
											</div>
											<div className="container">
												<label>Idioma para tu audio pathbook:</label>
												<h6>Selecciona minimo un idioma que tendra tu audio Pathbook</h6>
												<div className="row">
													<div className="col-sm">
														<div className="form-check">
															<input name="languagesAudioPathbookEN" type="checkbox" checked={this.state.languagesAudioPathbookEN} onChange={this.manejadorImputs}/>
															<label className="form-check-label"> - Ingles</label>
														</div>
													</div>
													<div className="col-sm">
														<div className="form-check">
															<input name="languagesAudioPathbookES" type="checkbox" checked={this.state.languagesAudioPathbookES} onChange={this.manejadorImputs}/>
															<label className="form-check-label"> - Español</label>
														</div>
													</div>
													<div className="col-sm">
														<div className="form-check">
															<input name="languagesAudioPathbookDE" type="checkbox" checked={this.state.languagesAudioPathbookDE} onChange={this.manejadorImputs}/>
															<label className="form-check-label"> - Frances</label>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-sm">
														<div className="form-check">
															<input name="languagesAudioPathbookFR" type="checkbox" checked={this.state.languagesAudioPathbookFR} onChange={this.manejadorImputs}/>
															<label className="form-check-label"> - Frances</label>
														</div>
													</div>
													<div className="col-sm">
														<div className="form-check">
															<input name="languagesAudioPathbookIT" type="checkbox" checked={this.state.languagesAudioPathbookIT} onChange={this.manejadorImputs}/>
															<label className="form-check-label"> - Italiano</label>
														</div>
													</div>
													<div className="col-sm">
														<div className="form-check">
															<input name="languagesAudioPathbookPT" type="checkbox" checked={this.state.languagesAudioPathbookPT} onChange={this.manejadorImputs}/>
															<label className="form-check-label"> - Portugues</label>
														</div>
													</div>
												</div>
											</div>
											<br/>
											<div className="form-group">
												{
													!this.state.pathbooksAModificar
														? (<div>
															<br/>
															<label>Agrega tu nuevo pathbook</label>
															<br/>
															<button onClick={() => this.validaBuscadorPathbook()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Agregar</button>
														</div>)
														:
														<div>
															<button onClick={() => this.validaBuscadorPathbook()} type="button" id="buttonPathbook" className="btn btn-warning btn-lg btn-block">Modificar</button>
														</div>
												}
											</div>
										</div>
									</div>
								</div>
								<br/>
							</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

export default FormNewPathbook;


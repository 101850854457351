import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import axios from "axios";
import swal from "sweetalert2";

///// Componente para armar la pantlla
class RepLecturasPathbook extends Component {
	constructor(props) {
		super();
		this.state = {
			pathbooksLista: props.listaPathbooks,
			listaPathbookUso: [],
			valorBarraLoad: 0,

			metUsuariosTotal: 0,
			metPatbooksIni: 0,
			metPatbooksEnd: 0,
			metPatbooksPag: 0,
			metPatbooksEve: 0,

			metPatbooksIniVe: 0,
			metPatbooksEndVe: 0,
			metPatbooksPagVe: 0,
			metPatbooksEveVe: 0,

			pullDll: 0,

			consecutivo: 0,
			consecutivoPull: 0,
			selectPathbooks: [],

			fechaIni: "",
			fechaEnd: "",
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	agreagarPull() {
		let calculaPull = [];
		let consecutivo = 0;
		let pulUnitario = this.state.pullDll / this.state.metPatbooksEve;
		let pullCalculado = 0;

		if (this.props.pullDll === 0) {
			swal.fire({
				title: "Ingresa una monto en dll",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else {
			this.state.listaPathbookUso.forEach((doc) => {
				if (!doc.divideSumNum) {
					pullCalculado = doc.totalEvents * pulUnitario;
				} else {
					pullCalculado = doc.divideSumNum * pulUnitario;
				}
				pullCalculado = pullCalculado.toFixed(3);

				calculaPull.push({
					urlGenrado: doc.urlPathbook,
					id: doc.id,
					titleEs: doc.titleEs,
					titleEn: doc.titleEn,
					cantIniciados: doc.cantIniciados,
					cantFinalizados: doc.cantFinalizados,
					changedPage: doc.changedPage,
					totalEvents: doc.totalEvents,
					dataUser: doc.dataUser,
					autorName: doc.autorName,

					divideIni: doc.divideIni,
					divideEnd: doc.divideEnd,
					divideChan: doc.divideChan,
					divideSum: doc.divideSum,
					divideSumNum: doc.divideSumNum,

					pull: pullCalculado,
				});
				////ordenamos la lista
				calculaPull.sort(function (a, b) {
					return b.pull - a.pull;
				});
				//////sacamos la lista
				this.setState({listaPathbookUso: calculaPull});
				///////calculamos consecutivo
				consecutivo = consecutivo + 1;
				this.setState({
					consecutivoPull: consecutivo,
				});
			});
		}


	}

	calculoRegalias() {

		if (!this.state.fechaIni) {
			swal.fire({
				title: "ingresa una fecha inicial",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.fechaEnd) {
			swal.fire({
				title: "ingresa una fecha final",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else {
			let consecutivo = 0;
			let lectorDataTotal = [];
			let listaData = [];

			this.state.selectPathbooks.forEach((doc) => {
				let fechaMinima = new Date(this.state.fechaIni);
				let fechaMaxima = new Date(this.state.fechaEnd);
				let urlPathbook = "https://us-central1-pathbook.cloudfunctions.net/admin/analytics?pathbook=" + doc.id + "&from=" + fechaMinima.getTime() + "&to=" + fechaMaxima.getTime() + "&limit=100000";
				//axios.get("https://us-central1-pathbook.cloudfunctions.net/admin/analytics?pathbook="+doc.id+"&from="+this.state.periodIniPull+"&to="+this.state.periodEndPull+"&limit=40000").then(res => {
				//axios.get("https://us-central1-pathbook.cloudfunctions.net/admin/analytics?pathbook="+doc.id+"&limit=80000").then(res => {
				console.log(urlPathbook)
				console.log(doc.nombreES)
				axios.get(urlPathbook).then(res => {
					let cFinalizados = 0;
					let cIniciados = 0;
					let cChangedPage = 0;

					/////contadores de Lectores
					const lectorData = [];
					res.data.forEach((doc) => {
						///if(doc.free === false){
							////empujamos al areglo los usuarios por ficha
							lectorData.push(doc.user);
							////empujamos al areglo los usuarios general
							lectorDataTotal.push(doc.user);
							////if cara contar eventos
							if (doc.event === "finishedPathbook") {
								cFinalizados = cFinalizados + 3;
							}
							if (doc.event === "startedPathbook") {
								cIniciados = cIniciados + 2;
							}
							if (doc.event === "changedPage") {
								cChangedPage = cChangedPage + 1;
							}
						///}
					});

					////contamos los usuarios correctamente para cada ficha
					const lectorResult = lectorData.filter(function (item, index, array) {
						return array.indexOf(item) === index;
					});
					let cUsuarios = lectorResult.length;

					////contamos los usuarios correctamente de forma general
					const lectorResultTotal = lectorDataTotal.filter(function (item, index, array) {
						return array.indexOf(item) === index;
					});
					this.setState({metUsuariosTotal: lectorResultTotal.length});

					////enviamos datos de patbooks

					this.setState({metPatbooksIni: this.state.metPatbooksIni + cIniciados});
					this.setState({metPatbooksEnd: this.state.metPatbooksEnd + cFinalizados});
					this.setState({metPatbooksPag: this.state.metPatbooksPag + cChangedPage});

					////////obtenemos el nombre del author para la ficha
					let divideIni = parseInt(cIniciados) / parseInt(cUsuarios);
					let divideEnd = parseInt(cFinalizados) / parseInt(cUsuarios);
					let divideChan = parseInt(cChangedPage) / parseInt(cUsuarios);
					let divideSumNum = 0;
					let cDivideSumNum = 0;
					let divideSum = 0;

					if (divideIni > 6) {
						divideIni = " --> " + cUsuarios * 5;
						divideSumNum = divideSumNum + cUsuarios * 5;
						divideSum = ""
					}else{
						cDivideSumNum = cDivideSumNum + cIniciados;
						divideIni = ""
					}

					if (divideEnd > 6) {
						divideEnd = " --> " + cUsuarios * 5;
						divideSumNum = divideSumNum + cUsuarios * 5;
						divideSum = ""
					}else{
						cDivideSumNum = cDivideSumNum + cFinalizados;
						divideEnd = ""
					}

					if (divideChan > 30) {
						divideChan = " --> " + cUsuarios * 30;
						divideSumNum = divideSumNum + cUsuarios * 30;
						divideSum = ""
					}else{
						cDivideSumNum = cDivideSumNum + cChangedPage;
						divideChan = ""
					}

					if (divideSumNum > 1){
						divideSumNum = divideSumNum  + cDivideSumNum;
						divideSum = " --> " + divideSumNum;
						this.setState({metPatbooksEve: this.state.metPatbooksEve + divideSumNum + cDivideSumNum});
					} else {
						divideSum = "";
						this.setState({metPatbooksEve: this.state.metPatbooksEve + cDivideSumNum});
					}

					listaData.push({
						urlGenrado: urlPathbook,
						id: doc.id,
						titleEs: doc.nombreES,
						titleEn: doc.nombreEN,
						cantIniciados: cIniciados,
						cantFinalizados: cFinalizados,
						changedPage: cChangedPage,
						totalEvents: cChangedPage + cFinalizados + cIniciados,
						dataUser: cUsuarios,
						autorName: doc.autor,
						pull: 0,
						divideIni: divideIni,
						divideEnd: divideEnd,
						divideChan: divideChan,
						divideSum: divideSum,
						divideSumNum: divideSumNum,
					});
					////ordenamos la lista
					listaData.sort(function (a, b) {
						return b.totalEvents - a.totalEvents;
					});
					//////sacamos la lista
					this.setState({listaPathbookUso: listaData});
					///////calculamos consecutivo
					consecutivo = consecutivo + 1;
					this.setState({
						consecutivo: consecutivo,
					});
				});

			});
		}


	}

	//////// async funcion asincrona para poder usar await
	async componentDidMount() {
		///////llenamos la lista de los pathbooks
		let selectPathbookList = [];
		this.state.pathbooksLista.forEach((doc) => {
			let pathbookInfo = doc.doc.data();
			let pathbookId = doc.doc.id;
			if (pathbookInfo.active) {
					firestore.doc("authors/" + pathbookInfo.author.id).get().then((documentSnapshot) => {
						let dataAuthor = documentSnapshot.data();
						let idPathbook = pathbookId;
						let nombrePathbookES = pathbookInfo.title["es"];
						let nombrePathbookEN = pathbookInfo.title["en"];
						let author = dataAuthor.name;
						let authorID = pathbookInfo.id;
						selectPathbookList.push({id: idPathbook, nombreES: nombrePathbookES, nombreEN: nombrePathbookEN, autor: author, autorID: authorID});
					});
			}
		});
		this.setState({selectPathbooks: selectPathbookList});
	}

	render() {
		return (
			<div className="content-wrapper">
				<div className="content-header">
					<div className="container-fluid">
						<div className="card card-primary">
							<div className="card-body">
								<h2 className="panel-title">Reporte de lecturas por usuario</h2>
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-check">
												<label>Fecha inicial:</label>
												<input className="form-control" name="fechaIni" type="date" value={this.state.fechaIni} onChange={this.manejadorCambios}/>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-check">
												<label>Fecha final:</label>
												<input className="form-control" name="fechaEnd" type="date" value={this.state.fechaEnd} onChange={this.manejadorCambios}/>
											</div>
										</div>
									</div>
									<br/>
									<div className="row">
										<div className="col-sm">
											<div className="form-check">
												<label>Inicia la consulta:</label>
												<button onClick={() => this.calculoRegalias()} type="button" id="buttonCarrusel" className="btn btn-primary btn-lg btn-block">CONSULTAR</button>
												<br/>
												<div className="form-check">
													<label>Progreso de consulta:</label>
													<progress value={this.state.consecutivo} max={this.state.selectPathbooks.length}/>
												</div>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>
					</div>

				</div>

				<div className="content-header d-flex">
					<div className="container-fluid">

						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Cantidad de Pathbooks: {this.state.selectPathbooks.length} Calculados: {this.state.consecutivo}</h3>
							</div>
							<div className="description-block">
								<h5 className="description-header">TOTALES</h5>
							</div>
							<div>
								<table className="table">
									<thead className="thead-dark">
									<tr>

										<th scope="col" width="40">Usuarios</th>
										<th scope="col" width="40">Iniciados</th>
										<th scope="col" width="40">Finalizados</th>
										<th scope="col" width="40">ChangedPage</th>
										<th scope="col" width="50">Suma</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td>{this.state.metUsuariosTotal}</td>
										<td>{this.state.metPatbooksIni}</td>
										<td>{this.state.metPatbooksEnd}</td>
										<td>{this.state.metPatbooksPag}</td>
										<td>{this.state.metPatbooksEve}</td>
									</tr>
									</tbody>
								</table>
								<div className="description-block">
									<h5 className="description-header">Reporte de pathbooks</h5>
								</div>
								<table className="table">
									<thead className="thead-dark">
									<tr>
										<th scope="col" width="130">Titulo</th>
										<th scope="col" width="130">Autor</th>
										<th scope="col" width="40">Usuarios</th>
										<th scope="col" width="40">Iniciados</th>
										<th scope="col" width="40">ChangedPage</th>
										<th scope="col" width="40">Finalizados</th>
										<th scope="col" width="50">Suma</th>
									</tr>
									</thead>
									<tbody>
									{this.state.listaPathbookUso.map(data => (
										<tr key={data.id}>
											<td width="100">
												<div>{data.titleEs}<br/>{data.titleEn}</div>
											</td>
											<td width="100">{data.autorName}</td>
											<td>{data.dataUser}</td>
											<td>{data.cantIniciados} {data.divideIni}</td>
											<td>{data.changedPage} {data.divideChan}</td>
											<td>{data.cantFinalizados} {data.divideEnd}</td>
											<td>{data.totalEvents} {data.divideSum}</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RepLecturasPathbook;

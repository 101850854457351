import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import swal from "sweetalert2";
import Moment from "moment";
import FichaOffert from "./FichaOffert";

///// Componente para armar la pantlla
class FormNewOffers extends Component {
	constructor(props) {
		super(props);
		this.state = {


			/////offer lista
			amountOffer: 0,
			colorOffer: "",
			daysOffer: 0,
			quantityUserOffer: 0,
			subTitleOffer: "",
			titleOffer: "",
			txt01Offer: "",
			txt02Offer: "",
			txt03Offer: "",
			txt04Offer: "",
			txtBeneficiosOffer: "",
			txtHeaderOffer: "",
			divisaOffer: "selecciona",

			titleOfferSubOffer: "",
			amountOfferSubOffer: 0,
			daysOfferSubOffer: 0,

			offerList: [],

		};
		this.manejadorCambios = this.manejadorCambios.bind(this);

	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	validaFormOferta() {

		if (this.state.divisaOffer === "selecciona") {
			swal.fire({title: "Ingresa divisa e idioma para oferta", text: "Necesario para esta oferta", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.txtHeaderOffer) {
			swal.fire({title: "Ingresa texto para el header de tu oferta", text: "Necesario para esta oferta", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.colorOffer) {
			swal.fire({title: "Ingresa un color", text: "Necesario para esta oferta", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.titleOffer) {
			swal.fire({title: "Ingresa un titulo", text: "Necesario para esta oferta", type: "error", confirmButtonText: "Entendido"});
		} else if (this.state.amountOffer === 0) {
			swal.fire({title: "Ingresa un valor en dll para tu oferta", text: "Necesario para esta oferta", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.daysOffer) {
			swal.fire({title: "Ingresa una cantidad de dias", text: "Necesario para esta oferta", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.daysOffer) {
			swal.fire({title: "Ingresa una cantidad de usuarios", text: "Necesario para esta oferta", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.quantityUserOffer) {
			swal.fire({title: "Ingresa un texto de subtitulo", text: "Necesario para esta oferta", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.txtBeneficiosOffer) {
			swal.fire({title: "Ingresa un texto de precentacion de veneficios", text: "Necesario para esta oferta", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.txt01Offer) {
			swal.fire({title: "Ingresa un beneficio", text: "Necesario para esta oferta", type: "error", confirmButtonText: "Entendido"});
		} else {
			this.guardaDatos();
		}
	}

	///guardamos os datos en la bd
	guardaDatos() {
		firestore.collection("offers").doc().set({
			amount: parseInt(this.state.amountOffer),
			color: this.state.colorOffer,
			days: parseInt(this.state.daysOffer),
			quantityUser: parseInt(this.state.quantityUserOffer),
			subTitle: this.state.subTitleOffer,
			title: this.state.titleOffer,
			txt01: this.state.txt01Offer,
			txt02: this.state.txt02Offer,
			txt03: this.state.txt03Offer,
			txt04: this.state.txt04Offer,
			txtBeneficios: this.state.txtBeneficiosOffer,
			txtHeader: this.state.txtHeaderOffer,
			modified: Moment().toDate(),
			contIniciados: 0,
			contPagados: 0,
			contRegUsuarios: 0,
			contSubOffert: 0,
			titleSubOffer: this.state.titleOfferSubOffer,
			amountSubOffer: parseInt(this.state.amountOfferSubOffer),
			daysSubOffer: parseInt(this.state.daysOfferSubOffer),
			divisa: this.state.divisaOffer,
		});
		this.limpiarForm();
	}

	limpiarForm() {
		this.setState({
			divisaOffer: "selecciona",
			amountOffer: 0,
			colorOffer: "",
			daysOffer: 0,
			quantityUserOffer: 0,
			subTitleOffer: "",
			titleOffer: "",
			txt01Offer: "",
			txt02Offer: "",
			txt03Offer: "",
			txt04Offer: "",
			txtBeneficiosOffer: "",
			txtHeaderOffer: "",
			titleOfferSubOffer: "",
			amountOfferSubOffer: 0,
			daysOfferSubOffer: 0,

		});
		swal.fire({title: "Felicidades", text: "Guardado con exito", icon: "success", confirmButtonText: "Entendido"});
	}

	async componentDidMount() {
		///////llenamos la lista de los offers
		let listaData = [];
		firestore.collection("offers").onSnapshot(querySnapshot => {
			///esta variable evita que se dupliquen las consultas
			listaData = [];
			let features = querySnapshot.docs;
			features.forEach((doc) => {
				listaData.push({
					offerID: doc.id,
					contIniciados: doc.data().contIniciados,
					contPagados: doc.data().contPagados,
					contRegUsuarios: doc.data().contRegUsuarios,
					color: doc.data().color,
					amount: doc.data().amount,
					title: doc.data().title,
					days: doc.data().days,
					quantityUser: doc.data().quantityUser,
					titleSubOffer: doc.data().titleSubOffer,
					amountSubOffer: doc.data().amountSubOffer,
					daysSubOffer: doc.data().daysSubOffer,
					divisa: doc.data().divisa,
				});
			});
			//////ordenamos la lista por prioridad (priority)
			listaData.sort(function (a, b) {
				return a.days - b.days;
			});
			this.setState({offerList: listaData});
		});
		///////llenamos la lista de los features
	}


	render() {
		return (
			<div className="content-wrapper">
				<div className="content-header">
					<h2 className="panel-title">Ofertas</h2>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Agrega ofertas</h3>
						</div>
						<div className="d-flex">
							<div className="card-body">
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="row">
												<div className="col col-md-6">
													<div className="form-group">
														<label>Selecciona una divisa </label>
														<select className="form-control" name="divisaOffer" value={this.state.divisaOffer} onChange={this.manejadorCambios}>
															<option value="selecciona">Selecciona uno</option>
															<option value="USD">USD</option>
															<option value="MX">MXN</option>
														</select>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">
														<label>Ingresa un texto para el encavesado </label>
														<input className="form-control" name="txtHeaderOffer" placeholder="Texto de beneficios" type="text" value={this.state.txtHeaderOffer} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">
														<label>Ingresa el codigo del color de tu oferta</label>
														<input className="form-control" name="colorOffer" placeholder="color en codigo" type="text" value={this.state.colorOffer} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">

														<label>Ingresa el titulo</label>
														<input className="form-control" name="titleOffer" placeholder="Titulo de oferta" type="text" value={this.state.titleOffer} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">
														<label>Valor en dll de tu oferta</label>
														<input className="form-control" name="amountOffer" placeholder="Valor en dll" type="number" value={this.state.amountOffer} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">
														<label>Cuandos dias otorga tu oferta (valor numero ejemplo 6 = a 6 dias) </label>
														<input className="form-control" name="daysOffer" placeholder="Dias de oferta" type="number" value={this.state.daysOffer} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">
														<label>Cuantos usuarios se veneficiaran de esta oferta. (valor numero ejemplo 6 = a 6 usuarios) </label>
														<input className="form-control" name="quantityUserOffer" placeholder="Dias de oferta" type="number" value={this.state.quantityUserOffer} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">
														<label>Ingresa el subtitulo</label>
														<input className="form-control" name="subTitleOffer" placeholder="Subtitulo de oferta" type="text" value={this.state.subTitleOffer} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
												</div>
												<div className="col col-md-6">
													<div className="form-group">
														<label>Ingresa un texto de precentacion de beneficios </label>
														<input className="form-control" name="txtBeneficiosOffer" placeholder="Texto de beneficios" type="text" value={this.state.txtBeneficiosOffer} onChange={this.manejadorCambios}/>
													</div>
													<div className="form-group">
														<label>Ingresa un texto de beneficios </label>
														<input className="form-control" name="txt01Offer" placeholder="Texto de beneficios" type="text" value={this.state.txt01Offer} onChange={this.manejadorCambios}/>
														<span>Campo obligatorio</span>
													</div>
													<div className="form-group">
														<label>Ingresa un texto de beneficios </label>
														<input className="form-control" name="txt02Offer" placeholder="Texto de beneficios" type="text" value={this.state.txt02Offer} onChange={this.manejadorCambios}/>
													</div>
													<div className="form-group">
														<label>Ingresa un texto de beneficios </label>
														<input className="form-control" name="txt03Offer" placeholder="Texto de beneficios" type="text" value={this.state.txt03Offer} onChange={this.manejadorCambios}/>
													</div>
													<div className="form-group">
														<label>Ingresa un texto de beneficios </label>
														<input className="form-control" name="txt04Offer" placeholder="Texto de beneficios" type="text" value={this.state.txt04Offer} onChange={this.manejadorCambios}/>
													</div>
													<br/>
													<h4>Sub oferta</h4>
													<div className="form-group">
														<label>Ingresa el titulo Para una sub oferta</label>
														<input className="form-control" name="titleOfferSubOffer" placeholder="Titulo de oferta" type="text" value={this.state.titleOfferSubOffer} onChange={this.manejadorCambios}/>
													</div>
													<div className="form-group">
														<label>Valor en dll de tu oferta</label>
														<input className="form-control" name="amountOfferSubOffer" placeholder="Valor en dll" type="number" value={this.state.amountOfferSubOffer} onChange={this.manejadorCambios}/>
													</div>
													<div className="form-group">
														<label>Cuandos dias otorga tu oferta (valor numero ejemplo 6 = a 6 dias) </label>
														<input className="form-control" name="daysOfferSubOffer" placeholder="Dias de oferta" type="number" value={this.state.daysOfferSubOffer} onChange={this.manejadorCambios}/>
													</div>
												</div>
											</div>
											<br/>
											<div className="col col-md-12">
												<button onClick={() => this.validaFormOferta()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Crear oferta</button>
											</div>
										</div>
									</div>
								</div>
								<br/>
							</div>
						</div>
					</div>
				</div>
				<div className="content">
					<h3 className="">Lista de ofertas:</h3>
				</div>
				<div className="content">
					<div className=" card-primary">
						<div className="row">
							{this.state.offerList.map(data => (
								<div className="col col-md-6" key={data.offerID}>
									<div>
										<FichaOffert data={data}/>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FormNewOffers;


import React, { Component } from 'react';
import { auth, firestore } from "../js/firebase";
import Moment from 'moment';

class Register extends Component {
    constructor(props) {
      super(props);
      this.state = {
        email: "",
        password: "",
        nameWriter: '',
        //////estado del usuario
        user: null,
      };
      this.changeManagement = this.changeManagement.bind(this);
    }
    changeManagement(event) {
      const name = event.target.name;
      this.setState({
        [name]: event.target.value
      });
    }

    signUp = () => {
        auth.createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(user => {
          ///// jalar los datos
          var idUser = user.user.uid;
          var emailUser = user.user.email;
          console.log(user.user.uid)
          //// cadena a guardar
          firestore.collection("users").doc(idUser)
          .set({
              //string
              id: idUser,
              //string
              email: emailUser,
              //timestamp
              timestamp: Moment().toDate(),
              //string
              name: this.state.nameWriter,
              //null
              userPic: null,
              //null
              authorData: null,
              //null
              type: "admin"
            })
            //// Mensaje de aprovacion limpiar formulario
            .then(function(docRef) {
              console.log(docRef);
            })
            .catch(function(error) {
              console.error("Error adding document: ", error);
            });
            this.props.history.push("/Panel")

          })

        .catch(error => {
          console.log(error)
          if( error.code === "auth/invalid-email" ){
            alert('Email no valido');
          }
          if( error.code === "auth/email-already-in-use" ){
            alert('La dirección de correo electrónico ya está en uso');
          }
          if( error.code === "auth/weak-password" ){
            alert('La contraseña debe tener al menos 6 caracteres');
          }
        })
      }

      regresar = () => {
          this.props.history.push("/")
      }

    render() {
      return (
        <div className="login-box">
          <div className="login-logo">
            <a href="../../index2.html"><b>PATBOOKS -</b> ESCRITORES</a>
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Registrate</p>
              <form action="#" onSubmit={e => e.preventDefault()}>

                <div className="input-group mb-3">
                  <input name="nameWriter"  type="text" className="form-control" placeholder="Nombre" value={this.state.nameWriter} onChange={this.changeManagement}/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user"></span>
                    </div>
                  </div>
                </div>

                <div className="input-group mb-3">
                  <input type="email" className="form-control" placeholder="Email" onChange={event => this.setState({email: event.target.value})}/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>

                <div className="input-group mb-3">
                  <input type="password" className="form-control" placeholder="Password"/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>

                <div className="input-group mb-3">
                  <input type="password" className="form-control" placeholder="Confirmar password" onChange={event => this.setState({password: event.target.value})}/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <button  onClick={this.signUp}  className="btn btn-primary btn-block btn-flat">Registrate</button>
                  </div>
                </div>
              </form>
            </div>
            <div>
              <div className="card-body login-card-body col-6">
                <button  onClick={this.regresar}  className="btn btn-primary btn-block btn-flat">Regresar</button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  export default Register;

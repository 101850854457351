import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import swal from "sweetalert2";
import Moment from "moment";

///// Componente para armar la pantlla
class FormNewAutor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editar: false,
			autorAModificar: "",
			autoresLista: props.dataGeneral.listaAutores,

			/////pathbook lista
			activeAutor: false,

			bioENAutor: "",
			bioESAutor: "",
			bioDEAutor: "",
			bioFRAutor: "",
			bioITAutor: "",
			bioPTAutor: "",

			linkAutor: "",
			modifiedAutor: "",
			nameAutor: "",
			nationalityENAutor: "",
			nationalityESAutor: "",
			nationalityDEAutor: "",
			nationalityFRAutor: "",
			nationalityITAutor: "",
			nationalityPTAutor: "",

			pictureAutor: "",

			titleENAutor: "",
			titleESAutor: "",
			titleDEAutor: "",
			titleFRAutor: "",
			titleITAutor: "",
			titlePTAutor: "",

			selectAutores: [],
			autorSeleccionado: "",
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
		this.manejadorImputs = this.manejadorImputs.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	async manejadorImputs(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name =  target.name;
		await this.setState({
			[name]: value,
		});
	}

	validaAutorExiste() {
		if (!this.state.autorSeleccionado) {
			swal.fire({title: "Seleciona un autor existente", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let generoID = this.state.autorSeleccionado.split(" -   --> ");
			firestore.doc("authors/" + generoID[1]).get().then((documentSnapshot) => {
				let dataAutor = documentSnapshot;
				if(dataAutor.exists === true){
					this.setState({autorAModificar: generoID[1]});
					this.llenaDatosSelecionados(dataAutor.data());
				}
				else{
					this.limpiarForm();
					swal.fire({title: "Ese autor no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
			})
		}
	}

	async llenaDatosSelecionados(datos) {
		await this.setState({
			editar: true
		});

		let activoAutor = false
		if(datos.active){
			activoAutor = datos.active
		}

		this.setState({
			activeAutor: activoAutor,
			bioENAutor: datos.bio["en-US"],
			bioESAutor: datos.bio["es-MX"],
			linkAutor: datos.link,
			modifiedAutor: datos.bio,
			nameAutor: datos.name,
			nationalityENAutor: datos.nationality["en-US"],
			nationalityESAutor: datos.nationality["es-MX"],
			pictureAutor: datos.nationality,
			titleENAutor: datos.title["en-US"],
			titleESAutor: datos.title["es-MX"],
		});

		if(datos.title["en"]){
			this.setState({
				titleENAutor: datos.title["en"],
			});
		}if(datos.title["es"]){
			this.setState({
				titleESAutor: datos.title["es"]
			});
		}
		if(datos.title["de"]){
			this.setState({
				titleDEAutor: datos.title["de"]
			});
		}
		if(datos.title["fr"]){
			this.setState({
				titleFRAutor: datos.title["fr"]
			});
		}
		if(datos.title["it"]){
			this.setState({
				titleITAutor: datos.title["it"]
			});
		}
		if(datos.title["pt"]){
			this.setState({
				titlePTAutor: datos.title["pt"]
			});
		}

		if(datos.nationality["en"]){
			this.setState({
				nationalityENAutor: datos.nationality["en"]
			});
		}
		if(datos.nationality["es"]){
			this.setState({
				nationalityESAutor: datos.nationality["es"]
			});
		}
		if(datos.nationality["de"]){
			this.setState({
				nationalityDEAutor: datos.nationality["de"]
			});
		}
		if(datos.nationality["fr"]){
			this.setState({
				nationalityFRAutor: datos.nationality["fr"]
			});
		}
		if(datos.nationality["it"]){
			this.setState({
				nationalityITAutor: datos.nationality["it"]
			});
		}
		if(datos.nationality["pt"]){
			this.setState({
				nationalityPTAutor: datos.nationality["pt"]
			});
		}


		if(datos.bio["en"]){
			this.setState({
				bioENAutor: datos.bio["en"]
			});
		}
		if(datos.bio["es"]){
			this.setState({
				bioESAutor: datos.bio["es"]
			});
		}
		if(datos.bio["de"]){
			this.setState({
				bioDEAutor: datos.bio["de"]
			});
		}
		if(datos.bio["fr"]){
			this.setState({
				bioFRAutor: datos.bio["fr"]
			});
		}
		if(datos.bio["it"]){
			this.setState({
				bioITAutor: datos.bio["it"]
			});
		}
		if(datos.bio["pt"]){
			this.setState({
				bioPTAutor: datos.bio["pt"]
			});
		}
	}

	validaAuthor() {
		if (!this.state.autorCarrusel) {
			swal.fire({title: "Agrega un Autor", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let authorID = this.state.autorCarrusel.split(" -   --> ");
			//////evaluamos que el autor exista
			 firestore.doc("authors/" + authorID[1]).get().then((documentSnapshot) => {
				let dataAuthor = documentSnapshot;
				 if(dataAuthor.exists === true){
					 this.setState({autorCarruselChk: "authors/" + dataAuthor.id});
					 this.validaFormAutor();
				 } else {
					 swal.fire({title: "Ese autor no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				 }
			})
		}
	}

	validaFormAutor() {
		if (!this.state.nameAutor) {
			swal.fire({title: "Ingresa un nombre", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.bioENAutor) {
			swal.fire({title: "Ingresa la biografia en Ingles", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.bioESAutor) {
			swal.fire({title: "Ingresa la biografia en Español", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.bioDEAutor) {
			swal.fire({title: "Ingresa la biografia en Aleman", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.bioFRAutor) {
			swal.fire({title: "Ingresa la biografia en Frances", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.bioITAutor) {
			swal.fire({title: "Ingresa la biografia en Italiano", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.bioPTAutor) {
			swal.fire({title: "Ingresa la biografia en Portuges", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.nationalityENAutor) {
			swal.fire({title: "Ingresa la nacionalidad en Ingles", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.nationalityESAutor) {
			swal.fire({title: "Ingresa la nacionalidad en Español", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.nationalityDEAutor) {
			swal.fire({title: "Ingresa la nacionalidad en Aleman", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.nationalityFRAutor) {
			swal.fire({title: "Ingresa la nacionalidad en Frances", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.nationalityITAutor) {
			swal.fire({title: "Ingresa la nacionalidad en Italiano", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else if (!this.state.nationalityPTAutor) {
			swal.fire({title: "Ingresa la nacionalidad en Portuges", text: "Necesario para esta ficha", type: "error", confirmButtonText: "Entendido"});
		} else   {
		 	this.guardaDatos();
		}
	}

	///guardamos os datos en la bd
	guardaDatos() {
		if (this.state.editar === false){
	// 		////obtenemos los datos del formulario
			firestore.collection("authors").doc().set({
				active: this.state.activeAutor,
				added: {
					timestamp: Moment().toDate(),
					user: firestore.doc("users/CMk4wSMR47XFHKZ1zY2yerH9Wp42"),
				},
				bio: {
					"en-US": this.state.bioENAutor,
					"es-MX": this.state.bioESAutor,
					"en": this.state.bioENAutor,
					"es": this.state.bioESAutor,
					"de": this.state.bioDEAutor,
					"fr": this.state.bioFRAutor,
					"it": this.state.bioITAutor,
					"pt": this.state.bioPTAutor,
				},
				deleted: null,
				link: this.state.linkAutor,
				modified: null,
				name: this.state.nameAutor,
				nationality: {
					"en-US": this.state.nationalityENAutor,
					"es-MX": this.state.nationalityESAutor,
					"en": this.state.nationalityENAutor,
					"es": this.state.nationalityESAutor,
					"de": this.state.nationalityDEAutor,
					"fr": this.state.nationalityFRAutor,
					"it": this.state.nationalityITAutor,
					"pt": this.state.nationalityPTAutor,
				},
				picture: "https://livingabook.com/LoadPathbooksAndMore/assets/img/featureNull.jpg",
				title: {
					"en-US": this.state.titleENAutor,
					"es-MX": this.state.titleESAutor,
					"en": this.state.titleENAutor,
					"es": this.state.titleESAutor,
					"de": this.state.titleDEAutor,
					"fr": this.state.titleFRAutor,
					"it": this.state.titleITAutor,
					"pt": this.state.titlePTAutor,
				},
			});
		} else {
	 		let autorId = this.state.autorAModificar
	 		firestore.collection("authors").doc(autorId).update({
			    active: this.state.activeAutor,
			    bio: {
				    "en-US": this.state.bioENAutor,
				    "es-MX": this.state.bioESAutor,
				    "en": this.state.bioENAutor,
				    "es": this.state.bioESAutor,
				    "de": this.state.bioDEAutor,
				    "fr": this.state.bioFRAutor,
				    "it": this.state.bioITAutor,
				    "pt": this.state.bioPTAutor,
			    },
			    deleted: null,
			    link: this.state.linkAutor,
			    modified: Moment().toDate(),
			    name: this.state.nameAutor,
			    nationality: {
				    "en": this.state.nationalityENAutor,
				    "es": this.state.nationalityESAutor,
				    "de": this.state.nationalityDEAutor,
				    "fr": this.state.nationalityFRAutor,
				    "it": this.state.nationalityITAutor,
				    "pt": this.state.nationalityPTAutor,
			    },
			    title: {
				    "en-US": this.state.titleENAutor,
				    "es-MX": this.state.titleESAutor,
				    "en": this.state.titleENAutor,
				    "es": this.state.titleESAutor,
				    "de": this.state.titleDEAutor,
				    "fr": this.state.titleFRAutor,
				    "it": this.state.titleITAutor,
				    "pt": this.state.titlePTAutor,
			    },
			});
		}
		this.limpiarForm();
	}

	limpiarForm() {
		this.setState({
			editar: false,
			autorAModificar: "",
			/////pathbook lista
			activeAutor: false,
			bioENAutor: "",
			bioESAutor: "",
			bioDEAutor: "",
			bioFRAutor: "",
			bioITAutor: "",
			bioPTAutor: "",
			linkAutor: "",
			modifiedAutor: "",
			nameAutor: "",
			nationalityENAutor: "",
			nationalityESAutor: "",
			nationalityDEAutor: "",
			nationalityFRAutor: "",
			nationalityITAutor: "",
			nationalityPTAutor: "",
			pictureAutor: "",

			titleENAutor: "",
			titleESAutor: "",
			titleDEAutor: "",
			titleFRAutor: "",
			titleITAutor: "",
			titlePTAutor: "",

			autorSeleccionado: "",
		});
		swal.fire({title: "Felicidades", text: "Guardado con exito", type: "error", confirmButtonText: "Entendido"});
	}

	async componentDidMount() {
		///////llenamos la lista de los Autores
		let selectAutoresList = [];
		await this.state.autoresLista.forEach((task) => {
				let idAutor = task.doc.id;
				let nombreAutor = task.doc.data().name;
				selectAutoresList.push({id: idAutor, nombre: nombreAutor});
		});
		this.setState({selectAutores: selectAutoresList});
	}


	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header">
					<h2 className="panel-title">Autores</h2>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Informacion de Autor</h3>
						</div>

						<div className="d-flex">
							<div className="card-body">
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<div className="container">
													<label>Si quieres editar un autor buscalo aqui:</label>
													<div className="row">
														<input className="form-control" list="selectAutor" id="generoSelected" name="autorSeleccionado" type="select" placeholder="Elige un autor a modificar" value={this.state.autorSeleccionado} onChange={this.manejadorCambios}/>
														<datalist id="selectAutor">
															{this.state.selectAutores.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
														</datalist>
													</div>

												</div>
												<br/>
												<div className="form-group">
													{/*validaBuscadorPathbook*/}
													<button onClick={() => this.validaAutorExiste()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Seleccionar autor</button>
												</div>

											</div>
										</div>
									</div>
								</div>

							</div>
						</div>

						<div className="d-flex">
							<div className="card-body">

								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<label>Activar:</label>
												<div className="form-check">
													<input name="activeAutor" type="checkbox" checked={this.state.activeAutor} onChange={this.manejadorImputs}/>
													<label className="form-check-label"></label>
												</div>
												<br/>
												<h6>Campo requerido pra usar en mas formularios</h6>
											</div>
											<div className="form-group">
												<label>Titulo del autor</label>
												<input className="form-control" name="titleENAutor" placeholder="Titulo Ingles" type="text" value={this.state.titleENAutor} onChange={this.manejadorCambios}/>
												<input className="form-control" name="titleESAutor" placeholder="Titulo Español" type="text" value={this.state.titleESAutor} onChange={this.manejadorCambios}/>
												<input className="form-control" name="titleDEAutor" placeholder="Titulo Aleman" type="text" value={this.state.titleDEAutor} onChange={this.manejadorCambios}/>
												<input className="form-control" name="titleFRAutor" placeholder="Titulo Frances" type="text" value={this.state.titleFRAutor} onChange={this.manejadorCambios}/>
												<input className="form-control" name="titleITAutor" placeholder="Titulo Italiano" type="text" value={this.state.titleITAutor} onChange={this.manejadorCambios}/>
												<input className="form-control" name="titlePTAutor" placeholder="Titulo Portugues" type="text" value={this.state.titlePTAutor} onChange={this.manejadorCambios}/>
											</div>
											<div className="form-group">
												<label>Nombre del autor</label>
												<input className="form-control" name="nameAutor" placeholder="Nombre del autor" type="text" value={this.state.nameAutor} onChange={this.manejadorCambios}/>
												<span>Campo obligatorio</span>
											</div>
											<div className="form-group">
												<label>Link del autor</label>
												<input className="form-control" name="linkAutor" placeholder="Link a su URL" type="text" value={this.state.linkAutor} onChange={this.manejadorCambios}/>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-group">
												<label>Sumario - Biografia - Sinopsis</label>
												<textarea className="form-control" name="bioENAutor" placeholder="Biografia Ingles" value={this.state.bioENAutor} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="bioESAutor" placeholder="Biografia Español" value={this.state.bioESAutor} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="bioDEAutor" placeholder="Biografia Aleman" value={this.state.bioDEAutor} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="bioFRAutor" placeholder="Biografia Frances" value={this.state.bioFRAutor} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="bioITAutor" placeholder="Biografia Italiano" value={this.state.bioITAutor} onChange={this.manejadorCambios}/>
												<textarea className="form-control" name="bioPTAutor" placeholder="Biografia Portugues" value={this.state.bioPTAutor} onChange={this.manejadorCambios}/>
												<span>Campo obligatorio</span>
											</div>
											<div className="form-group">
												<label>Nacionalidad</label>
												<input className="form-control" name="nationalityENAutor" placeholder="Nacionalidad Ingles" type="text" value={this.state.nationalityENAutor} onChange={this.manejadorCambios}/>
												<input className="form-control" name="nationalityESAutor" placeholder="Nacionalidad Español" type="text" value={this.state.nationalityESAutor} onChange={this.manejadorCambios}/>
												<input className="form-control" name="nationalityDEAutor" placeholder="Nacionalidad Aleman" type="text" value={this.state.nationalityDEAutor} onChange={this.manejadorCambios}/>
												<input className="form-control" name="nationalityFRAutor" placeholder="Nacionalidad Frances" type="text" value={this.state.nationalityFRAutor} onChange={this.manejadorCambios}/>
												<input className="form-control" name="nationalityITAutor" placeholder="Nacionalidad Italiano" type="text" value={this.state.nationalityITAutor} onChange={this.manejadorCambios}/>
												<input className="form-control" name="nationalityPTAutor" placeholder="Nacionalidad Portugues" type="text" value={this.state.nationalityPTAutor} onChange={this.manejadorCambios}/>
												<span>Campo obligatorio</span>
											</div>

											<br/>
											<div className="form-group">
												{
													!this.state.autorAModificar
														? (<div>
															<br/>
															<label>Agrega tu nuevo pathbook</label>
															<br/>
															<button onClick={() => this.validaFormAutor()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Agregar</button>
														</div>)
														:
														<div>
															<button onClick={() => this.validaFormAutor()} type="button" id="buttonPathbook" className="btn btn-warning btn-lg btn-block">Modificar</button>
														</div>
												}

												{/*validaBuscadorPathbook*/}
											</div>
										</div>
									</div>
								</div>
								<br/>
							</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

export default FormNewAutor;


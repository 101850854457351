import React from "react";
import Moment from "moment";
import { firestore } from "../js/firebase";
import swal from "sweetalert2";

///// Componente para armar la pantlla
class GeneraCupones extends React.Component {
  constructor() {
    super();
    this.state = {
      codigoCupon: "",
      consultaCupon: "",
      diasCupon: 0,
      infoCode: "",
      infoDays: "",
      infoExpired: "",
      infoUsed: "",
      infoUser: "",
      infoActive: ""
    };
    this.manejadorCambios = this.manejadorCambios.bind(this);
  }

  manejadorCambios(event) {
    const name = event.target.name;
    this.setState({[name]: event.target.value});
  }
  manejadorImputs1() {
    this.setState({diasCupon: 30});
  }
  manejadorImputs2() {
    this.setState({diasCupon: 90});
  }
  manejadorImputs3() {
    this.setState({diasCupon: 365});
  }
  manejadorImputs4() {
    this.setState({diasCupon: 7});
  }
  manejadorImputs5() {
    this.setState({diasCupon: 15});
  }
  manejadorImputs6() {
    this.setState({diasCupon: 90});
  }
  manejadorImputs7() {
    this.setState({diasCupon: 14600});
  }

  validaFormCupon() {
    if (this.state.diasCupon === 0) {
      ////alerta de error
      swal.fire({title: "Selecciona una duración", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
    } else {
      if (this.state.codigoCupon === "") {
        ////alerta de error
        swal.fire({title: "Agregar minimo un cupón", text: "para continuar", type: "error", confirmButtonText: "Entendido"});
      } else {
        this.guardaDatos();
        // this.borraCupones();
      }
    }
  }
  limpiarForm() {
    this.setState({codigoCupon: ""});
  }
  ///guardamos os datos en la bd
  guardaDatos() {
    //////obtenemos los datos del formulario
    let codigoVar = this.state.codigoCupon;
    let fecha = Moment().add(4, "year");
    //////esta linea detecta los cupones separados por , y espacio y guarda uno por uno
    const doubled = codigoVar.split(", ")

    doubled.map((codigoData) => firestore.collection("codes").doc(codigoData).set({code: codigoData, days: this.state.diasCupon, expires: fecha.toDate(), timestamp: Moment().toDate()}).then(function(docRef) {
      swal.fire({title: "Todo perfecto", text: "Tus cupones fueron genrados", type: "error", confirmButtonText: "OK"});
    }).catch(function(error) {
      ////alerta de error
      swal.fire({
        title: "Error al generar los cupones",
        text: "Codigo de error:" + error,
        type: "error",
        confirmButtonText: "Entendido"
      });
    }),);
    this.limpiarForm();
  }
  borraCupones() {
    //////obtenemos los datos del formulario
    let codigoVar = this.state.codigoCupon;
    //////esta linea detecta los cupones separados por , y espacio y guarda uno por uno
    const doubled = codigoVar.split(", ")

    doubled.map((codigoData) => firestore.collection("codes").doc(codigoData).delete().then(function(docRef) {
      swal.fire({title: "Todo borrado", text: "Tus cupones fueron genrados", type: "error", confirmButtonText: "OK"});
    }).catch(function(error) {
      ////alerta de error
      swal.fire({
        title: "Error al generar los cupones",
        text: "Codigo de error:" + error,
        type: "error",
        confirmButtonText: "Entendido"
      });
    }),);
    this.limpiarForm();
  }

  consultaCupon() {
    if (this.state.consultaCupon === "") {
      swal.fire({title: "Ingresa un cupon", text: "para realizar la busqueda agrega un cupon", type: "error", confirmButtonText: "Entendido"});
    } else {
      firestore.doc("codes/" + this.state.consultaCupon).get().then((documentSnapshot) => {
        let dataCupon = documentSnapshot.data();
        if (dataCupon.used) {
          // convertimos fechas de timesTamp a fechas a mostrar
          let dateExpired = Moment(dataCupon.expires.toDate()).format('lll');
          let dateUsed = Moment(dataCupon.used.toDate()).format('lll');
          // cargamos variables
          this.setState({infoCode: dataCupon.code});
          this.setState({infoDays: dataCupon.days});
          this.setState({infoExpired: dateExpired});
          this.setState({infoUsed: dateUsed});
          this.setState({infoUser: dataCupon.user.id});
          this.setState({infoActive: "activado"});
        } else {
          // convertimos fechas de timesTamp a fechas a mostrar
          let dateExpired = Moment(dataCupon.expires.toDate()).format('lll');
          // cargamos variables
          this.setState({infoCode: dataCupon.code});
          this.setState({infoDays: dataCupon.days});
          this.setState({infoExpired: dateExpired});
          this.setState({infoActive: "iactivo"});
        }
      }).catch(function(error) {
        ////alerta de error
        swal.fire({title: "Cupon no existe ", text: "En la base de datos no tenemos este cupon:", type: "error", confirmButtonText: "Entendido"});
      })
    }
  }

  // componentDidMount(){
  // }
  render() {
    return (<div className="content-wrapper">
      <div className="content-header d-flex">
        <h2 className="panel-title">Cupones</h2>
      </div>
      <div className="content-header d-flex">
        {/* form cupon */}
        <div className="container-fluid">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Genera cupones</h3>
            </div>
            <div>

              <div className="card-body">
                {/* seleciona una opcion */}
                <div className="form-group">
                  <label>Selecciona una duración
                  </label>
                  <br/>
                  <span>
                    La duracion es dias y es para que un usuario este activo</span>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs4()}/>
                      <label className="form-check-label">7 Dias</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs5()}/>
                      <label className="form-check-label">15 Dias</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs1()}/>
                      <label className="form-check-label">Un mes</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs2()}/>
                      <label className="form-check-label">3 meces</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs6()}/>
                      <label className="form-check-label">6 meces</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs3()}/>
                      <label className="form-check-label">Un año</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs7()}/>
                      <label className="form-check-label">40 Años</label>
                    </div>

                  </div>
                </div>
                {/* campo normal */}
                <div className="form-group">
                  <label>Selecciona una duración
                  </label>
                  <label>Cupon</label>
                  <input className="form-control" id="exampleInputPassword1" name="codigoCupon" placeholder="CUPON" type="text" value={this.state.codigoCupon} onChange={this.manejadorCambios}/>
                  <br/>
                  <span>Puedes ingresar un cupon o mas de uno siempre y cuando este separado por una coma y un espacio(, )</span>
                </div>

              </div>

              <div className="card-footer">
                <button onClick={() => this.validaFormCupon()} type="button" id="buttonFeature" className="btn btn-primary btn-lg btn-block">Agregar</button>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Consulta cupones</h3>
            </div>
            <div>
              <div className="card-body">
                {/* campo normal */}
                <div className="form-group">
                  <label>Cupon</label>
                  <input className="form-control" name="consultaCupon" placeholder="CUPON" type="text" value={this.state.consultaCupon} onChange={this.manejadorCambios}/>
                </div>
              </div>
              <div className="card-footer">
                <button onClick={() => this.consultaCupon()} type="button" className="btn btn-primary btn-lg btn-block">Consultar</button>
              </div>
              {
                this.state.infoActive === "activado"
                  ? <div className="card-body">
                      <div className="card-header bg-success">
                        <h3 className="card-title ">
                          <i className="nav-icon fas fa-ticket-alt"/>
                          <span>
                            Estatus cupon: Activado</span>
                        </h3>
                      </div>
                      <div className="card-body">
                        <div>
                          <span>
                            Codigo de cupon:
                          </span>
                        </div>
                        <h4>
                          <span>{this.state.infoCode}</span>
                        </h4>
                        <div>
                          <span>
                            Dias de que dura la activacion:
                          </span>
                        </div>
                        <h4>
                          <span>{this.state.infoDays}
                            Dias</span>
                        </h4>
                        <div>
                          <span>
                            Dia de expiracion del cupon:
                          </span>
                        </div>
                        <h4>
                          <span>{this.state.infoExpired}</span>
                        </h4>
                        <div>
                          <span>
                            Dias que se uso:
                          </span>
                        </div>
                        <h4>
                          <span>{this.state.infoUsed}</span>
                        </h4>
                        <div>
                          <span>
                            Clave usuario:
                          </span>
                        </div>
                        <h4>
                          <span>{this.state.infoUser}</span>
                        </h4>
                      </div>
                    </div>
                  : null
              }
              {
                this.state.infoActive === "iactivo"
                  ? <div className="card-body">
                      <div className="card-header bg-warning">
                        <h3 className="card-title ">
                          <i className="nav-icon fas fa-ticket-alt"/>
                          <span>
                            Estatus cupon: Inactivo</span>
                        </h3>
                      </div>
                      <div className="card-body">
                        <div>
                          <span>
                            Codigo de cupon:
                          </span>
                        </div>
                        <h4>
                          <span></span>
                          <span>
                            {this.state.infoCode}</span>
                        </h4>
                        <div>
                          <span>
                            Dias de que dura la activacion:
                          </span>
                        </div>
                        <h4>
                          <span></span>
                          <span>
                            {this.state.infoDays}
                            Dias</span>
                        </h4>
                        <div>
                          <span>
                            Dia de expiracion del cupon:
                          </span>
                        </div>
                        <h4>
                          <span></span>
                          <span>
                            {this.state.infoExpired}</span>
                        </h4>
                      </div>
                    </div>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

export default GeneraCupones;

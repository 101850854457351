import React, {Component} from "react";
import {firestore} from "../../js/firebase";

///// Componente para armar la pantlla
class FichaPathbookControl extends Component {
	constructor(props) {
		super();
		this.state = {
			imagenNull: "https://livingabook.com/LoadPathbooksAndMore/assets/img/portadaNull.jpg",
			pathbook: props.data.pathbook.data(),
			pathbookID: props.data.pathbook.id,
			activeStatus: props.data.pathbook.data().active,
			visibleStatus: props.data.pathbook.data().visible,
			coverEn: "",
			coverEs: "",
			feature: "",
		};
	}

	activarPathbook() {
		if(!this.state.activeStatus){
			firestore.collection("pathbooks").doc(this.state.pathbookID).update({
				active: true,
			});
			this.setState({activeStatus: true})
		}
		else{
			firestore.collection("pathbooks").doc(this.state.pathbookID).update({
				active: false,
			});
			this.setState({activeStatus: false})
		}
	}
	visiblePathbook() {
		if(!this.state.visibleStatus){
			firestore.collection("pathbooks").doc(this.state.pathbookID).update({
				visible: true,
			});
			this.setState({visibleStatus: true})
		}
		else{
			firestore.collection("pathbooks").doc(this.state.pathbookID).update({
				visible: false,
			});
			this.setState({visibleStatus: false})
		}
	}

	async componentDidMount() {
		if (this.state.pathbook.cover["en"] === null) {
			this.setState({coverEn: this.state.imagenNull});
		} else {
			this.setState({coverEn: this.state.pathbook.cover["en"]});
		}

		if (this.state.pathbook.cover["es"] === null) {
			this.setState({coverEs: this.state.imagenNull});
		} else {
			this.setState({coverEs: this.state.pathbook.cover["es"]});
		}

		if (this.state.pathbook.featureImage === null) {
			this.setState({feature: this.state.imagenNull});
		} else {
			this.setState({feature: this.state.pathbook.featureImage});
		}
	}

	render() {
		// console.log(this.state.pathbook)
		return (
			<div className="col card">
				<br/>
				<div className="row">
					<div className="col-2 col-md-3">
						<h6>Titulo EN:</h6>
						<h4>{this.state.pathbook.title["en"]}</h4>
						<h6>Titulo ES:</h6>
						<h4>{this.state.pathbook.title["es"]}</h4>
					</div>
					<div className="col-2 col-md-1">
						<h6>Portada EN</h6>
						<img src={this.state.coverEn} alt="feature" width="80"/>
					</div>
					<div className="col-2 col-md-1">
						<h6>Portada ES</h6>
						<img src={this.state.coverEs} alt="feature" width="80"/>
					</div>
					<div className="col-2 col-md-2">
						<h6>Features</h6>
						<img src={this.state.feature} alt="feature" width="160" height="60"/>
					</div>
					<div className="col-2 col-md-2">
						{
							!this.state.activeStatus
								? (<div>
									<h6>No esta activo</h6>
									<button onClick={() => this.activarPathbook()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Activar</button>
								</div>)
								:
								<div>
									<h6>Si esta activo</h6>
									<button onClick={() => this.activarPathbook()} type="button" id="buttonPathbook" className="btn btn-warning btn-lg btn-block">Desactivar</button>
								</div>
						}
					</div>
					<div className="col-2 col-md-2">
						{
							!this.state.visibleStatus
								? (<div>
									<h6>No esta visible</h6>
									<button onClick={() => this.visiblePathbook()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Visible</button>
								</div>)
								:
								<div>
									<h6>Si esta visible</h6>
									<button onClick={() => this.visiblePathbook()} type="button" id="buttonPathbook" className="btn btn-warning btn-lg btn-block">No Visible</button>
								</div>
						}

					</div>
				</div>
				<br/>
			</div>
		);
	}
}

export default FichaPathbookControl;

import React, {Component} from "react";
import {storage, firestore} from "../../js/firebase";
import swal from "sweetalert2";

///// Componente para armar la pantlla
class FormImgAutor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			autoresLista: props.dataGeneral.listaAutores,
			autorsInfo: null,
			autorsNombre: "",
			selectAutores: [],
			/////pathbook lista

			urlIni: "https://firebasestorage.googleapis.com/v0/b/pathbook.appspot.com/o/authors%2F",
			urlmid: "%2Fimages%2F",
			urlEnd: "?alt=media&token=f13da64f-51f5-45a5-a90c-1e21e013258e",

			fotoAutor: null,
			barraLoad1: 0,
			barraLoadEnd1: 1000,
			message1: "Seleciona un archivo .JPG para cargar",

			autorSeleccionado: "",

			// autorsInfo: null,
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	async validaAutorExiste() {
		if (!this.state.autorSeleccionado) {
			swal.fire({title: "Seleciona un autor existente", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let generoID = this.state.autorSeleccionado.split(" -   --> ");
			firestore.doc("authors/" + generoID[1]).get().then((documentSnapshot) => {
				let dataAutor = documentSnapshot;

				if(dataAutor.exists === true){
					this.setState({autorsInfo: dataAutor});
					 this.llenaDatosSelecionados(dataAutor.data());
				}

				else{
					this.limpiarForm();
					swal.fire({title: "Ese autor no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
				console.log(dataAutor)
			})
		}
	}

	async llenaDatosSelecionados(datos) {
		await this.setState({
			editar: true,
			name: datos.name,
			fotoAutor: datos.picture,
		});
	}

	cargaPortadaEn(event) {
		if (!this.state.autorSeleccionado) {
			swal.fire({
				title: "Selecciona un pathbook",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else {
			const namePict = "picture.png";
			const file = event.target.files[0];
			const storageRef = storage.ref("authors/" + this.state.autorsInfo.id + "/images").child(namePict);
			const task = storageRef.put(file);
			if (task.snapshot.totalBytes < 550000) {
				task.on("state_changed", (snapshot) => {
					this.setState({
						barraLoadEnd1: snapshot.totalBytes,
						barraLoad1: snapshot.bytesTransferred,
					});

				}, (error) => {
					this.setState({
						message1: "Error: " + error,
					});
				}, () => {
					this.setState({
						message1: "Cargado correctamente",
						fotoAutor: this.state.urlIni + this.state.autorsInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
					});
					this.guarda();
				});
			} else {
				swal.fire({
					title: "Imagen demaciado grande",
					text: "reduce a 500 KB maximo para continuar ",
					type: "error",
					confirmButtonText: "Entendido",
				});
			}

		}
	}

	async guarda() {
		await firestore.collection("authors").doc(this.state.autorsInfo.id).update({
			picture: this.state.fotoAutor,
		});
		swal.fire({
			title: "Guardado con exito",
			text: "",
			type: "error",
			confirmButtonText: "Entendido",
		});
	}

	async componentDidMount() {
		///////llenamos la lista de los Autores
		let selectAutoresList = [];
		await this.state.autoresLista.forEach((task) => {
			let idAutor = task.doc.id;
			let nombreAutor = task.doc.data().name;
			selectAutoresList.push({id: idAutor, nombre: nombreAutor});
		});

		this.setState({selectAutores: selectAutoresList});
	}


	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header">
					<h2 className="panel-title">Imagenes de cada autor</h2>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Informacion de Pathbook</h3>
						</div>

						<div className="d-flex">
							<div className="card-body">
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<div className="container">
													<label>Si quieres editar un autor buscalo aqui:</label>
													<div className="row">
														<input className="form-control" list="selectAutor" id="generoSelected" name="autorSeleccionado" type="select" placeholder="Elige un autor a modificar" value={this.state.autorSeleccionado} onChange={this.manejadorCambios}/>
														<datalist id="selectAutor">
															{this.state.selectAutores.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
														</datalist>
													</div>

												</div>
												<br/>
												<div className="form-group">
													{/*validaBuscadorPathbook*/}
													<button onClick={() => this.validaAutorExiste()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Seleccionar autor</button>
												</div>

											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="d-flex">
							<div className="card-body">
								{
									!this.state.editar
										? (<div>
											<label>Selecciona un autor:</label>
										</div>)
										:
										<div>
											<label>Autor selecionado</label>
											<div className="container">
												<div className="row">
													<div className="col-sm card-header">
														<h6>Portada Ingles</h6>
														<div className="row row-inputs">
															<progress value={this.state.barraLoad1} max={this.state.barraLoadEnd1}/>
														</div>
														<div className="row row-inputs">
															<br/>
															<div className="input-group mb-3">
																<div className="custom-file">
																	<input type="file" accept="image/png" className="custom-file-input" onChange={this.cargaPortadaEn.bind(this)}/>
																	<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .PNG</label>
																</div>
															</div>
															<div className="input-group mb-3">
																{this.state.message1}
															</div>
															<br/>
															<img src={this.state.fotoAutor} alt="manual" width="160" height="160"/>
															<br/>
														</div>
														<br/>
														<a target="_blank" href={this.state.fotoAutor} rel="noopener noreferrer"><h5>Descarga foto de autor</h5></a>
														<br/>
													</div>
												</div>
											</div>
										</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);

	}
}

export default FormImgAutor;


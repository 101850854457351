import React, {Component} from "react";
///// Componente para armar la pantlla
class FichaAudioInterior extends Component {
	constructor(props) {
		super();
		this.state = {
			audioDir: props.data
		};
	}
	render() {
		return (
			<div className="card">
				<div className="col-md-12">
					<div className="  widget-user">
						<label >Archivo:</label><br/>
						<h6>{this.state.audioDir.file}</h6><br/>
						<label >Link audio:</label><br/>
						<h6>{this.state.audioDir.url}</h6><br/>
						<label >Referencia audio:</label><br/>
						<h6>{this.state.audioDir.reference}</h6><br/>
						<a download="PSD" href={this.state.audioDir.url}><h5>Descarga Audio</h5></a>
					</div>
				</div>
			</div>
		);
	}
}

export default FichaAudioInterior;

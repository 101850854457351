import React, {Component} from "react";
import {firestore, fieldValue} from "../../js/firebase";

class FichaEliminaPathbook extends Component {
	constructor(props) {
		super();
		this.state = {
			task: props.task,
			nombrePathbook: "",
			nombrePathbookEn: "",
			statusBoton: "",
		};
	}

	borrarPathbook() {
		// console.log(this.state.task.dbLocation + "/" + this.state.task.idCarucel +  "/pathbooks"+this.state.task.documentID)
		let referenciaPathbook = firestore.doc("pathbooks/"+this.state.task.idPathbook)
		let docRef = firestore.doc(this.state.task.dbLocation + "/" + this.state.task.idCarucel)

		docRef.update({
			pathbooks: fieldValue.arrayRemove(referenciaPathbook)
		});
		this.setState({statusBoton: "borrado"})
	}

	async componentDidMount() {
		firestore.doc("pathbooks/" + this.state.task.idPathbook).get().then((documentSnapshot) => {
			let dataAdmin = documentSnapshot.data();
			this.setState({nombrePathbook: dataAdmin.title["es"], nombrePathbookEn: dataAdmin.title["en"]});
		});
	}

	render() {
		return (
			<div className="col-sm-12">
				{
					this.state.statusBoton === ""
						? (<div className="row">
							<div className="col-sm-2 ">
								<div className="description-block">
									<button onClick={() => this.borrarPathbook()} type="button" className="btn btn-block bg-gradient-danger">X </button>
								</div>
							</div>
							<div className="col-sm-9 ">
								<h4>{this.state.nombrePathbook}</h4>
								<h4>{this.state.nombrePathbookEn}</h4><br/>
							</div>
						</div>)
						: null
				}
			</div>
		);
	}
}

export default FichaEliminaPathbook;

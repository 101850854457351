import React, {Component} from "react";
import {functions, firestore} from "../../js/firebase";
import swal from "sweetalert2";
import Moment from "moment";

///// Componente para armar la pantlla
class AppSchools extends Component {
	constructor(props) {
		super(props);
		this.state = {
			idEscuela: 0,
			nombreEcuela: "",
			numLicenciasEscuelas: "",
			fechaEnd:"",
			estadoEscuelas: "",
			ciudadEscuelas: "",
			estatusForm: 2,
			nombreRespEscuelas: "",
			email: "",
			password: "",
			nombreRepresentante: "",
			empresaRepresentante: "null",
			schoolList: [],

		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}


	validaFormFeature() {

		if (!this.state.nombreRepresentante) {
			swal.fire({
				title: "Ingresa un representante",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (this.state.empresaRepresentante === "null") {
			swal.fire({
				title: "Ingresa la empresa",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.nombreEcuela) {
			swal.fire({
				title: "Ingresa un Nombre para la escuela",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.fechaEnd) {
			swal.fire({
				title: "ingresa una fecha de caducidad del plan",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.numLicenciasEscuelas) {
			swal.fire({
				title: "Ingresa la cantidad de licencias",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.estadoEscuelas) {
			swal.fire({
				title: "Ingresa un estado",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.ciudadEscuelas) {
			swal.fire({
				title: "Ingresa una ciudad",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else {
			this.guardaDatos();

		}
	}

	limpiarForm() {
		this.setState({
			numLicenciasEscuelas: "",
			nombreEcuela: "",
			estadoEscuelas: "",
			ciudadEscuelas: "",
			nombreRespEscuelas: "",
			telephoneGeneral: 0,
			email: "",
			password: "",
			nombreRepresentante: "",
			empresaRepresentante: "null",
		});
		swal.fire({
			title: "Guardado con exito",
			text: "Buen trabajo",
			type: "error",
			confirmButtonText: "OK",
		});
	}

	async guardaDatos() {
		////obtenemos los datos del formulario
		const creaEscuela = await firestore.collection("schools").add({
			principal: null,
			name: this.state.nombreEcuela,
			telephoneGeneral: this.state.telephoneGeneral,
			telephoneContact: null,
			nameContact: null,
			country: "Mexico",
			state: this.state.estadoEscuelas,
			city: this.state.ciudadEscuelas,
			logo: null,
			licenses: parseInt(this.state.numLicenciasEscuelas),
			licensesRemaining: parseInt(this.state.numLicenciasEscuelas),
			nombreRepresentante: this.state.nombreRepresentante,
			empresaRepresentante: this.state.empresaRepresentante,
			timestamp: Moment().toDate(),
			expires: new Date(this.state.fechaEnd),
			active: true
		});

		const crearDirector = await functions.httpsCallable("school-createPrincipal");

		crearDirector({
			name: this.state.nombreRespEscuelas,
			email: this.state.email,
			password: this.state.password,
			school: creaEscuela.id,
		})
			.then()
			.catch();
		this.limpiarForm();

	}

	signUp = async () => {

	};

	async componentDidMount() {
		firestore.collection("schools").onSnapshot(async querySnapshot => {
			this.setState({idEscuela: querySnapshot.size});
		});
		///////llenamos la lista de los features
		let listaData = [];
		// const features = await firestore.collection("features").get();
		firestore.collection("schools").onSnapshot(querySnapshot => {
			///esta variable evita que se dupliquen las consultas
			listaData = [];
			let schools = querySnapshot.docs;
			schools.forEach((doc) => {
				listaData.push({
					idSchool: doc.id,
					city: doc.data().city,
					country: doc.data().country,
					licences: doc.data().licences,
					licencesRemaining: doc.data().licencesRemaining,
					logo: doc.data().logo,
					name: doc.data().name,
					state: doc.data().state,
					principal: doc.data().principal,
					active: doc.data().active,
					expires: doc.data().expires,
					telephoneGeneral: doc.data().telephoneGeneral,
					nameContact: doc.data().nameContact,

				});
			});
			//////ordenamos la lista por prioridad (priority)
			listaData.sort(function (a, b) {
				return a.priority - b.priority;
			});
			this.setState({schoolList: listaData});
		});
		///////llenamos la lista de los features


	}

	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header d-flex">
					<h2 className="panel-title">Escuelas</h2>
				</div>

				<div className="content-header">
					<div className="container-fluid">
						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Crear nueva Escuela</h3>
							</div>
							<div>
								<div className="card-body">
									<h3>Datos de escuela</h3>
									<div className="form-group">

									</div>
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<label>Nombre del representante</label>
												<input className="form-control" name="nombreRepresentante" placeholder="Nombre del representante" type="text" value={this.state.nombreRepresentante} onChange={this.manejadorCambios}/>
												<span>Agregar un representante</span>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-group">

												<label>Empresa</label>
												<select className="form-control" name="empresaRepresentante" value={this.state.empresaRepresentante} onChange={this.manejadorCambios}>
													<option value="null">Selecciona uno</option>
													<option value="pathbooks">pathbooks</option>
													<option value="books2go">books2go</option>
													<option value="Educare">Educare</option>
												</select>
												<span>Ingresar el pasword del responsable</span>
											</div>
										</div>
									</div>
									<div className="form-group">
										<label>Nombre de la escuela</label>
										<input className="form-control" name="nombreEcuela" placeholder="Nombre de la escuela" type="text" value={this.state.nombreEcuela} onChange={this.manejadorCambios}/>
										<span>Agregar un nombre para la escuela</span>
									</div>

									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<label>Cantidad de licencias para la escuela</label>
												<input className="form-control" name="numLicenciasEscuelas" placeholder="Licencias (numero)" type="number" value={this.state.numLicenciasEscuelas} onChange={this.manejadorCambios}/>
												<span>Ingresar un numero que indique que tanta prioridad tendra el feature en el carrucel</span>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-group">
												<label>Fecha final:</label>
												<input className="form-control" name="fechaEnd" type="date" value={this.state.fechaEnd} onChange={this.manejadorCambios}/>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<label>Estado</label>
												<input className="form-control" name="estadoEscuelas" placeholder="Estado de la escuela" type="text" value={this.state.estadoEscuelas} onChange={this.manejadorCambios}/>
												<span>Agregar un estado</span>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-group">
												<label>Ciudad</label>
												<input className="form-control" name="ciudadEscuelas" placeholder="Ciudad de la escuela" type="text" value={this.state.ciudadEscuelas} onChange={this.manejadorCambios}/>
												<span>Agregar una ciudad es obligatoria</span>
											</div>
										</div>
									</div>

								</div>
							</div>
							<div>
								<div className="card-body">
									<h3>Datos del responsable de la escuela</h3>
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<label>Nombre del responsable</label>
												<input className="form-control" name="nombreRespEscuelas" placeholder="Nombre del responsable/Director" type="text" value={this.state.nombreRespEscuelas} onChange={this.manejadorCambios}/>
												<span>Agregar nombre de responsable</span>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-group">
												<label>Email del responsable</label>
												<input type="email" className="form-control" name="email" placeholder="Email" value={this.state.email} onChange={this.manejadorCambios}/>
												<span>Agregar nombre de responsable</span>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<label>Telefono del responsable</label>
												<input type="Tel" className="form-control" name="telephoneGeneral" placeholder="Telefono del responsable/Director"  value={this.state.telephoneGeneral} onChange={this.manejadorCambios}/>
												<span>Agregar nombre de responsable</span>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-group">
												<label>Password</label>
												<input className="form-control" name="password" placeholder="Password" type="Password" value={this.state.password} onChange={this.manejadorCambios}/>
												<span>Ingresar el pasword del responsable</span>
											</div>
										</div>
									</div>

									<div className="card-footer">
										<button onClick={() => this.validaFormFeature()} type="button" id="buttonFeature" className="btn btn-primary btn-lg btn-block">Continuar</button>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div className="content-header d-flex">
					<div className="container-fluid">
						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Lista de features Pathbooks</h3>
							</div>
							<div>
								<div className="card-body">
									{this.state.schoolList.map(data => (
										<div key={data.idSchool}>
											<div>{data.name}</div>
											{/*<FichaFeatures data={data}/>*/}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

export default AppSchools;

import React, { Component } from "react";
///// Componente para armar la pantlla
class RepPathbooks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pathbooks: props.listaPathbooks,
			pathbookList: [],
			activado: ""
		};
	}
	componentDidMount(){
		let pathbook = []
		this.state.pathbooks.forEach((doc) => {
			const data = doc.doc.data()
			console.log(data)
			pathbook.push(data);
			this.setState({pathbookList: pathbook})
			console.log(this.state.pathbookList)
			// if (doc.data().active === false){
			// 	pathbookNoActivados.push({pathbook: doc});
			// } else if (doc.data().visible === false){
			// 	pathbookNoActivados.push({pathbook: doc});
			// } else if (!doc.data().visible){
			// 	pathbookNoActivados.push({pathbook: doc});
			// } else {
			// 	pathbookActivados.push({pathbook: doc});
			// }

		});
		this.setState({
			// pathbooksNoActivados: pathbookNoActivados,
			// pathbooksActivados: pathbookActivados,
		});
	}

	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header d-flex">
					<h2 className="panel-title">Usuarios Regiistrados</h2>
				</div>
				<div className="content-header d-flex">
					<div>
						<button onClick={() => this.buscaUsuarios()} type="button" id="buttonCarrusel" className="btn btn-primary btn-lg btn-block">Buscar</button>
					</div>
					<progress value={this.state.consecutivo} max={this.state.valorBarraLoad}/>
				</div>

				<div className="content-header d-flex">
					<div className="container-fluid">

						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Lista de Usuarios registrados: {this.state.valorBarraLoad}</h3>
								<h3 className="card-title">analizado: {this.state.consecutivo}</h3>
							</div>
							<div>
								<table className="table">
									<thead className="thead-dark">
									<tr>
										<th scope="col" width="40">Clasificacion</th>
										<th scope="col" width="130">Titulo</th>
										<th scope="col" width="60">Idiomas</th>
										<th scope="col" width="60">Sumario</th>


									</tr>
									</thead>
									<tbody>
									{this.state.pathbookList.map(data => (
										<tr key={data.title.es}>
											<td>{data.classification}</td>
											<td width="300">
												<div>{data.title.es}</div>
											</td>
											<td width="50">
												{
													data.translations
														? (<div>
															{data.translations[0]}&nbsp;&nbsp;
															{data.translations[1]}&nbsp;&nbsp;
															{data.translations[2]}&nbsp;&nbsp;
															{data.translations[3]}&nbsp;&nbsp;
															{data.translations[4]}&nbsp;&nbsp;
															{data.translations[5]}&nbsp;&nbsp;
														</div>)
														: null
												}

											</td>
											<td>{data.summary.es}</td>

											{/*<td><a href={data.urlGenrado} target="_blank">Abrir</a></td>*/}
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RepPathbooks;

import React, {Component} from "react";
import {storage, firestore} from "../../js/firebase";
import swal from "sweetalert2";
import Moment from "moment";


class FormPortadasPathbook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pathbooksLista: props.dataGeneral.listaPathbooks,
			selectPathbooks: [],
			/////pathbook lista

			urlIni: "https://firebasestorage.googleapis.com/v0/b/pathbook.appspot.com/o/pathbooks%2F",
			urlmid: "%2Fcovers%2F",
			urlmidExtra: "%2Fadditional%2F",
			urlEnd: "?alt=media",
			imagenNull: "https://livingabook.com/LoadPathbooksAndMore/assets/img/portadaNull.jpg",

			portadaAltaEn: null,
			barraLoad4: 0,
			barraLoadEnd4: 1000,
			message4: "Seleciona un archivo .JPG para cargar",

			portadaImgEn: null,
			barraLoad1: 0,
			barraLoadEnd1: 1000,
			message1: "Seleciona un archivo .JPG para cargar",

			portadaImgEs: null,
			barraLoad2: 0,
			barraLoadEnd2: 1000,
			message2: "Seleciona un archivo .JPG para cargar",

			featureImg: null,
			barraLoad3: 0,
			barraLoadEnd3: 1000,
			message3: "Seleciona un archivo .JPG para cargar",

			portadaAltaEs: null,
			barraLoad5: 0,
			barraLoadEnd5: 1000,
			message5: "Seleciona un archivo .JPG para cargar",

			portadaPsd: null,
			barraLoad6: 0,
			barraLoadEnd6: 1000,
			message6: "Seleciona un archivo .PSD para cargar",

			portadaImgAudioEn: null,
			barraLoadAudio1: 0,
			barraLoadEndAudio1: 1000,
			messageAudio1: "Seleciona un archivo .JPG para cargar",

			portadaImgAudioEs: null,
			barraLoadAudio2: 0,
			barraLoadEndAudio2: 1000,
			messageAudio2: "Seleciona un archivo .JPG para cargar",

			pathbookSeleccionado: "",
			pathbookSelecDatos: [],
			pathbooksInfo: null,
			descargasEstatus: null,

		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}
	limpiarVars(){
		this.setState({
			portadaAltaEn: null,
			barraLoad4: 0,
			barraLoadEnd4: 1000,
			message4: "Seleciona un archivo .JPG para cargar",

			portadaImgEn: null,
			barraLoad1: 0,
			barraLoadEnd1: 1000,
			message1: "Seleciona un archivo .JPG para cargar",

			portadaImgEs: null,
			barraLoad2: 0,
			barraLoadEnd2: 1000,
			message2: "Seleciona un archivo .JPG para cargar",

			featureImg: null,
			barraLoad3: 0,
			barraLoadEnd3: 1000,
			message3: "Seleciona un archivo .JPG para cargar",

			portadaAltaEs: null,
			barraLoad5: 0,
			barraLoadEnd5: 1000,
			message5: "Seleciona un archivo .JPG para cargar",

			portadaPsd: null,
			barraLoad6: 0,
			barraLoadEnd6: 1000,
			message6: "Seleciona un archivo .PSD para cargar",

			portadaImgAudioEn: null,
			barraLoadAudio1: 0,
			barraLoadEndAudio1: 1000,
			messageAudio1: "Seleciona un archivo .JPG para cargar",

			portadaImgAudioEs: null,
			barraLoadAudio2: 0,
			barraLoadEndAudio2: 1000,
			messageAudio2: "Seleciona un archivo .JPG para cargar",
		})
	}
	async validaPathbooksExiste() {
		this.limpiarVars()
		if (!this.state.pathbookSeleccionado) {
			swal.fire({title: "Seleciona un pathbook existente", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let pathbookId = this.state.pathbookSeleccionado.split(" -   --> ");
			await firestore.doc("pathbooks/" + pathbookId[1]).get().then((documentSnapshot) => {
				let datapathbook = documentSnapshot;
				if (datapathbook.exists === true) {
					this.setState({pathbooksInfo: datapathbook});

					///PSD
					if (datapathbook.data().imageFiles) {
					if (datapathbook.data().imageFiles.portadaPsd) {
						this.setState({
							portadaPsd: datapathbook.data().imageFiles.portadaPsd,
							descargasEstatus: "ok",
						});
					}
					} else {
						this.setState({
							portadaPsd: null,
							descargasEstatus: null,
						});
					}

					///portada En alta
					if (datapathbook.data().imageFiles) {
					if (datapathbook.data().imageFiles.portadaEn) {
						this.setState({
							portadaAltaEn: datapathbook.data().imageFiles.portadaEn,
						});
					}
					} else {
						this.setState({
							portadaAltaEn: this.state.imagenNull,
						});
					}

					///portada Es
					if (datapathbook.data().cover["en"]) {
						this.setState({
							portadaImgEn: datapathbook.data().cover["en"],
						});
					} else {
						this.setState({
							portadaImgEn: this.state.imagenNull,
						});
					}

					///portada Es alta
					if (datapathbook.data().imageFiles) {
						if (datapathbook.data().imageFiles.portadaEs) {
							this.setState({
								portadaAltaEs: datapathbook.data().imageFiles.portadaEs,
							});
						}
					} else {
						this.setState({
							portadaAltaEs: this.state.imagenNull,
						});
					}


					if (datapathbook.data().cover["es"]) {
						this.setState({
							portadaImgEs: datapathbook.data().cover["es"],
						});
					} else {
						this.setState({
							portadaImgEs: this.state.imagenNull,
						});
					}

					if (datapathbook.data().featureImage) {
						this.setState({
							featureImg: datapathbook.data().featureImage,
						});
					} else {
						this.setState({
							featureImg: this.state.imagenNull,
						});
					}
					///audio
					if (datapathbook.data().audiobookCover) {
						if (datapathbook.data().audiobookCover["en-US"]) {
							this.setState({
								portadaImgAudioEn: datapathbook.data().audiobookCover["en-US"],
							});
						} else {
							this.setState({
								portadaImgAudioEn: this.state.imagenNull,
							});
						}
						if (datapathbook.data().audiobookCover["es-MX"]) {
							this.setState({
								portadaImgAudioEs: datapathbook.data().audiobookCover["es-MX"],
							});
						} else {
							this.setState({
								portadaImgAudioEs: this.state.imagenNull,
							});
						}
					}

				} else {
					swal.fire({title: "Ese pathbook no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
			});

		}
	}

	alertaTamanioElevado() {
		swal.fire({
			title: "Imagen demaciado grande",
			text: "reduce a 200 KB maximo para continuar ",
			type: "error",
			confirmButtonText: "Entendido",
		});
	}

	alertaTamanioBajo() {
		swal.fire({
			title: "Imagen demaciado chica",
			text: "necesario mayor a 300 KB minimo para continuar ",
			type: "error",
			confirmButtonText: "Entendido",
		});
	}

	cargaAltaEn(event) {
		const namePict = "altaEn" + Moment().format();
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id + "/additional").child(namePict + ".jpg");
		const task = storageRef.put(file);
		if (task.snapshot.totalBytes > 100000) {
			task.on("state_changed", (snapshot) => {
				this.setState({
					barraLoadEnd4: snapshot.totalBytes,
					barraLoad4: snapshot.bytesTransferred,
				});

			}, (error) => {
				this.setState({
					message4: "Error: " + error,
				});
			}, () => {
				this.setState({
					message4: "Cargado correctamente",
					portadaAltaEn: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmidExtra + task.snapshot.metadata.name + this.state.urlEnd,
				});
				this.guarda();
			});
		} else {
			this.alertaTamanioBajo();
		}
	}

	cargaPortadaEn(event) {
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id + "/covers").child("coverEN.jpg");
		const task = storageRef.put(file);
		if (task.snapshot.totalBytes < 600000) {
			task.on("state_changed", async (snapshot) => {
				this.setState({
					barraLoadEnd1: snapshot.totalBytes,
					barraLoad1: snapshot.bytesTransferred,
				});
			}, (error) => {
				this.setState({
					message1: "Error: " + error,
				});
			}, async () => {
				await this.setState({
					message1: "Cargado correctamente",
					portadaImgEn: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
				});
				this.guarda();
			});
		} else {
			this.alertaTamanioElevado();
		}
	}

	cargaAltaEs(event) {
		const namePict = "altaEn" + Moment().format();
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id + "/additional").child(namePict + ".jpg");
		const task = storageRef.put(file);
		if (task.snapshot.totalBytes > 100000) {
			task.on("state_changed", (snapshot) => {
				this.setState({
					barraLoadEnd5: snapshot.totalBytes,
					barraLoad5: snapshot.bytesTransferred,
				});

			}, (error) => {
				this.setState({
					message5: "Error: " + error,
				});
			}, () => {
				this.setState({
					message5: "Cargado correctamente",
					portadaAltaEs: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmidExtra + task.snapshot.metadata.name + this.state.urlEnd,
				});
				this.guarda();
			});
		} else {
			this.alertaTamanioBajo();
		}
	}

	cargaPortadaEs(event) {
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id + "/covers").child("coverES.jpg");
		const task = storageRef.put(file);
		if (task.snapshot.totalBytes < 600000) {
			task.on("state_changed", (snapshot) => {
				this.setState({
					barraLoadEnd2: snapshot.totalBytes,
					barraLoad2: snapshot.bytesTransferred,
				});
			}, (error) => {
				this.setState({
					message2: "Error: " + error,
				});
			}, () => {
				this.setState({
					message2: "Cargado correctamente",
					portadaImgEs: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
				});
				this.guarda();
			});
		} else {
			this.alertaTamanioElevado();
		}
	}

	cargaFeature(event) {
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id + "/covers").child("feature.jpg");
		const task = storageRef.put(file);
		if (task.snapshot.totalBytes < 700000) {
			task.on("state_changed", (snapshot) => {
				this.setState({
					barraLoadEnd3: snapshot.totalBytes,
					barraLoad3: snapshot.bytesTransferred,
				});
			}, (error) => {
				this.setState({
					message3: "Error: " + error,
				});
			}, () => {
				this.setState({
					message3: "Cargado correctamente",
					featureImg: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
				});
				this.guarda();
			});
		} else {
			this.alertaTamanioElevado();
		}
	}

	///audio Ingles
	cargaPortadaAudioEn(event) {
		if (!this.state.pathbookSeleccionado) {
			swal.fire({
				title: "Selecciona un pathbook",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else {
			const file = event.target.files[0];
			const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id + "/covers").child("coverAudioEN.jpg");
			const task = storageRef.put(file);

			if (task.snapshot.totalBytes < 500000) {
				task.on("state_changed", (snapshot) => {
					this.setState({
						barraLoadEndAudio1: snapshot.totalBytes,
						barraLoadAudio1: snapshot.bytesTransferred,
					});

				}, (error) => {
					this.setState({
						messageAudio1: "Error: " + error,
					});
				}, () => {
					this.setState({
						messageAudio1: "Cargado correctamente",
						portadaImgAudioEn: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
					});
					this.guarda();
				});
			} else {
				swal.fire({
					title: "Imagen demaciado grande",
					text: "reduce a 200 KB maximo para continuar ",
					type: "error",
					confirmButtonText: "Entendido",
				});
			}
		}
	}

	///audio espaniol
	cargaPortadaAudioEs(event) {
		if (!this.state.pathbookSeleccionado) {
			swal.fire({
				title: "Selecciona un pathbook",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else {
			const file = event.target.files[0];
			const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id + "/covers").child("coverAudioES.jpg");
			const task = storageRef.put(file);

			if (task.snapshot.totalBytes < 500000) {
				task.on("state_changed", (snapshot) => {
					this.setState({
						barraLoadEndAudio2: snapshot.totalBytes,
						barraLoadAudio2: snapshot.bytesTransferred,
					});

				}, (error) => {
					this.setState({
						messageAudio2: "Error: " + error,
					});
				}, () => {
					this.setState({
						messageAudio2: "Cargado correctamente",
						portadaImgAudioEs: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
					});
					this.guarda();
				});

			} else {
				swal.fire({
					title: "Imagen demaciado grande",
					text: "reduce a 200 KB maximo para continuar ",
					type: "error",
					confirmButtonText: "Entendido",
				});
			}

		}
	}


	cargaPsd(event) {
		if (!this.state.pathbookSeleccionado) {
			swal.fire({
				title: "Selecciona un pathbook",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else {
			const namePict = "PSD.psd";
			const file = event.target.files[0];
			const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id + "/additional").child(namePict);
			const task = storageRef.put(file);

			task.on("state_changed", (snapshot) => {
				this.setState({
					barraLoadEnd6: snapshot.totalBytes,
					barraLoad6: snapshot.bytesTransferred,
				});

			}, (error) => {
				this.setState({
					message6: "Error: " + error,
				});
			}, () => {
				this.setState({
					message6: "Cargado correctamente",
					portadaPsd: this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmidExtra + task.snapshot.metadata.name + this.state.urlEnd,
				});
				this.guarda();
			});
		}
	}


	async guarda() {
		if (this.state.portadaImgEn === this.state.imagenNull) {
			await this.setState({portadaImgEn: null});
		}
		if (this.state.portadaImgEs === this.state.imagenNull) {
			await this.setState({portadaImgEs: null});
		}
		if (this.state.featureImg === this.state.imagenNull) {
			await this.setState({featureImg: null});
		}
		if (this.state.portadaAltaEn === this.state.imagenNull) {
			await this.setState({portadaAltaEn: null});
		}
		if (this.state.portadaAltaEs === this.state.imagenNull) {
			await this.setState({portadaAltaEs: null});
		}
		if (this.state.portadaImgAudioEn === this.state.imagenNull) {
			await this.setState({portadaImgAudioEn: null});
		}
		if (this.state.portadaImgAudioEs === this.state.imagenNull) {
			await this.setState({portadaImgAudioEs: null});
		}
		await firestore.collection("pathbooks").doc(this.state.pathbooksInfo.id).update({
			cover: {
				"en": this.state.portadaImgEn,
				"es": this.state.portadaImgEs,
				"en-US": this.state.portadaImgEn,
				"es-MX": this.state.portadaImgEs,
			},
			featureImage: this.state.featureImg,
			imageFiles: {
				portadaEn: this.state.portadaAltaEn,
				portadaEs: this.state.portadaAltaEs,
				portadaPsd: this.state.portadaPsd,
			},
			audiobookCover: {
				"en": this.state.portadaImgAudioEn,
				"es": this.state.portadaImgAudioEs,
				"en-US": this.state.portadaImgAudioEn,
				"es-MX": this.state.portadaImgAudioEs,
			},
		});
		this.validaPathbooksExiste();
	}

	async componentDidMount() {
		/////lista de pathbooks
		let selectPathbookList = [];
		await this.state.pathbooksLista.forEach((task) => {
			let idPathbook = task.doc.id;
			let nombrePathbook = task.doc.data().title["es"];
			selectPathbookList.push({id: idPathbook, nombre: nombrePathbook});
		});
		this.setState({selectPathbooks: selectPathbookList});
	}


	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header">
					<h2 className="panel-title">Portadas de Pathbook</h2>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Busca Pathbook</h3>
						</div>

						<div className="d-flex">
							<div className="card-body">
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<div className="container">
													<label>Si quieres editar portadas a un pathbook buscalo aqui:</label>
													<div className="row">
														<input className="form-control" list="selectPathbook" id="generoSelected" name="pathbookSeleccionado" type="select" placeholder="Elige un pathbook a modificar" value={this.state.pathbookSeleccionado} onChange={this.manejadorCambios}/>
														<datalist id="selectPathbook">
															{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
														</datalist>
													</div>
												</div>
												<br/>
												<div className="form-group">
													{/*validaBuscadorPathbook*/}
													<button onClick={() => this.validaPathbooksExiste()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Seleccionar pathbook</button>
												</div>

											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div className="d-flex">
							<div className="card-body">
								{
									!this.state.pathbooksInfo
										? (<div>
											<label>Selecciona un pathbook:</label>
										</div>)
										:
										<div>
											<label>Pathbook selecionado</label>
											<h3>{this.state.pathbooksInfo.data().title["es"]}</h3>
											<div className="container">
												<div className="row">

													<div className="col-sm card-header">
														<h6>PSD editable</h6>
														<div className="row row-inputs">
															<progress value={this.state.barraLoad6} max={this.state.barraLoadEnd6}/>
														</div>
														<div className="row row-inputs">
															<br/>
															<div className="input-group mb-3">
																<div className="custom-file">
																	<input type="file" accept="image/psd" className="custom-file-input" onChange={this.cargaPsd.bind(this)}/>
																	<label className="custom-file-label" htmlFor="inputGroupFile02">Tiopo PSD</label>
																</div>
															</div>
															<div className="input-group mb-3">
																{this.state.message6}
															</div>
															<br/>
															{
																this.state.descargasEstatus === "ok"
																	? (
																		<div className="col-sm">
																			<a download="PSD" href={this.state.portadaPsd}><h5>Descarga PSD</h5></a>
																			<br/>
																		</div>
																	)
																	:
																	null
															}
														</div>
													</div>

													<div className="col-sm card-header">
														{
															this.state.portadaPsd
																?
																(<div>
																	<h6>Alta calidad ingles</h6>
																	<div className="row row-inputs">
																		<progress value={this.state.barraLoad4} max={this.state.barraLoadEnd4}/>
																	</div>
																	<div className="row row-inputs">
																		<br/>
																		<div className="input-group mb-3">
																			<div className="custom-file">
																				<input type="file" accept="image/jpg" className="custom-file-input" onChange={this.cargaAltaEn.bind(this)}/>
																				<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .JPG</label>
																			</div>
																		</div>
																		<div className="input-group mb-3">
																			{this.state.message4}
																		</div>
																		<br/>
																		<img src={this.state.portadaAltaEn} alt="feature" width="100" height="160"/>
																		<br/>
																	</div>
																	<br/>
																	<a target="_blank" href={this.state.portadaAltaEn} rel="noopener noreferrer"><h5>Descarga portada alta</h5></a>
																	<br/>
																</div>)
																: null
														}
													</div>
													<div className="col-sm card-header">
														{
															this.state.portadaAltaEn === this.state.imagenNull
																? null
																:
																(<div>
																	<h6>Portada Ingles</h6>
																	<div className="row row-inputs">
																		<progress value={this.state.barraLoad1} max={this.state.barraLoadEnd1}/>
																	</div>
																	<div className="row row-inputs">
																		<br/>
																		<div className="input-group mb-3">
																			<div className="custom-file">
																				<input type="file" accept="image/jpg" className="custom-file-input" onChange={this.cargaPortadaEn.bind(this)}/>
																				<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .JPG</label>
																			</div>
																		</div>
																		<div className="input-group mb-3">
																			{this.state.message1}
																		</div>
																		<br/>
																		<img src={this.state.portadaImgEn} alt="manual" width="100" height="160"/>
																		<br/>
																	</div>
																	<br/>
																	<a target="_blank" href={this.state.portadaImgEn} rel="noopener noreferrer"><h5>Descarga portada</h5></a>
																	<br/>
																</div>)
														}
													</div>
												</div>

												<div className="row">

													<div className="col-sm card-header">
														{
															this.state.portadaImgEn === this.state.imagenNull
																? null
																:
																(<div>
																	<h6>Alta calidad español</h6>
																	<div className="row row-inputs">
																		<progress value={this.state.barraLoad5} max={this.state.barraLoadEnd5}/>
																	</div>
																	<div className="row row-inputs">
																		<br/>
																		<div className="input-group mb-3">
																			<div className="custom-file">
																				<input type="file" accept="image/jpg" className="custom-file-input" onChange={this.cargaAltaEs.bind(this)}/>
																				<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .JPG</label>
																			</div>
																		</div>
																		<div className="input-group mb-3">
																			{this.state.message5}
																		</div>
																		<br/>
																		<img src={this.state.portadaAltaEs} alt="feature" width="100" height="160"/>
																		<br/>
																	</div>
																	<br/>
																	<a target="_blank" href={this.state.portadaAltaEs} rel="noopener noreferrer"><h5>Descarga portada alta</h5></a>
																	<br/>
																	<br/>
																</div>)
														}
													</div>

													<div className="col-sm card-header">
														{
															this.state.portadaAltaEs === this.state.imagenNull
																? null
																:
																(<div>
																	<h6>Portada Español</h6>
																	<div className="row row-inputs">
																		<progress value={this.state.barraLoad2} max={this.state.barraLoadEnd2}/>
																	</div>
																	<div className="row row-inputs">
																		<br/>
																		<div className="input-group mb-3">
																			<div className="custom-file">
																				<input type="file" accept="image/jpg" className="custom-file-input" onChange={this.cargaPortadaEs.bind(this)}/>
																				<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .JPG</label>
																			</div>
																		</div>
																		<div className="input-group mb-3">
																			{this.state.message2}
																		</div>
																		<br/>
																		<img src={this.state.portadaImgEs} alt="manual" width="100" height="160"/>
																	</div>
																	<br/>
																	<a target="_blank" href={this.state.portadaImgEs} rel="noopener noreferrer"><h5>Descarga portada</h5></a>
																	<br/>
																</div>)
														}
													</div>


													<div className="col-sm card-header">
														{
															this.state.portadaImgEs === this.state.imagenNull
																? null
																:
																(<div>
																	<h6>Feature</h6>
																	<div className="row row-inputs">
																		<progress value={this.state.barraLoad3} max={this.state.barraLoadEnd3}/>
																	</div>
																	<div className="row row-inputs">
																		<br/>
																		<div className="input-group mb-3">
																			<div className="custom-file">
																				<input type="file" accept="image/jpg" className="custom-file-input" onChange={this.cargaFeature.bind(this)}/>
																				<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .JPG</label>
																			</div>
																		</div>
																		<div className="input-group mb-3">
																			{this.state.message3}
																		</div>
																		<br/>
																		<img src={this.state.featureImg} alt="feature" width="166" height="58"/>
																		<br/>
																	</div>
																	<br/>
																	<a target="_blank" href={this.state.featureImg} rel="noopener noreferrer"><h5>Descarga feature</h5></a>
																	<br/>
																</div>)
														}
													</div>
												</div>
												<div>
													{
														this.state.featureImg === this.state.imagenNull
															? null
															:
															(<div className="row">
																<div className="col-sm card-header">
																	<h6>Portada Audio Ingles</h6>
																	<div className="row row-inputs">
																		<progress value={this.state.barraLoadAudio1} max={this.state.barraLoadEndAudio1}/>
																	</div>
																	<div className="row row-inputs">
																		<br/>
																		<div className="input-group mb-3">
																			<div className="custom-file">
																				<input type="file" accept="image/jpg" className="custom-file-input" onChange={this.cargaPortadaAudioEn.bind(this)}/>
																				<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .JPG</label>
																			</div>
																		</div>
																		<div className="input-group mb-3">
																			{this.state.messageAudio1}
																		</div>
																		<br/>
																		<img src={this.state.portadaImgAudioEn} alt="manual" width="160" height="160"/>
																		<br/>
																	</div>
																	<br/>
																	<a target="_blank" href={this.state.portadaImgAudioEn} rel="noopener noreferrer"><h5>Descarga portada</h5></a>
																	<br/>
																</div>
																<div className="col-sm card-header">
																	<h6>Portada Audio Español</h6>
																	<div className="row row-inputs">
																		<progress value={this.state.barraLoadAudio2} max={this.state.barraLoadEndAudio2}/>
																	</div>
																	<div className="row row-inputs">
																		<br/>
																		<div className="input-group mb-3">
																			<div className="custom-file">
																				<input type="file" accept="image/jpg" className="custom-file-input" onChange={this.cargaPortadaAudioEs.bind(this)}/>
																				<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .JPG</label>
																			</div>
																		</div>
																		<div className="input-group mb-3">
																			{this.state.messageAudio2}
																		</div>
																		<br/>
																		<img src={this.state.portadaImgAudioEs} alt="manual" width="160" height="160"/>
																	</div>
																	<br/>
																	<a target="_blank" href={this.state.portadaImgAudioEs} rel="noopener noreferrer"><h5>Descarga portada</h5></a>
																	<br/>
																</div>
															</div>)
													}
												</div>


											</div>
										</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);

	}
}

export default FormPortadasPathbook;


import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import swal from "sweetalert2";

///// Componente para armar la pantlla
class RepCommission extends Component {
	constructor(props) {
		super();
		this.state = {
			pathbooksLista: props.listaPathbooks,
			listaComisionista: [],
			valorBarraLoad: 0,

			metUsuariosTotal: 0,
			metPatbooksIni: 0,
			metPatbooksEnd: 0,
			metPatbooksPag: 0,
			metPatbooksEve: 0,

			metPatbooksIniVe: 0,
			metPatbooksEndVe: 0,
			metPatbooksPagVe: 0,
			metPatbooksEveVe: 0,

			pullDll: 0,

			consecutivo: 0,
			consecutivoPull: 0,
			offerList: [],

			fechaIni: "",
			fechaEnd: "",
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	async calculoComisiones() {

		if (!this.state.fechaIni) {
			swal.fire({
				title: "ingresa una fecha inicial",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.fechaEnd) {
			swal.fire({
				title: "ingresa una fecha final",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else {
			let listaData = [];
			let fechaMinima = new Date(this.state.fechaIni);
			let fechaMaxima = new Date(this.state.fechaEnd);

			await this.state.offerList.forEach((doc) => {
				listaData = []
				let sumaDeCapital = 0
				let sumaContador = 0
				firestore.collection("receipts").where("id","==",doc.offerID).onSnapshot(async querySnapshot => {
					let commissioners = querySnapshot.docs
					await commissioners.forEach((dataConsulta) => {
					if((dataConsulta.data().timestamp.seconds * 1000) >= fechaMinima.getTime()){
							if((dataConsulta.data().timestamp.seconds * 1000) <= fechaMaxima.getTime()){
								if(dataConsulta.data().paypalAmount === doc.amount){
									sumaDeCapital = sumaDeCapital + dataConsulta.data().paypalAmount
									sumaContador = sumaContador + 1
								}
							}
						}
					})
					await listaData.push({
						offerID: doc.offerID,
						sumaDeCapital: sumaDeCapital,
						percentage: doc.percentage,
						nombreCommissioner: doc.name,
						divisa: doc.divisa,
						sumaContador: sumaContador,
					});
					////ordenamos la lista
					await listaData.sort(function (a, b) {
						return b.totalEvents - a.totalEvents;
					});
					//////sacamos la lista
					this.setState({listaComisionista: listaData});
				})
			})
		}
	}

	//////// async funcion asincrona para poder usar await
	async componentDidMount() {
		///////llenamos la
		let listaData = [];
		await firestore.collection("affiliate").onSnapshot(querySnapshot => {
			///esta variable evita que se dupliquen las consultas
			listaData = []
			let commissioners = querySnapshot.docs
			commissioners.forEach((doc) => {
				listaData.push({
					offerID: doc.data().txtLink,
					name: doc.data().name,
					contIniciados: doc.data().contIniciados,
					contPagados: doc.data().contPagados,
					contRegUsuarios: doc.data().contRegUsuarios,
					email: doc.data().email,
					color: doc.data().color,
					amount: doc.data().amount,
					days: doc.data().days,
					percentage: doc.data().percentage,
					divisa: doc.data().divisa,
				});
			});
			//////ordenamos la lista por prioridad (priority)
			listaData.sort(function (a, b) {
				return a.days - b.days;
			});
			this.setState({offerList: listaData});
		});
	}

	render() {
		return (
			<div className="content-wrapper">
				<div className="content-header">
					<div className="container-fluid">
						<div className="card card-primary">
							<div className="card-body">
								<h2 className="panel-title">Reporte de pago de comisiones</h2>
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-check">
												<label>Fecha inicial:</label>
												<input className="form-control" name="fechaIni" type="date" value={this.state.fechaIni} onChange={this.manejadorCambios}/>
											</div>
										</div>
										<div className="col-sm">
											<div className="form-check">
												<label>Fecha final:</label>
												<input className="form-control" name="fechaEnd" type="date" value={this.state.fechaEnd} onChange={this.manejadorCambios}/>
											</div>
										</div>
									</div>
									<br/>
									<div className="row">
										<div className="col-sm">
											<div className="form-check">
												<label>Inicia la consulta:</label>
												<button onClick={() => this.calculoComisiones()} type="button" id="buttonCarrusel" className="btn btn-primary btn-lg btn-block">CONSULTAR</button>
												<br/>
												<div className="form-check">
													<label>Progreso de consulta:</label>
													<progress value={this.state.consecutivo} max={this.state.offerList.length}/>
												</div>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>
					</div>

				</div>

				<div className="content-header d-flex">
					<div className="container-fluid">

						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Cantidad de comisionistas: {this.state.offerList.length} Calculados: {this.state.consecutivo}</h3>
							</div>
							{/*<div className="description-block">*/}
							{/*	<h5 className="description-header">TOTALES</h5>*/}
							{/*</div>*/}
							<div>
								{/*<table className="table">*/}
								{/*	<thead className="thead-dark">*/}
								{/*	<tr>*/}

								{/*		<th scope="col" width="40">Usuarios</th>*/}
								{/*		<th scope="col" width="40">Finalizados</th>*/}
								{/*		<th scope="col" width="40">Iniciados</th>*/}
								{/*		<th scope="col" width="40">ChangedPage</th>*/}
								{/*		<th scope="col" width="50">Suma</th>*/}
								{/*	</tr>*/}
								{/*	</thead>*/}
								{/*	<tbody>*/}
								{/*	<tr>*/}
								{/*		<td>{this.state.metUsuariosTotal}</td>*/}
								{/*		<td>{this.state.metPatbooksIni}</td>*/}
								{/*		<td>{this.state.metPatbooksEnd}</td>*/}
								{/*		<td>{this.state.metPatbooksPag}</td>*/}
								{/*		<td>{this.state.metPatbooksEve}</td>*/}
								{/*	</tr>*/}
								{/*	</tbody>*/}
								{/*</table>*/}
								<div className="description-block">
									<h5 className="description-header">Reporte de comissionistas</h5>
								</div>
								<table className="table">
									<thead className="thead-dark">
									<tr>
										<th scope="col" width="280">Nombre</th>
										<th scope="col" width="40">Divisa</th>
										<th scope="col" width="40">Porcentage</th>
										<th scope="col" width="40">Conteo</th>
										<th scope="col" width="40">Ventas Totales</th>
										<th scope="col" width="40">Comision</th>


									</tr>
									</thead>
									<tbody>
									{this.state.listaComisionista.map(data => (
										<tr key={data.offerID}>
											<td width="250">
												<div>{data.nombreCommissioner}</div>
											</td>
											<td width="100">{data.divisa}</td>
											<td>{data.percentage} %</td>
											<td>{data.sumaContador}</td>
											<td>$ {data.sumaDeCapital} {data.divisa}</td>

											<td>$ {(data.sumaDeCapital * data.percentage) / 100} {data.divisa}</td>

											{/*<td>{data.cantIniciados} {data.divideIni}</td>*/}
											{/*<td>{data.changedPage} {data.divideChan}</td>*/}
											{/*<td>{data.cantFinalizados} {data.divideEnd}</td>*/}
											{/*<td>{data.totalEvents} {data.divideSum}</td>*/}
											{/*<td>{data.pull}</td>*/}
											{/*<td><a href={data.urlGenrado} target="_blank">Abrir</a></td>*/}
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default RepCommission;

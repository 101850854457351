import React, {Component} from "react";
import { auth, firestore } from "../js/firebase";

class LoginIni extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			//////estado del usuario
			user: null,
		};
	}


	componentDidMount() {
		let parent = this;
		auth.onAuthStateChanged(function (user) {
			if (user) {
				firestore.doc("users/" + user.uid).get().then((documentSnapshot) => {
                    let dataWriter = documentSnapshot.data();
					if(!dataWriter){
						auth.signOut();
					}
                    if (dataWriter.type === "admin"){
	                    parent.props.history.push("/Panel");
                    }else{
	                    auth.signOut();
	                    alert("usuario no Administrador");
                    }
                });
			}
			parent.setState({
				user: user,
			});
		});
	}

	login = () => {
		auth.signInWithEmailAndPassword(this.state.email, this.state.password)
			.then(() => {

			})
			.catch(error => {
				if (error.code === "auth/user-not-found") {
					alert("Email no valido");
				}
				if (error.code === "auth/invalid-email") {
					alert("Email no valido");
				}
				if (error.code === "auth/wrong-password") {
					alert("Password no valido");
				}
			});
	};

	register = () => {
		this.props.history.push("/Register");
	};

	render() {
		return (
			<div className="login-box">
				<div className="login-logo">
					<b>PATHBOOKS</b> ADMINISTRADORES
				</div>
				<div className="card">
					<div className="card-body login-card-body">
						<p className="login-box-msg">Inicia con tu cuenta de Escritor</p>
						<form action="#" onSubmit={e => e.preventDefault()}>
							<div className="input-group mb-3">
								<input type="email" className="form-control" placeholder="Email" onChange={event => this.setState({email: event.target.value})}/>
								<div className="input-group-append">
									<div className="input-group-text">
										<span className="fas fa-envelope"/>
									</div>
								</div>
							</div>
							<div className="input-group mb-3">
								<input type="password" className="form-control" placeholder="Password" onChange={event => this.setState({password: event.target.value})}/>
								<div className="input-group-append">
									<div className="input-group-text">
										<span className="fas fa-lock"/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<button onClick={this.login} className="btn btn-primary btn-block btn-flat">Inicia sesión</button>
								</div>
							</div>
						</form>
					</div>
					<div>
						<div className="card-body login-card-body col-6">
							<button onClick={this.register} className="btn btn-primary btn-block btn-flat">Registrar</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LoginIni;

import React from 'react';
import { auth } from "../js/firebase";
import {Link} from "react-router-dom";

export default function BarTop() {
  // let {url} = useRouteMatch();
  return (
      <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">

          <ul className="navbar-nav">

            <li className="nav-item">
              <span className="nav-link" data-widget="pushmenu"><i className="fas fa-bars"/></span>
            </li>

            {/*<li className="nav-item d-none d-sm-inline-block">*/}
            {/*  <Link className="nav-link" to={`${url}`}>Inicio</Link>*/}
            {/*</li>*/}

            {/*<li className="nav-item d-none d-sm-inline-block">*/}
            {/*  <Link className="nav-link" to={`${url}/Contacto`}>Contacto</Link>*/}
            {/*</li>*/}

            {/*<li className="nav-item d-none d-sm-inline-block">*/}
            {/*  <Link className="nav-link" to={`${url}/Ini`}>Ini</Link>*/}
            {/*</li>*/}

          </ul>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to={"/"} onClick={LogOut}>Salir</Link>
            </li>
          </ul>

        </nav>
      </div>
  );
}

function LogOut () {auth.signOut()}

import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import swal from "sweetalert2";
import FichaEliminaPathbook from "./FichaEliminaPathbook";

///// Componente para armar la pantlla
class ListaVisibleCaruseles extends Component {
	constructor(props) {
		super();
		this.state = {
			pathbookCarrusel: "",

			carruselID: props.data.idCarr,
			carruselPriority: props.data.priority,
			carruselNameEs: props.data.nombreEs,
			carruselNameEn: props.data.nombreEn,
			dbLocation: props.data.dbLocation,
			type: props.data.typeCarr,
			pathbooks: props.data.pathbooks,
			pathbooksList: [],
			selectPathbooks: props.pathbooksList,
			limitPathbooks: props.data.limit,
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	///////editar Carrusel
	editarPrioridadFeature(idCarrusel, Prioridad) {
		if (Prioridad > 0) {
			firestore.collection(this.state.dbLocation).doc(idCarrusel).update({
				priority: parseInt(Prioridad),
			});
		} else {
			swal.fire({
				title: "Numero no valido",
				text: "Ingresa un numero mayor a cero ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		}
	}

	validaPathbooksExiste() {
		if (!this.state.pathbookCarrusel) {
			swal.fire({title: "Seleciona un pathbook existente", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let generoID = this.state.pathbookCarrusel.split(" -   --> ");
			firestore.doc("pathbooks/" + generoID[1]).get().then((documentSnapshot) => {
				let datapathbook = documentSnapshot;
				if (datapathbook.exists === true) {
					// this.setState({pathbooksAModificar: generoID[1]});
					this.agregarAListaPathbooks(generoID[1]);
				} else {
					this.setState({pathbookCarrusel: ""});
					swal.fire({title: "Ese pathbook no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
			});
		}
	}

	agregarAListaPathbooks(idPAthbook) {
		if (!this.state.limitPathbooks) {
			swal.fire({title: "Ingresa la cantidad de pathbooks maxima de tu carrusel", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (parseInt(this.state.limitPathbooks) <= 0) {
			swal.fire({title: "Ingresa la cantidad de Maximo de pathbooks Mayor a 0", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else if (parseInt(this.state.limitPathbooks) === this.state.pathbooks.length) {
			swal.fire({title: "Limite maximo de pathboks alcanzado agrega un limite mayor", text: "para continuar ", type: "error", confirmButtonText: "Entendido"});
		} else {
			this.state.pathbooks.push(firestore.doc("pathbooks/" + idPAthbook));
			firestore.collection(this.state.dbLocation).doc(this.state.carruselID).update({
				pathbooks: this.state.pathbooks,
			});
			this.pathbookListas();
		}
	}

	borrarCarrusel(idCarrusel) {
		firestore.collection(this.props.data.dbLocation).doc(idCarrusel).delete().then(function () {
			swal.fire({
				title: "Borrado con exito",
				text: "Buen trabajo",
				type: "error",
				confirmButtonText: "OK",
			});
		}).catch(function (error) {
			swal.fire({
				title: "Error al borrar",
				text: error,
				type: "error",
				confirmButtonText: "Entendido",
			});
		});
	}

	pathbookListas() {
		if (this.state.pathbooks) {
			let pathbookListVar = [];
			let documentID = 0;
			this.state.pathbooks.forEach((doc) => {
				pathbookListVar.push({
					idPathbook: doc.id,
					idCarucel: this.state.carruselID,
					documentID: documentID,
					dbLocation: this.state.dbLocation,
				});
				documentID = documentID + 1;
			});
			this.setState({pathbooksList: pathbookListVar});
		}
	}

	componentDidMount() {
		this.pathbookListas();
	}


	render() {
		return (
			<div className="col card">
				<div className="col-md-12">
					<div className="widget-user">
						<div className="description-block">
							<span>Nombre Es:</span>
							<h3>{this.state.carruselNameEs}</h3>
						</div>

						<div className="description-block">
							<span>Nombre En:</span>
							<h5 className="description-header">{this.state.carruselNameEn}</h5>
						</div>
						<div className="description-block">
							<span>Prioridad:</span>
							<h5 className="description-header">{this.state.carruselPriority}</h5>
						</div>
						<div className="description-block">
							<span>Tipo de carrusel:</span>
							<h5 className="description-header">{this.state.type}</h5>
						</div>
					</div>
				</div>
				<div className="col-sm-12">
					<div className="row">
						<div className="col-sm-12">
							<div className="description-block">
								<h5 className="description-header">EDITAR PRIORIDAD O BORRAR</h5>
							</div>
						</div>
						<div className="col-sm-6 border-right">
							<div className="description-block">
								<input className="form-control" name="carruselPriority" placeholder="Prioridad (numero)" type="number" value={this.state.carruselPriority} onChange={this.manejadorCambios}/>
								<button onClick={() => this.editarPrioridadFeature(this.state.carruselID, this.state.carruselPriority)} type="button" className="btn btn-block bg-gradient-warning">CAMBIAR</button>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="description-block">
								<h4>BORRAR</h4>
							</div>
							<div className="description-block">
								<button onClick={() => this.borrarCarrusel(this.state.carruselID)} type="button" className="btn btn-block bg-gradient-danger">BORRAR</button>
							</div>
						</div>
						<div className="col-sm-6 border-right">
						</div>

					</div>
				</div>
				{
					this.state.pathbooks === null
						? null
						: (<div>
							<div>
								<br/>
								<h3>Escoje un Pathbook para agregar a este carusel</h3>
								<input className="form-control" list="selectPathbook" id="patbookSelected" name="pathbookCarrusel" type="select" placeholder="Elige un Pathbook" value={this.state.pathbookCarrusel} onChange={this.manejadorCambios}/>
								<datalist id="selectPathbook">
									{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
									{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombreEn + " -   --> " + task.id}>{task.nombreEn}</option>)}
								</datalist>
								<br/>
								<div className="">
									<button onClick={() => this.validaPathbooksExiste()} type="button" id="buttonCarrusel" className="btn btn-block btn-primary btn-lg btn-success">Agrega a la lista</button>
								</div>
							</div>
							<br/>
							<h3>Lista de pathbooks</h3>
							{this.state.pathbooksList.map(task =>
								<div key={task.documentID} className="">
									<FichaEliminaPathbook task={task}/>
								</div>)
							}
							<br/>
						</div>)
				}
			</div>
		);
	}
}

export default ListaVisibleCaruseles;

import React, {Component} from "react";
import {firestore, storage} from "../../js/firebase";
import swal from "sweetalert2";

class FichaCommission extends Component {
	constructor(props) {
		super();
		console.log(props.data)
		this.state = {
			priorityFeature: props.data.priority,
			imagePathbook: "",
			fotoAutor: props.data.picAfiliado,
			barraLoad1: 0,
			barraLoadEnd1: 1000,
			message1: "Seleciona un archivo .JPG para cargar",
			urlIni: "https://firebasestorage.googleapis.com/v0/b/pathbook.appspot.com/o/affiliate%2F",
			urlmid: "%2Fimages%2F",
			urlEnd: "?alt=media&token=b8878edf-dbb0-4383-87e9-bfa78ca03bf0",
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	borrarOffert(idOffer) {
		firestore.collection("affiliate").doc(idOffer).delete().then(function () {
			swal.fire({
				title: "Borrado con exito",
				text: "Buen trabajo",
				type: "error",
				confirmButtonText: "OK",
			});
		}).catch(function (error) {
			swal.fire({
				title: "Error al borrar",
				text: "error",
				type: "error",
				confirmButtonText: "Entendido",
			});
		});
	}

	cargaFoto(event) {
		const namePict = "foto";
		const file = event.target.files[0];
		const storageRef = storage.ref("affiliate/" + this.props.data.offerID + "/images").child(namePict);
		const task = storageRef.put(file);
		if (task.snapshot.totalBytes < 1250000) {
			task.on("state_changed", (snapshot) => {
				this.setState({
					barraLoadEnd1: snapshot.totalBytes,
					barraLoad1: snapshot.bytesTransferred,
				});

			}, (error) => {
				this.setState({
					message1: "Error: " + error,
				});
			}, () => {
				this.setState({
					message1: "Cargado correctamente",
					fotoAutor: this.state.urlIni + this.props.data.offerID + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd,
				});
				this.guarda();
			});
		} else {
			swal.fire({
				title: "Imagen demaciado grande",
				text: "reduce a 150 KB maximo para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		}
	}

	async guarda() {
		await firestore.collection("affiliate").doc(this.props.data.offerID).update({
			picAfiliado: this.state.fotoAutor,
		});
		swal.fire({
			title: "Guardado con exito",
			text: "",
			type: "error",
			confirmButtonText: "Entendido",
		});
	}

	render() {
		return (
			<div className="card text-center">
				<div className="card-header">
					{
						!this.props.data.picAfiliado
							? (<div>
								<label>SIN FOTO</label>

							</div>)
							:
							<div>
								<img src={this.state.fotoAutor} alt="manual" width="100" height="100"/>
							</div>
					}
					<div className="">
						<br/>
						<div className="custom-file">
							<input type="file" accept="image/x-png,image/jpg" className="custom-file-input" onChange={this.cargaFoto.bind(this)}/>
							<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .PNG</label>
						</div>
					</div>
					<div className="input-group mb-3">
						{this.state.message1}
					</div>
					<div className="">
						<progress value={this.state.barraLoad1} max={this.state.barraLoadEnd1}/>
					</div>
				</div>
				<div className="card-footer text-muted">
					<p>Codigo URL boton:</p>
					<h5 className="">{"https://read.pathbooks.app/af?rid=" + this.props.data.txtLink + "&divi=" + this.props.data.divisa}</h5>
				</div>
				<div className="card-footer text-muted">
					<p>Nombre:</p>
					<h5 className="">{this.props.data.name}</h5>
				</div>
				<div className="card-footer text-muted">
					<p>Email:</p>
					<h5 className="">{this.props.data.email}</h5>
				</div>
				<div className="card-footer text-muted">
					<p>Telefono:</p>
					<h5 className="">{this.props.data.phone}</h5>
				</div>
				<div className="card-footer text-muted">
					<button onClick={() => this.borrarOffert(this.props.data.offerID)} type="button" className="btn btn-block bg-gradient-danger">BORRAR: {this.props.data.nameCommission}</button>
				</div>
			</div>
		);
	}
}

export default FichaCommission;

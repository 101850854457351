import React, {Component} from "react";
import {storage, firestore} from "../../js/firebase";
import swal from "sweetalert2";
import FichaImagenInterior from "./FichaImagenInterior";

class FormImgPathbook extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pathbooksLista: props.dataGeneral.listaPathbooks,
			selectPathbooks: [],
			/////pathbook lista
			urlIni: "https://firebasestorage.googleapis.com/v0/b/pathbook.appspot.com/o/pathbooks%2F",
			urlmid: "%2Fimages%2F",
			urlEnd: "?alt=media",
			referenceIni: "gs://pathbook.appspot.com/pathbooks/",
			referenceMid: "/images/",
			imagenNull: "https://livingabook.com/LoadPathbooksAndMore/assets/img/portadaNull.jpg",
			imagenACargar: null,
			barraLoad4: 0,
			barraLoadEnd4: 1000,
			message4: "Seleciona un archivo .JPG o .PNG para cargar",
			pathbookSeleccionado: "",
			imagenDatos: [],
			pathbooksInfo: null,
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	async validaPathbooksExiste() {
		if (!this.state.pathbookSeleccionado) {
			swal.fire({title: "Seleciona un pathbook existente", text: "Gracias", type: "error", confirmButtonText: "Entendido"});
		} else {
			let pathbookId = this.state.pathbookSeleccionado.split(" -   --> ");
			await firestore.doc("pathbooks/" + pathbookId[1]).get().then((documentSnapshot) => {
				let datapathbook = documentSnapshot;
				if (datapathbook.exists === true) {
					this.setState({pathbooksInfo: datapathbook});
					if(datapathbook.data().imagesInt){
						this.setState({imagenDatos: datapathbook.data().imagesInt,});
					}
				} else {
					swal.fire({title: "Ese pathbook no existe", text: "ingresa uno correcto para continuar ", type: "error", confirmButtonText: "Entendido"});
				}
			});
		}
	}

	async cargaImagen(event) {
		const file = event.target.files[0];
		const storageRef = storage.ref("pathbooks/" + this.state.pathbooksInfo.id + "/images").child(file.name);
		let cadenaSave = this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + file.name + this.state.urlEnd
		let existe = false
		// let cadenaSave = this.state.urlIni + this.state.pathbooksInfo.id + this.state.urlmid + task.snapshot.metadata.name + this.state.urlEnd
		 await this.state.imagenDatos.forEach((task) => {
		 	if (cadenaSave === task){
				existe = true
				    swal.fire({
					    title: "Esta imagen ya existe",
					    text: "El nombre de esta imagen ya existe",
					    type: "error",
					    confirmButtonText: "Entendido",
				    });

			}
		 });
		if (existe === false){
			const task = storageRef.put(file);
			task.on("state_changed", (snapshot) => {
				this.setState({
					barraLoadEnd4: snapshot.totalBytes,
					barraLoad4: snapshot.bytesTransferred,
				});
			}, (error) => {
				this.setState({
					message4: "Error: " + error,
				});
			}, () => {
				this.setState({
					message4: "Cargado correctamente",
					imagenACargar: cadenaSave
				});
				this.guarda();
			});
			this.state.imagenDatos.push(cadenaSave);
		}
	}

	async guarda() {
		await firestore.collection("pathbooks").doc(this.state.pathbooksInfo.id).update({
			imagesInt: this.state.imagenDatos
		});
		this.validaPathbooksExiste();
	}

	listaDeImagenes(){
		// var listRef = storageRef.child("pathbooks/" + this.state.pathbooksInfo.id);
		// listRef.listAll().then(function(res) {
		// 	res.prefixes.forEach(function(folderRef) {
		// 		console.log(folderRef)
		// 	});
		// 	res.items.forEach(function(itemRef) {
		// 	});
		// }).catch(function(error) {
		// });
	}

	async componentDidMount() {
		/////lista de pathbooks
		let selectPathbookList = [];
		await this.state.pathbooksLista.forEach((task) => {
			let idPathbook = task.doc.id;
			let nombrePathbook = task.doc.data().title["es"];
			selectPathbookList.push({id: idPathbook, nombre: nombrePathbook});
		});

		this.setState({selectPathbooks: selectPathbookList});

		this.listaDeImagenes()
	}



	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header">
					<h2 className="panel-title">Imagenes de Pathbook</h2>
				</div>
				<div className="content-header">
					<div className="card card-primary">
						<div className="card-header">
							<h3 className="card-title">Busca Pathbook</h3>
						</div>

						<div className="d-flex">
							<div className="card-body">
								<div className="container">
									<div className="row">
										<div className="col-sm">
											<div className="form-group">
												<div className="container">
													<label>Si quieres editar imagenes a un pathbook Buscalo aqui:</label>
													<div className="row">
														<input className="form-control" list="selectPathbook" id="generoSelected" name="pathbookSeleccionado" type="select" placeholder="Elige un pathbook a modificar" value={this.state.pathbookSeleccionado} onChange={this.manejadorCambios}/>
														<datalist id="selectPathbook">
															{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.nombre + " -   --> " + task.id}>{task.nombre}</option>)}
														</datalist>
													</div>
												</div>
												<br/>
												<div className="form-group">
													{/*validaBuscadorPathbook*/}
													<button onClick={() => this.validaPathbooksExiste()} type="button" id="buttonPathbook" className="btn btn-primary btn-lg btn-block">Seleccionar pathbook</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="d-flex">
							<div className="card-body">
								{
									!this.state.pathbooksInfo
										? (
											<div>
												<label>Selecciona un pathbook:</label>
											</div>)
										:
										<div>
											<label>Pathbook selecionado</label>
											<h3>{this.state.pathbooksInfo.data().title["es"]}</h3>
											<div className="container">
												<h6>Carga una imagen</h6>
												<div className="row row-inputs">
													<progress value={this.state.barraLoad4} max={this.state.barraLoadEnd4}/>
												</div>
												<div className="row row-inputs">
													<br/>
													<div className="input-group mb-3">
														<div className="custom-file">
															<input type="file" accept="image/x-png,image/jpg" className="custom-file-input" onChange={this.cargaImagen.bind(this)}/>
															<label className="custom-file-label" htmlFor="inputGroupFile02">Tipo .JPG o PNG</label>
														</div>
													</div>
													<div className="input-group mb-3">
														{this.state.message4}
													</div>
													<br/>
												</div>
												<br/>
											</div>
											<div className="container">
												{this.state.imagenDatos.map(task => <div className="row justify-content-md-center" key={task}>
													<FichaImagenInterior data={task}/>
												</div>)}
											</div>
											<br/>
											<br/>
											<br/>
											<label>Cadenas</label>
											<label>Url:</label>
											<h5>{this.state.urlIni}{this.state.pathbooksInfo.id}{this.state.urlmid}</h5>
											<h5>{this.state.urlEnd}</h5>
											<br/>
											<label>Referencia:</label>
											<h5>{this.state.referenceIni}{this.state.pathbooksInfo.id}{this.state.referenceMid}</h5>
											<br/>
										</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);

	}
}

export default FormImgPathbook;


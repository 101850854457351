import React, {Component} from "react";
///// Componente para armar la pantlla
class FichaImagenInterior extends Component {
	constructor(props) {
		super();
		this.state = {
			imagenDir: props.data
		};
	}
	render() {
		return (
		<div className="card">
			<div className="col-md-12">
				<div className="  widget-user">
					<label >Referencia audio:</label><br/>
					<h6>{this.state.imagenDir}</h6><br/>
					<img src={this.state.imagenDir}  alt="feature" width="300" />
					<a download="PSD" href={this.state.imagenDir}><h5>Descarga Audio</h5></a>
				</div>
			</div>
		</div>
		);
	}
}

export default FichaImagenInterior;

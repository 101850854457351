import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import swal from "sweetalert2";

class FichaOffert extends Component {
	constructor(props) {
		super();
		this.state = {
			priorityFeature: props.data.priority,
			imagePathbook: "",
			nombrePathbook: "",
		};
		this.manejadorCambios = this.manejadorCambios.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	borrarOffert(idOffer) {
		firestore.collection("offers").doc(idOffer).delete().then(function () {
			swal.fire({
				title: "Borrado con exito",
				text: "Buen trabajo",
				type: "error",
				confirmButtonText: "OK",
			});
		}).catch(function (error) {
			swal.fire({
				title: "Error al borrar",
				text: "error",
				type: "error",
				confirmButtonText: "Entendido",
			});
		});
	}

	render() {
		return (
			<div>
				<div className="card text-center">
					<div className="card-header">
						<h3 className="description-header">Oferta:</h3>
						<h5 className="">{this.props.data.title}</h5>
						<h6 className="description-header">Precio: $ {this.props.data.amount + " " + this.props.data.divisa + " - Días" + this.props.data.days}</h6>
					</div>
					<div className="card-footer text-muted">
						<p>Codigo URL boton:</p>
						<h5 className="">{"https://read.pathbooks.app/payment?rid=" + this.props.data.offerID + "&divi=" + this.props.data.divisa}</h5>
					</div>
					<div className="card-header">
						<br/>
						<h5 className="description-header">Sub Oferta:</h5>
						<h5 className="description-header">{this.props.data.titleSubOffer}</h5>
						<h6 className="description-header">Precio: $ {this.props.data.amountSubOffer + " " + this.props.data.divisa + " - Días" + this.props.data.daysSubOffer}</h6>
					</div>
					<div className="card-footer text-muted">
						<button onClick={() => this.borrarOffert(this.props.data.offerID)} type="button" className="btn btn-block bg-gradient-danger">BORRAR {this.props.data.title}</button>
					</div>
				</div>
			</div>
		);
	}
}

export default FichaOffert;

import React, {Component} from "react";
import {firestore} from "../../js/firebase";
import FichaFeatures from "./FichaFeatures";
import swal from "sweetalert2";
import Moment from "moment";

///// Componente para armar la pantlla
class AppFeatures extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pathbooksLista: props.dataGeneral.listaPathbooks,
			codigoFeature: "",
			activarFeature: false,
			tituloEnFeature: "",
			tituloEsFeature: "",
			tituloDeFeature: "",
			tituloFrFeature: "",
			tituloItFeature: "",
			tituloPtFeature: "",
			prioridadFeature: "",
			pathbookFeature: "",
			classificationFeature: "Null",
			selectPathbooks: [],
			selectPathbooksSend: [],

			pathbookFeatureVerificado: "",
			enviarFeaturelA: "",
			///FEATURES
			featureList: [],
			featureListKids: [],
			featureListStudents: [],
		};
		//console.log(this.state.pathbooks)
		this.manejadorCambios = this.manejadorCambios.bind(this);
		this.manejadorImputs = this.manejadorImputs.bind(this);
	}

	manejadorCambios(event) {
		const name = event.target.name;
		this.setState({
			[name]: event.target.value,
		});
	}

	////manejamos el imput change para multiples chekbooks
	manejadorImputs(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		this.setState({
			[name]: value,
		});
	}

	manejadorImputs1() {
		this.setState({enviarFeaturelA: "features"});
	}

	manejadorImputs2() {
		this.setState({enviarFeaturelA: "features-kids"});
	}

	manejadorImputs3() {
		this.setState({enviarFeaturelA: "features-students"});
	}

	validaFormFeature() {
		const value = this.state.enviarFeaturelA;
		let regex = new RegExp("^[0-9]+$");
		if (!this.state.enviarFeaturelA) {
			swal.fire({
				title: "Seleciona a donde mandaras tu carrusel Kids o pathbooks",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (this.state.classificationFeature === "Null") {
			swal.fire({
				title: "Ingresa una clasificacion",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.tituloEnFeature) {
			swal.fire({
				title: "Ingresa un titulo en Ingles para tu feature ",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.tituloEsFeature) {
			swal.fire({
				title: "Ingresa un titulo en Español para tu feature",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.tituloDeFeature) {
			swal.fire({
				title: "Ingresa un titulo en Aleman para tu feature ",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.tituloFrFeature) {
			swal.fire({
				title: "Ingresa un titulo en Frances para tu feature",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.tituloItFeature) {
			swal.fire({
				title: "Ingresa un titulo en Italiano para tu feature ",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.tituloPtFeature) {
			swal.fire({
				title: "Ingresa un titulo en Portuges para tu feature",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.prioridadFeature) {
			swal.fire({
				title: "Ingresa la prioridad para tu feature",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (regex.test(value)) {
			swal.fire({
				title: "El valor de la prioridad no es un numero entero",
				text: "agrega un numero entero para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});
		} else if (!this.state.pathbookFeature) {

			swal.fire({
				title: "Ingresa un pathbook para tu feature",
				text: "para continuar ",
				type: "error",
				confirmButtonText: "Entendido",
			});

		} else {
			let patbookID = this.state.pathbookFeature.split(" --> ");
			//////evaluamos que el pathbook exista
			firestore.doc("pathbooks/" + patbookID[0]).get().then((documentSnapshot) => {
					let dataPatbook = documentSnapshot;
					if (dataPatbook.data().active) {
						this.setState({pathbookFeatureVerificado: patbookID[0]});
					}
				})
				.then(() => {
					this.guardaDatos();
				})
				.catch(error => {
					swal.fire({
						title: "Ese pathbook no existe",
						text: "OK para continuar",
						type: "error",
						confirmButtonText: "Entendido",
					});
				});
		}
	}

	limpiarForm() {
		this.setState({
			prioridadFeature: "",
			pathbookFeatureVerificado: "",
			classificationFeature: "Null",
			tituloEnFeature: "",
			tituloEsFeature: "",
			tituloDeFeature: "",
			tituloFrFeature: "",
			tituloItFeature: "",
			tituloPtFeature: "",
			codigoFeature: "",
			activarFeature: false,
			pathbookFeature: ""
		});
		swal.fire({
			title: "Guardado con exito",
			text: "Buen trabajo",
			type: "error",
			confirmButtonText: "OK",
		});
	}

	///guardamos os datos en la bd
	guardaDatos() {
		////obtenemos los datos del formulario
		firestore.collection(this.state.enviarFeaturelA).doc().set({
			active: this.state.activarFeature,
			pathbook: firestore.doc("pathbooks/" + this.state.pathbookFeatureVerificado),
			priority: parseInt(this.state.prioridadFeature),
			user: null,
			deleted: null,
			modifies: null,
			classification: this.state.classificationFeature,
			title: {
				"en-US": this.state.tituloEnFeature,
				"es-MX": this.state.tituloEsFeature,
				"en": this.state.tituloEnFeature,
				"es": this.state.tituloEsFeature,
				"de": this.state.tituloDeFeature,
				"fr": this.state.tituloFrFeature,
				"it": this.state.tituloItFeature,
				"pt": this.state.tituloPtFeature,
			},
			timestamp: Moment().toDate(),
		});
		this.limpiarForm();
	}

	async componentDidMount() {
		///////llenamos la lista de los pathbooks
		let selectPathbookList = [];
		await this.state.pathbooksLista.forEach((task) => {
			if (task.doc.data().active === true){
				let idPathbook = task.doc.id;
				let nombrePathbook = task.doc.data().title["es-MX"];
				selectPathbookList.push({id: idPathbook, nombre: nombrePathbook});
			}
		});
		this.setState({selectPathbooks: selectPathbookList});

		///////llenamos la lista de los features
		let listaData = [];
		// const features = await firestore.collection("features").get();
		firestore.collection("features").onSnapshot(querySnapshot => {
			///esta variable evita que se dupliquen las consultas
			listaData = []
			let features = querySnapshot.docs
			 features.forEach((doc) => {
				listaData.push({
					pathbookID: doc.data().pathbook.id,
					priority: doc.data().priority,
					id: doc.id,
					nombreEs: doc.data().title["es-MX"],
					nombreEn: doc.data().title["en-US"],
					activo: doc.data().active,
					dbLocation: "features",
				});
			});
			//////ordenamos la lista por prioridad (priority)
			listaData.sort(function (a, b) {
				return a.priority - b.priority;
			});
			this.setState({featureList: listaData});
		});
		///////llenamos la lista de los features

		///////llenamos la lista de los features Kids
		let listaDataK = [];
		// const features = await firestore.collection("features").get();
		firestore.collection("features-kids").onSnapshot(querySnapshot => {
			///esta variable evita que se dupliquen las consultas
			listaDataK = []
			let featuresKids = querySnapshot.docs
			featuresKids.forEach((doc) => {
				listaDataK.push({
					pathbookID: doc.data().pathbook.id,
					priority: doc.data().priority,
					id: doc.id,
					nombreEs: doc.data().title["es-MX"],
					nombreEn: doc.data().title["en-US"],
					activo: doc.data().active,
					dbLocation: "features-kids",
				});
			});
			//////ordenamos la lista por prioridad (priority)
			listaDataK.sort(function (a, b) {
				return a.priority - b.priority;
			});
			this.setState({featureListKids: listaDataK});
		});

		let listaDataE = [];
		// const features = await firestore.collection("features").get();
		firestore.collection("features-students").onSnapshot(querySnapshot => {
			///esta variable evita que se dupliquen las consultas
			listaDataE = []
			let featuresKids = querySnapshot.docs
			featuresKids.forEach((doc) => {
				listaDataE.push({
					pathbookID: doc.data().pathbook.id,
					priority: doc.data().priority,
					id: doc.id,
					nombreEs: doc.data().title["es-MX"],
					nombreEn: doc.data().title["en-US"],
					activo: doc.data().active,
					dbLocation: "features-students",
				});
			});
			//////ordenamos la lista por prioridad (priority)
			listaDataE.sort(function (a, b) {
				return a.priority - b.priority;
			});
			this.setState({featureListStudents: listaDataE});
		});
	}

	render() {
		return (
			<div className="content-wrapper">

				<div className="content-header d-flex">
					<h2 className="panel-title">Features</h2>
				</div>

				<div className="content-header">
					<div className="container-fluid">
						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Crear nuevo Features</h3>
							</div>
							<div>
								<div className="card-body">
									<label>Feature para:</label>
									<h6>Selecciona a cual de los dos menus se mandara tu feature</h6>
									<div className="container">
										<div className="row">
											<div className="col-sm">
												<div className="form-check">
													<input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs1()}/>
													<label className="form-check-label">Pathbooks</label>
												</div>
											</div>
											<div className="col-sm">
												<div className="form-check">
													<input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs2()}/>
													<label className="form-check-label">Kids</label>
												</div>
											</div>
											<div className="col-sm">
												<div className="form-check">
													<input className="form-check-input" type="radio" name="radio1" onChange={() => this.manejadorImputs3()}/>
													<label className="form-check-label">Estudiantes</label>
												</div>
											</div>
										</div>
									</div>
									<br/>
									<div className="form-group">
										<label>Seleeciona una clasificación</label>
										<select className="form-control" name="classificationFeature" value={this.state.classificationFeature} onChange={this.manejadorCambios}>
											<option value="Null">Seleciona uno</option>
											<option value="A">A</option>
											<option value="AA">AA</option>
											<option value="B">B</option>
											<option value="B15">B15</option>
											<option value="C">C</option>
										</select>
									</div>
									<br/>
									<div className="form-group">
										<label>Activo:</label>
										<div className="form-check">
											{/*<input name="activarFeature" type="checkbox" checked={this.state.activarFeature} onChange={this.manejadorImputs} />*/}
											{/*<input className="form-check-input" type="checkbox" name="radio1" onChange={() => this.manejadorImputs1()}/>*/}
											<input name="activarFeature" type="checkbox" checked={this.state.activarFeature} onChange={this.manejadorImputs}/>
											<label className="form-check-label"> - Activar</label>
										</div>
										<span> Activar el featur para que funcione al guardar.</span>
									</div>
									<div className="form-group">
										<label>Titulo de feature</label>
										<input className="form-control" name="tituloEnFeature" placeholder="Titulo Ingles" type="text" value={this.state.tituloEnFeature} onChange={this.manejadorCambios}/>
										<input className="form-control" name="tituloEsFeature" placeholder="Titulo Español" type="text" value={this.state.tituloEsFeature} onChange={this.manejadorCambios}/>
										<input className="form-control" name="tituloDeFeature" placeholder="Titulo Aleman" type="text" value={this.state.tituloDeFeature} onChange={this.manejadorCambios}/>
										<input className="form-control" name="tituloFrFeature" placeholder="Titulo Frances" type="text" value={this.state.tituloFrFeature} onChange={this.manejadorCambios}/>
										<input className="form-control" name="tituloItFeature" placeholder="Titulo Italiano" type="text" value={this.state.tituloItFeature} onChange={this.manejadorCambios}/>
										<input className="form-control" name="tituloPtFeature" placeholder="Titulo Portuges" type="text" value={this.state.tituloPtFeature} onChange={this.manejadorCambios}/>
										<span>Ingresar un titulo en español e ingles de manera obligatoria</span>
									</div>
									<div className="form-group">
										<label>Prioridad (Numero)</label>
										<input className="form-control" name="prioridadFeature" placeholder="Prioridad (numero)" type="number" value={this.state.prioridadFeature} onChange={this.manejadorCambios}/>
										<span>Ingresar un numero que indique que tanta prioridad tendra el feature en el carrucel</span>
									</div>

									<div>
										<h6>Seleeciona un pathbook</h6>
										<input className="form-control" list="selectPathbook" id="patbookSelected" name="pathbookFeature" type="select" placeholder="Elige un Pathbook" value={this.state.pathbookFeature} onChange={this.manejadorCambios}/>
										<datalist id="selectPathbook">
											{this.state.selectPathbooks.map(task => <option key={task.id + "key"} value={task.id + " --> " + task.nombre}>{task.nombre}</option>)}
										</datalist>
										<div></div>
									</div>
									{/*<button onClick={() => this.guardarFeature()} type="button" id="buttonFeature" className="btn btn-primary btn-lg btn-block">Nuevo Feature</button>*/}
									<div className="card-footer">
										<button onClick={() => this.validaFormFeature()} type="button" id="buttonFeature" className="btn btn-primary btn-lg btn-block">Agregar</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/*<FichaFeatures />*/}
				</div>
				<div className="content-header d-flex">
					<div className="container-fluid">
						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Lista de features Pathbooks</h3>
							</div>
							<div>
								<div className="card-body">
									{this.state.featureList.map(data => (
										<div key={data.nombreEn}>
											<FichaFeatures data={data}/>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid">
						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Lista de features Kids</h3>
							</div>
							<div>
								<div className="card-body">
									{this.state.featureListKids.map(data => (
										<div key={data.nombreEn}>
											<FichaFeatures data={data}/>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid">
						<div className="card card-primary">
							<div className="card-header">
								<h3 className="card-title">Lista de features Estudiantes</h3>
							</div>
							<div>
								<div className="card-body">
									{this.state.featureListStudents.map(data => (
										<div key={data.nombreEn}>
											<FichaFeatures data={data}/>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AppFeatures;

import React from "react";
import {Link, useRouteMatch} from "react-router-dom";
import logoPATHBOOKS from "../assets/img/LOGOPATHBOOKS.png";

export default function BarSide(userData) {
	let fotoUsuario = null;
	let username = null;
	if (!userData.userData.userPic) {
		fotoUsuario = "https://firebasestorage.googleapis.com/v0/b/viksoftwareant.appspot.com/o/fotos%2Fuser-01.png?alt=media&token=6b4043af-668b-4be1-b680-c924f91b7751";
		username = "";
	} else {
		fotoUsuario = userData.userData.userPic;
		username = userData.userData.name;
	}
	let {url} = useRouteMatch();

	return (
		<div>
			<div>
				<div>
					<aside className="main-sidebar sidebar-dark-primary elevation-4">
	              <span className="brand-link">
	                <span className="brand-text font-weight-light">
	                Administradores <img src={logoPATHBOOKS} alt="AdminLTE Logo" className="brand-image"/>
	                </span>
	              </span>
						<div className="sidebar">

							<div className="user-panel mt-3 pb-3 mb-3 d-flex">
								<div className="image">
									<img src={fotoUsuario} className="img-circle elevation-2" alt=""/>
								</div>
								<div className="info">
									<Link className="d-block" to="/">{username}</Link>
								</div>
							</div>
							<nav className="mt-2">
								<ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
									{/*<!-- Boton1 -->*/}
									<li className="nav-item">
										<Link className="nav-link" to={`${url}`}>
											<i className="nav-icon fas fa-home"/>
											<p>
												Inicio
												<span className="right badge badge-danger"/>
											</p>
										</Link>
									</li>

									{/*<!-- Boton2 -->*/}
									<li className="nav-header">ESCUELAS</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/AppSchools`}>
											<i className="far fa-circle nav-icon"/>
											<p>ABC Escuelas</p>
										</Link>
									</li>

									{/*<!-- Boton3 -->*/}
									<li className="nav-header">PATHBOOKS</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/FormNewPathbook`}>
											<i className="far fa-circle nav-icon"/>
											<p>ABC Pathbook</p>
										</Link>
									</li>

									<li className="nav-item">
										<Link className="nav-link" to={`${url}/ControlPathbooks`}>
											<i className="far fa-circle nav-icon"/>
											<p>Control Pathbook</p>
										</Link>
									</li>

									<li className="nav-item">
										<Link className="nav-link" to={`${url}/FormPortadasPathbook`}>
											<i className="far fa-circle nav-icon"/>
											<p>Portadas de pathbook</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/FormImgPathbook`}>
											<i className="far fa-circle nav-icon"/>
											<p>Imagenes de pathbook</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/FormJsonPathbook`}>
											<i className="far fa-circle nav-icon"/>
											<p>Json de pathbook</p>
										</Link>
									</li>
									<li className="nav-header">AUDIOPATHBOOKS</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/GeneraAudioPathbooks`}>
											<i className="far fa-circle nav-icon"/>
											<p>Archivo Word</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/FormAudioPathbook`}>
											<i className="far fa-circle nav-icon"/>
											<p>Carga Audios</p>
										</Link>
									</li>
									{/*<!-- Boton4 -->*/}
									<li className="nav-header"><i className="nav-icon fas fa-toolbox"/>AUTORES</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/FormNewAutor`}>
											<i className="far fa-circle nav-icon"/>
											<p>Nuevo autor</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/FormImgAutor`}>
											<i className="far fa-circle nav-icon"/>
											<p>Imagenes de autor</p>
										</Link>
									</li>

									{/*<!-- Boton4 -->*/}
									<li className="nav-header "><i className="nav-icon fas fa-toolbox"/> Herramientas</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/FormImgPathbook`}>
											<i className="far fa-circle nav-icon"/>
											<p>Herramientas</p>
										</Link>
									</li>
									{/*<!-- Boton5 -->*/}
									<li className="nav-header">REPORTES</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/RepUsersReg`}>
											<i className="far fa-circle nav-icon"/>
											<p>Usuarios Registro</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/RepRegaliasPathbook`}>
											<i className="far fa-circle nav-icon"/>
											<p>Reporte regalias</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/RepLecturasPathbook`}>
											<i className="far fa-circle nav-icon"/>
											<p>Reporte Lecturas</p>
										</Link>
									</li>

									<li className="nav-item">
										<Link className="nav-link" to={`${url}/RepCommission`}>
											<i className="far fa-circle nav-icon"/>
											<p>Reporte comisionistas</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/RepPathbooks`}>
											<i className="far fa-circle nav-icon"/>
											<p>Reporte de PATHBOOKS</p>
										</Link>
									</li>
									{/*<!-- Boton6 -->*/}
									<li className="nav-header">APP CONTROL</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/AppFeatures`}>
											<i className="far fa-circle nav-icon"/>
											<p>Features</p>
										</Link>
									</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/AppCarrusel`}>
											<i className="far fa-circle nav-icon"/>
											<p>Carrusel</p>
										</Link>
									</li>

									{/*<!-- Boton7 -->*/}
									<li className="nav-header">CUPONES</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/Coupons`}>
											<i className="nav-icon fas fa-ticket-alt"/>
											<p>
												Cupones
												<span className="right badge badge-danger"/>
											</p>
										</Link>
									</li>
									{/*Ofertas*/}

									<li className="nav-header">OFERTAS</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/FormNewOffers`}>
											<i className="nav-icon fas fa-ticket-alt"/>
											<p>
													Nueva Oferta
												<span className="right badge badge-danger"/>
											</p>
										</Link>
									</li>

									<li className="nav-header">COMISIÓNISTAS</li>
									<li className="nav-item">
										<Link className="nav-link" to={`${url}/FormNewCommission`}>
											<i className="nav-icon fas fa-ticket-alt"/>
											<p>
												Nuevo comisiónista
												<span className="right badge badge-danger"/>
											</p>
										</Link>
									</li>
									<li className="nav-header">Pathbooks Panel</li>


								</ul>
							</nav>

						</div>
					</aside>
				</div>
			</div>
		</div>
	);
}

import React from "react";
import './assets/css/adminlte.min.css';
import './assets/plugins/fontawesome-free/css/all.min.css';

import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import LoginIni from "./modules/LoginIni";
import Register from "./modules/Register";
import Panel from "./modules/Panel";

export default function NestingExample() {
	return (
		<Router>
			<div>
				<Switch>
					<Route path="/" exact render={props => <LoginIni {...props}  />} />
					<Route path="/Panel" render={props => <Panel {...props}/>} />
					<Route path="/Register" exact render={props => <Register {...props}  />} />
				</Switch>
			</div>
		</Router>
	);
}

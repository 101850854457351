import React, {Component} from "react";
import {firestore} from "../js/firebase";


class SaludoTop extends Component {
	constructor(props) {
		super();
		this.state = {
			idEscritor: props.userData.authorId,
			autorName: null,
		};
	}
	componentDidMount() {
		let nombreAuthor = null;
		if (!this.state.idEscritor) {
			this.setState({autorName: null});
		} else {
			firestore.doc("authors/" + this.state.idEscritor).get().then((documentSnapshot) => {
				let dataAuthor = documentSnapshot.data();
				nombreAuthor = dataAuthor.name;
				this.setState({autorName: nombreAuthor});
			});
		}

	}

	render() {
		return (
			<div className="content-wrapper">
				<h6 className="col-sm-8">
					{this.state.idEscritor ?
						<div>
							Escritor asignado: {this.state.autorName}
						</div>
						:
						<div>
							Author no asignado
						</div>
					}
				</h6>
			</div>
		);
	}
}

export default SaludoTop;

// import React, { Component } from 'react';
// import { firestore } from "../js/firebase";
//
// class SaludoTop extends Component {
// 	render() {
// 		return (
// 			<div className="content-wrapper">
// 				<div className="content-header">
// 					<h6 className="col-sm-8">
// 						{/*{userData.userData.authorId ?*/}
// 						{/*	<div>*/}
// 						{/*		<TengoEscritorAsignado idEscritor={userData.userData.authorId}/>*/}
// 						{/*	</div>*/}
// 						{/*	:*/}
// 						{/*	<div>*/}
// 						{/*		Author no asignado*/}
// 						{/*	</div>*/}
// 						{/*}*/}
// 					</h6>
// 				</div>
// 			</div>
// 		)
// 	}
// }
//
//
// // function TengoEscritorAsignado(idEscritor) {
// // 	let autorID = idEscritor.idEscritor;
// // 	console.log(autorID);
// // 	let nombreAuthor = null;
// // 	firestore.doc("authors/" + autorID).get().then((documentSnapshot) => {
// // 		let dataAuthor = documentSnapshot.data();
// // 		nombreAuthor = dataAuthor.name;
// // 		console.log(nombreAuthor);
// // 	});
// // 	return (
// // 		<div>
// // 			Author asignado: {nombreAuthor}
// // 		</div>
// // 	);
// // }
